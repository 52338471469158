import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Stack } from "@mui/system";
import {
  useActionQuery,
  useDeleteActionMutation,
} from "../../lib/xhr/action/action";
import {
  useActivityQuery,
  useDeleteActivityMutation,
} from "../../lib/xhr/activity/activity";
import { useDeleteTaskMutation } from "../../lib/xhr/task/task";
import { useDeleteKpiMutation, useKPIQuery } from "../../lib/xhr/kpi/kpi";
import {
  useDeleteReportMutation,
  useReportQuery,
} from "../../lib/xhr/reports/report";
import {
  useMilestoneQuery,
  useDeleteMilestoneMutation,
  useMileStoneReportsDeleteById,
  useMileStoneReportsQueryById,
  useMilestoneParamsQueryById,
  useDeleteMilestoneParamsMutation,
} from "../../lib/xhr/milestones/milestone";
import { useSnackbar } from "notistack";
import { useQueryClient } from "@tanstack/react-query";
import { useUserDeleteMutation } from "../../lib/xhr/users/users";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  p: 4,
};

export default function DeleteModal() {
  const navigate = useNavigate();
  const handleClose = () => navigate(-1);
  const { id, name, route } = useParams();
  const deleteReport = useDeleteReportMutation();
  const deleteActivity = useDeleteActivityMutation();
  const deleteKpi = useDeleteKpiMutation();
  const deleteMilestones = useDeleteMilestoneMutation();
  const deleteMilestoneParam = useDeleteMilestoneParamsMutation();
  const deleteMilestoneReport = useMileStoneReportsDeleteById();
  const deleteUser = useUserDeleteMutation();
  const { enqueueSnackbar } = useSnackbar();

  const kpiQuery = useKPIQuery();
  const reportsQuery = useReportQuery();
  const activityQuery = useActivityQuery();
  const location = useLocation();
  const milestoneQuery = useMilestoneQuery();

  const milestoneParamQuery = useMilestoneParamsQueryById(
    location?.state?.backgroundLocation?.state?.id as string
  );

  const milestoneReportQuery = useMileStoneReportsQueryById(
    location?.state?.backgroundLocation?.state?.id as string
  );

  const queryClient = useQueryClient();
  console.log("location", location.state);

  const handleDelete = () => {
    switch (route) {
      case "kpis":
        deleteKpi.mutate(id as string, {
          onSuccess: async (data) => {
            queryClient.invalidateQueries({
              queryKey: ["getKPIs"],
            });

            await kpiQuery.refetch();
            enqueueSnackbar("success", { variant: "success" });
            navigate("/kpis?name=&page=1&partner=&page_size=10");
          },
        });
        return;

      case "activities":
        deleteActivity.mutate(id as string, {
          onSuccess: async (data) => {
            queryClient.invalidateQueries({
              queryKey: ["getActivity"],
            });

            await activityQuery.refetch();
            enqueueSnackbar("success", { variant: "success" });
            navigate("/activities?name=&page=1&partner=&page_size=10");
          },
        });
        return;
      case "milestones":
        deleteMilestones.mutate(id as string, {
          onSuccess: async (data) => {
            queryClient.invalidateQueries({
              queryKey: ["get milestone"],
            });

            await milestoneQuery.refetch();
            enqueueSnackbar("success", { variant: "success" });
            navigate("/milestones");
          },
        });
        return;
      case "reports":
        // console.log("deleting action");
        deleteReport.mutate(id as string, {
          onSuccess: async (data) => {
            queryClient.invalidateQueries({
              queryKey: ["getReports"],
            });

            await reportsQuery.refetch();
            enqueueSnackbar("success", { variant: "success" });
            navigate("/reports?name=&page=1&partner=&page_size=10");
          },
        });
        return;

      case "milestone-report":
        // console.log("deleting action");
        deleteMilestoneReport.mutate(
          { id: id as string },
          {
            onSuccess: async (data) => {
              enqueueSnackbar("success", { variant: "success" });
              queryClient.invalidateQueries({
                queryKey: ["get milestone report"],
              });

              await milestoneReportQuery.refetch();
              navigate(
                location.state.backgroundLocation.pathname +
                location.state.backgroundLocation.search
              );
            },
          }
        );
        return;
      case "users":
        // console.log("deleting action");
        deleteUser.mutate(
          { id: id as string },
          {
            onSuccess: async (data) => {
              enqueueSnackbar("success", { variant: "success" });
              queryClient.invalidateQueries(["getUsers"]);
              navigate(
                "/user-management?name=&partner=&page=1&page_size=10&search_term="
              );
            },
          }
        );
        return;
      case "milestone-param":
        deleteMilestoneParam.mutate(
          { id: id as string },
          {
            onSuccess: async (data) => {
              queryClient.invalidateQueries({
                queryKey: ["get miletone params"],
              });

              await milestoneParamQuery.refetch();
              navigate(
                location.state.backgroundLocation.pathname
              );
            },
          });
        return;
      default:
        return;
    }
  };

  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack direction="column" spacing={3}>
          <Stack direction="row" spacing={2}>
            <Typography variant="h6" sx={{ color: "red" }}>
              Delete
            </Typography>
            <Typography variant="h6">{name} {id}</Typography>
          </Stack>
          <Typography variant="h6" sx={{ color: "red" }} component="h2">
            Caution, this action is irreversible !
          </Typography>
          <Stack direction="row" justifyContent="space-between">
            <Button
              variant="contained"
              sx={{ bgcolor: "blue" }}
              onClick={(e) => navigate(-1)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ bgcolor: "red" }}
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}
