import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export const StatusContainer: React.FC<
  React.PropsWithChildren<{ status: string }>
> = ({ status, children }) => {
  if (status === "error") {
    return <Error />;
  }

  if (status === "success") {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return <Loading />;
};

const Loading = () => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress size="2rem" />
    </Box>
  );
};

const Error = () => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="h4" sx={{ color: "secondary.main" }}>
        Error
      </Typography>
    </Box>
  );
};

export const LoadingContainer: React.FC<
  React.PropsWithChildren<{ loading: boolean }>
> = ({ loading, children }) => {
  if (loading) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <Loading />;
  }

  return <>{children}</>;
};
