import { useQuery } from "@tanstack/react-query";
import { client, ResultArray, ResultPaginatedArray } from "../common/common";
import { handleResFormat } from "../common/common.util";


interface Response {
	id: number
	event_name: string
	client_ip_address: string
	created_time: string
	email: string
}

interface ActivityLogParams {
	partner?: string,
	page?: number | string,
	page_size?: number | string
}

export type ActivityLogResponse = Response

export const useActivityLogQuery = (params?: ActivityLogParams) =>
	useQuery<ResultPaginatedArray<Response>>({
		queryKey: ["getActivityLog", { ...params }],
		queryFn: () =>
			client.get('/api/activity-logs/history/', { params })
				.then(handleResFormat)
				.catch(err => err)
	})