import { Typography, Stack, Paper } from "@mui/material";
import { useForm } from "react-hook-form";
import { CustomAutoComplete } from "../../lib/ui/forms/autoComplete";
import { LoadingContainer } from "../../lib/ui/forms/common/status-container";
import { CustomDatePicker } from "../../lib/ui/forms/date-picker";
import { TextField } from "../../lib/ui/forms/text-field";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { CustomModal } from "../../components/modals/custom-modal";
import { CheckboxField } from "../../lib/ui/forms/checkbox";
import { z } from "zod";
import {
  ActivityResponse,
  useActivityQuery,
} from "../../lib/xhr/activity/activity";
import {
  MilestoneResponse,
  useMilestoneQuery,
  useMilestoneQueryById,
  useMilestoneUpdateMutation,
} from "../../lib/xhr/milestones/milestone";
import React from "react";
import { CLoadingButton } from "../../components/buttons/button";

const schema = z
  .object({
    activity: z.object({ label: z.string(), value: z.number() }),
    name: z.string().min(1, "Required"),
    start_date: z.date(),
    end_date: z.date(),
    verification_means: z.any(z.string().min(1, "Required")),
    outcome: z.string().min(1, "Required"),
    target: z.string().min(1, "Required"),
    gender_disaggregated: z.boolean(),
  })
  .superRefine(({ start_date, end_date }, ctx) => {
    const start = new Date(start_date);
    const end = new Date(end_date);

    if (start.getTime() > end.getTime()) {
      // console.log("it is");
      ctx.addIssue({
        code: "custom",
        message: "Start date must be less than end date",
        path: ["end_date"],
      });
    }
  });

type ISchema = z.infer<typeof schema>;

export const EditMilestonePage: React.FC<any> = () => {
  const { id } = useParams();
  const milestoneQuery = useMilestoneQuery();
  const milestone = useMilestoneQueryById(id as string);
  const activityQuery = useActivityQuery();

  return (
    <Paper sx={{ minHeight: "100vh" }}>
      <LoadingContainer
        loading={
          activityQuery.isLoading ||
          milestoneQuery.isLoading ||
          milestone.isLoading
        }
      >
        <Content
          activities={activityQuery.data?.results!}
          milestone={milestone.data?.results!}
          refetch={milestoneQuery.refetch}
        />
      </LoadingContainer>
    </Paper>
  );
};

const Content = ({
  activities,
  milestone,
  refetch,
}: {
  activities: ActivityResponse[];
  milestone: MilestoneResponse;
  refetch: any;
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { mutateAsync: milestoneMutAsync, isLoading } =
    useMilestoneUpdateMutation();

  const activity = activities
    ?.filter((a) => a.id! === milestone.activity)
    .map((a) => ({ label: a.name, value: a.id }));

  const form = useForm<ISchema>({
    resolver: zodResolver(schema),
    mode: "onChange",
  });

  // console.log("errors", form.formState.errors);
  // console.log("activity", activity);
  // const agg = form.watch("gender_disaggregated");
  // console.log("gender disaggregated", agg);

  React.useEffect(() => {
    form.setValue("name", milestone?.name!);
    form.setValue("start_date", new Date(milestone?.start_date!));
    form.setValue("end_date", new Date(milestone?.end_date!));
    form.setValue("verification_means", milestone?.verification_means);
    form.setValue("outcome", milestone?.outcome!);
    form.setValue("gender_disaggregated", milestone?.gender_disaggregated!);
    form.setValue("target", milestone?.target!);
    form.setValue("activity", activity?.at(0)!);
  }, [milestone]);

  const submit = async (data: ISchema) => {
    const payload = {
      ...data,
      activity: data.activity.value,
      start_date: dayjs(data.start_date).format("YYYY-MM-DD"),
      end_date: dayjs(data.end_date).format("YYYY-MM-DD"),
      id,
    };
    // console.log(payload);
    const res = await milestoneMutAsync(payload as any);
    if (res.results.messages) {
      return enqueueSnackbar(res.results.messages, {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }

    enqueueSnackbar("Successfully added 1 Milestone", {
      variant: "success",
      anchorOrigin: { vertical: "top", horizontal: "center" },
    });
    refetch();
    return navigate(`/milestones?page=1&page_size=10&name=${res.results.name}`);
  };

  return (
    <CustomModal>
      <Stack spacing={3} component="form" onSubmit={form.handleSubmit(submit)}>
        <Stack direction="row" justifyContent="center">
          <Typography variant="h5"> Edit Milestone</Typography>
        </Stack>
        <Stack direction="column" spacing={2}>
          <CustomAutoComplete
            name="activity"
            options={
              activities?.map((activity) => ({
                label: activity.name,
                value: activity.id,
              })) ?? []
            }
            labelProps={{ variant: "h6" }}
            label={"Activity"}
            control={form.control}
            errors={form.formState.errors}
            defaultValue={activity ?? []}
          />
          <TextField
            label="Name"
            labelProps={{ variant: "h6" }}
            name="name"
            control={form.control}
            errors={form.formState.errors}
            defaultValue={milestone?.name}
          />
          <CustomDatePicker
            name="start_date"
            label="Start date"
            labelProps={{ variant: "h6" }}
            control={form.control}
            errors={form.formState.errors}
            defaultValue={new Date(milestone?.start_date!)}
          />
          <CustomDatePicker
            name="end_date"
            label="End date"
            labelProps={{ variant: "h6" }}
            control={form.control}
            errors={form.formState.errors}
            defaultValue={new Date(milestone?.end_date!)}
          />
          <TextField
            label="Verification means"
            labelProps={{ variant: "h6" }}
            name="verification_means"
            control={form.control}
            errors={form.formState.errors}
            defaultValue={milestone?.verification_means}
          />
          <TextField
            label="Outcome"
            labelProps={{ variant: "h6" }}
            name="outcome"
            control={form.control}
            errors={form.formState.errors}
            defaultValue={milestone?.outcome}

            // type="number"
          />
          <TextField
            label="Target"
            labelProps={{ variant: "h6" }}
            name="target"
            control={form.control}
            errors={form.formState.errors}
            defaultValue={milestone?.target}
          />

          <CheckboxField
            name="gender_disaggregated"
            label="Gender disaggregated"
            errors={form.formState.errors}
            control={form.control}
            defaultChecked={milestone?.gender_disaggregated}
          />
        </Stack>
        <CLoadingButton loading={isLoading} />
      </Stack>
    </CustomModal>
  );
};
