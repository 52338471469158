import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { ActivitySummary } from "../../../types/types";
import { Colors } from "../../../config/theme/theme";
import { TaskResponse } from "../../../lib/xhr/task/task";
import { CustomPagination } from "../../Pagination/pagination-component";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "activityId",
    numeric: true,
    disablePadding: false,
    label: "#",
  },
  {
    id: "activity",
    numeric: false,
    disablePadding: false,
    label: "Activity",
  },
  {
    id: "target",
    numeric: true,
    disablePadding: false,
    label: "Target",
  },
  {
    id: "milestoneId",
    numeric: true,
    disablePadding: false,
    label: "#",
  },
  {
    id: "milestones",
    numeric: false,
    disablePadding: false,
    label: "Milestones",
  },
  // {
  //   id: "milestoneTarget",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Target",
  // },
  // {
  //   id: "milestoneAchieved",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Achieved",
  // },
  // {
  //   id: "indicator",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "indicator",
  // },
];

interface EnhancedTableProps {
  numSelected?: number;
  onRequestSort?: (event: React.MouseEvent<unknown>, property: any) => void;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order?: Order;
  orderBy?: string;
  rowCount?: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, numSelected, rowCount, onRequestSort } = props;
  //   const createSortHandler =
  //     (property: keyof ReportData) => (event: React.MouseEvent<unknown>) => {
  //       onRequestSort!(event, property);
  //     };

  return (
    <TableHead>
      <TableRow sx={{ background: Colors.primary.dark }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ color: "white" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              sx={{
                color: "white! important",
                "&:hover": { color: "red! important" },
                "&:focus": { color: "red! important" },
                "& .MuiTableSortLabel-icon": {
                  color: "white",
                },
                "&.Mui-active .MuiTableSortLabel-icon": {
                  opacity: 1,
                  color: "red",
                },
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable(props: any) {
  const { rows } = props;
  const [selected, setSelected] = React.useState<readonly string[]>([]);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <CustomPagination
          advanced={false}
          count={props.count}
          page={props.page}
          pages={props.pages}
          position="start"
          rowsPerPage={props.rowsPerPage}
          setPage={props.setPage}
          setRowsPerPage={props.setRowsPerPage}
        />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead numSelected={selected.length} />
            <TableBody>
              {rows.map((row: any, index: number) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      align="left"
                    >
                      {row.code}
                    </TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">{row.target}</TableCell>
                    <TableCell align="left" colSpan={6}>
                      <TableContainer>
                        <Table>
                          <TableHead sx={{ height: 0 }}>
                            <TableCell sx={{ fontWeight: "bold" }}>
                              Code
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                              Name
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                              Target
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                              Achieved
                            </TableCell>
                          </TableHead>
                          {row.milestones.map((milestone: any) => (
                            <TableBody>
                              <TableRow sx={{ boxShadow: "none" }}>
                                <TableCell
                                  align="left"
                                  sx={{ minWidth: "3rem" }}
                                >
                                  {milestone.code ?? " "}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{ minWidth: "3rem", maxWidth: "60ch" }}
                                >
                                  {milestone.name ?? " "}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{ minWidth: "3rem" }}
                                >
                                  {milestone.target}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{ minWidth: "3rem" }}
                                >
                                  {milestone.achieved}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          ))}
                        </Table>
                      </TableContainer>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomPagination
          advanced={true}
          count={props.count}
          page={props.page}
          pages={props.pages}
          position="end"
          rowsPerPage={props.rowsPerPage}
          setPage={props.setPage}
          setRowsPerPage={props.setRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

const Cell = ({ action }: any) => {
  return action.tasks.map((task: TaskResponse) => (
    <Table>
      <TableHead sx={{ height: 0, display: "none" }}>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell sx={{ minWidth: "3rem" }} align="left">
            {task.code ?? "-"}
          </TableCell>
          <TableCell sx={{ minWidth: "3rem" }} align="left">
            {task.name ?? "-"}
          </TableCell>
          <TableCell sx={{ minWidth: "3rem" }} align="left">
            {task.target ?? "-"}
          </TableCell>
          <TableCell sx={{ minWidth: "3rem" }} align="left">
            {/* {"-"} */}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  ));
};
