import { useMutation } from "@tanstack/react-query";
import { client, jsonToFormData, ResultObject } from "../common/common";
import { handleErrFormat, handleResFormat } from "../common/common.util";


interface Request {
	email: string,
	name: string,
	user_type: string | number,
	organisation: number,
	password: string
}

interface Response {
	refresh: string,
	access: string
}
//200
export const useRegisterMutation = () =>
	useMutation<ResultObject<any>, string, Request>({
		mutationKey: ["register"],
		mutationFn: (body: Request) =>
			client.post('/api/register/', body)
				.then(handleResFormat)
				.catch(err => err)
	})

export const useLoginMutation = () =>
	useMutation<ResultObject<Response>, string, Request>({
		mutationKey: ["login"],
		mutationFn: (body) =>
			client.post('/api/token/', body)
				.then(handleResFormat)
				.catch(handleErrFormat)
	})

export const useLogoutMutation = () =>
	useMutation<Response, string, { refresh: string }>({
		mutationKey: ['logoot'],
		mutationFn: (body) =>
			client.post('/api/logout/', jsonToFormData(body))
				.then(handleResFormat)
				.catch(err => err)
	})

interface IForgotPassword {
	email: string
}

interface IResetPassword {
	uid: string,//"WQ",
	token: string,//"biayie-d5c6d275f7afbb447dbe4c12abff3f82",
	password: string//"Sam@1234"
	new_password2: string

}

export const useForgotPassword = () =>
	useMutation<ResultObject<any>, string, IForgotPassword>({
		mutationKey: ['forgotPassword'],
		mutationFn: (body) =>
			client.post('/api/forgot-password/', body)
				.then(handleResFormat)
				.catch(err => err)

	})


export const useResetPassword = () =>
	useMutation<ResultObject<any>, string, IResetPassword>({
		mutationKey: ['forgotPassword'],
		mutationFn: (body) =>
			client.post('/api/reset-password/', body)
				.then(handleResFormat)
				.catch(err => err)

	})
