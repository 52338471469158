import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import DefaultLayout from "../../components/layout/layout.component";
import ActivitiesPage from "../../pages/activities/activities";
import DashboardPage from "../../pages/dashboard/dashboard";
import KPIsPage from "../../pages/kpis/kpis";
import { AddKPIPage } from "../../pages/kpis/add-kpis";
import LoginPage from "../../pages/login/login";
import SummaryPage from "../../pages/summary/summary";
import ReportsPage from "../../pages/reporting/reports";
import AddActivityPage from "../../pages/activities/add/add-activity-task-action";
import SignUpPage from "../../pages/signup/sign-up";
import DeleteModal from "../../components/modals/delete";
import React from "react";
import { EditKPIPage } from "../../pages/kpis/edit-kpi";
import { AddReportPage } from "../../pages/reporting/add-report";
import { EditReportPage } from "../../pages/reporting/edit-report";
import { EditActivityPage } from "../../pages/activities/edit/edit-activity";
import { EditActionPage } from "../../pages/activities/edit/edit-action";
import { EditTaskPage } from "../../pages/activities/edit/edit-task-page";
import { UserManagementPage } from "../../pages/user-management/users";
import { ActivityLogPage } from "../../pages/activity-log/activity-log";
import { Logout } from "../../pages/logout/logout";
import { RoleManagementPage } from "../../pages/roles/roles";
import { useAuth } from "../../lib/context/auth.context";
import { LoginGuardProvider } from "../../lib/context/guards/loginGuard";
import { AddRoles } from "../../pages/roles/add-roles";
import { AddRolePermission } from "../../pages/roles/add-groups-permission";
import { AddUserPermissions } from "../../pages/user-management/add-user-permissions";
import { AddUserToGroup } from "../../pages/user-management/add-user-to-group";
import { ProfilePage } from "../../pages/profile/profile";
import ForgotPasswordPage from "../../pages/password/forgot-password";
import ResetPasswordPage from "../../pages/password/reset-password";
import { UploadActivitiesPage } from "../../pages/activities/upload/upload";
import { UploadKPIPage } from "../../pages/kpis/upload-kpi";
import { MilestonesPage } from "../../pages/milestones/milestones";
import { AddMilestonePage } from "../../pages/milestones/add-milestones";
import { ReportMilestonePage } from "../../pages/milestones/report-milestones";
import { EditMilestonePage } from "../../pages/milestones/edit-milestones";
import { MilestoneDetailPage } from "../../pages/milestones/milestone-detail";
import { LogoutTimer } from "../../lib/context/guards/timeoutGuard";
import { EditUserPage } from "../../pages/user-management/edit-user";
import { AddMilestoneParams } from "../../pages/milestones/add-params";
import { MilestoneConfiguration } from "../../pages/milestones/configure";
import { EditMilestoneParamPage } from "../../pages/milestones/edit-milestone-param";

export const AppROuter = () => {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
};

const AppRoutes = () => {
  const {
    state: { isLoggedIn },
  } = useAuth();
  const location = useLocation();
  let state = location.state as { backgroundLocation?: Location };
  // console.log("rerenders");

  if (isLoggedIn) {
    return (
      <React.Fragment>
        <Routes location={state?.backgroundLocation || location}>
          <Route path="/auth">
            <Route path="logout" element={<Logout />} />
          </Route>
          <Route
            path="/"
            element={
              <LoginGuardProvider>
                <LogoutTimer>
                  <DefaultLayout />{" "}
                </LogoutTimer>
              </LoginGuardProvider>
            }
          >
            <Route path="activities" element={<ActivitiesPage />} />
            <Route path="reports" element={<ReportsPage />} />
            <Route path="kpis" element={<KPIsPage />} />
            <Route path="summary" element={<SummaryPage />} />
            <Route path="dashboard" element={<DashboardPage />} />
            <Route path="user-management" element={<UserManagementPage />} />
            <Route path="activity-log" element={<ActivityLogPage />} />
            <Route path="roles" element={<RoleManagementPage />} />
            <Route path="profile" element={<ProfilePage />} />
            <Route path="/milestones" element={<MilestonesPage />} />
            <Route
              path="/milestones-reports/:id"
              element={<MilestoneDetailPage />}
            />
            <Route
              path="/milestones/:id/configure"
              element={<MilestoneConfiguration />}
            />
          </Route>
        </Routes>
        {state?.backgroundLocation && (
          <Routes>
            <Route path="/delete/:route/:name/:id" element={<DeleteModal />} />

            <Route path="/reports/add" element={<AddReportPage />} />
            <Route path="/reports/edit/:id" element={<EditReportPage />} />

            <Route path="/kpis/edit/:id" element={<EditKPIPage />} />
            <Route path="/kpis/add" element={<AddKPIPage />} />
            <Route path="/kpis/upload" element={<UploadKPIPage />} />

            <Route path="/activities/add" element={<AddActivityPage />} />
            <Route path="/activities/edit/:id" element={<EditActivityPage />} />
            <Route
              path="/activities/upload"
              element={<UploadActivitiesPage />}
            />
            <Route path="/milestones-add" element={<AddMilestonePage />} />
            <Route
              path="/milestones/:id/report"
              element={<ReportMilestonePage />}
            />
            <Route
              path="/milestones/:id/edit"
              element={<EditMilestonePage />}
            />
            <Route
              path="/milestone-param/:milestone_id/:param_id/edit"
              element={<EditMilestoneParamPage />}
            />
            <Route path="/actions/edit/:id" element={<EditActionPage />} />
            <Route path="/tasks/edit/:id" element={<EditTaskPage />} />

            <Route path="/roles/add" element={<AddRoles />} />
            <Route
              path="/roles/add-permission/:group"
              element={<AddRolePermission />}
            />
            <Route
              path="/users/add-user-to-group/:id"
              element={<AddUserToGroup />}
            />
            <Route
              path="/users/add-permissions/:id"
              element={<AddUserPermissions />}
            />
            <Route path="/users/:id/edit" element={<EditUserPage />} />
          </Routes>
        )}
      </React.Fragment>
    );
  }

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <LoginGuardProvider>
            <Outlet />
          </LoginGuardProvider>
        }
      >
        <Route path="auth">
          <Route path="login" element={<LoginPage />} />
          <Route path="sign-up" element={<SignUpPage />} />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
        </Route>
        <Route path="reset/verify" element={<ResetPasswordPage />} />
      </Route>
    </Routes>
  );
};
