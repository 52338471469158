import { useMutation, useQuery } from "@tanstack/react-query";
import { client, jsonToFormData, ResultObject, ResultPaginatedArray } from "../common/common";
import { handleResFormat } from "../common/common.util";


interface Request {
	partner: number[],//[1,2],
	name: string,//"Annual volume of ICS sales (increasing from 250,000 in 2019 to over 1 million in 2024) ",
	indicator: string,// "1",
	target: string, //"250",
	start_date: string,//"2021-10-25",
	end_date: string, //"2024-10-25",
	verification_means: string, //"test",
	gender_disaggregated: boolean, // "male"
}


interface Response {
	id: number,// 10,
	partner: {
		id: number,//1,
		code: string,//"GIZ",
		description: string
	}[],//[1,2],
	name: string,// "Annual volume of ICS sales (increasing from 250,000 in 2019 to over 1 million in 2025)",
	outcome: string,// "1",
	target: string,// "250",
	start_date: string,// "2021-10-25T00:00:00Z",
	end_date: string,// "2024-10-25T00:00:00Z",
	verification_means: string,// "test",
	gender_disaggregated: boolean,// "male",
	created_by: string | null,// null,
	creation_date: string,// "2023-01-01T06:43:51.714502Z",
	last_updated: string,// "2023-01-01T06:43:51.714523Z"
	achieved: string
	maleachieved: string
	femaleachieved: string
}

interface UploadReq {
	file: string
}

export type KPIResponse = Response;

interface KpiParams {
	partner?: string,
	page?: number | string,
	page_size?: number | string,
	name?: string
}

export const useKPIQuery = (params?: KpiParams) =>
	useQuery<ResultPaginatedArray<Response>>({
		queryKey: ["getKPIs", { ...params }],
		queryFn: () =>
			client.get('/api/kpis/', { params })
				.then(res => res.data)
				.catch(err => err)
	})

export const useKPIQueryById = (id: string) =>
	useQuery<ResultObject<Response>>({
		queryKey: ['getKpiById'],
		queryFn: () =>
			client.get(`/api/kpis/${id}`)
				.then(res => res.data)
				.catch(err => err)
	})

export const useKPIMutation = () =>
	useMutation<ResultObject<Response>, string, Request>({
		mutationKey: ["addKPI"],
		mutationFn: (body) =>
			client.post('/api/kpis/', body)
				.then(res => handleResFormat(res))
				.catch(err => err)
	})


export const useKPIEditMutation = () =>
	useMutation<ResultObject<Response>, string, Request & { id: string }>({
		mutationKey: ["editKPI"],
		mutationFn: (body) =>
			client.put('/api/kpis/' + body.id, body)
				.then(res => handleResFormat(res))
				.catch(err => err)
	})

export const useDeleteKpiMutation = () =>
	useMutation({
		mutationFn: (id: string) =>
			client.delete('/api/kpis/' + id)
				.then(handleResFormat)
				.catch(err => err)
	})


export const useKpiUploadMutation = () =>
	useMutation<ResultPaginatedArray<Response>, string, UploadReq>({
		mutationFn: (body) =>
			client.post('/api/uploadexcel/kpis/', jsonToFormData(body))
				.then(handleResFormat)
				.catch(err => err)
	})