import React from "react";
import { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip
);

function getQuartersInRange(startDate, endDate) {
  const quarters: any = [];
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    const quarter = `Q${Math.floor(
      (currentDate.getMonth() + 3) / 3
    )} ${currentDate.getFullYear()}`;
    if (!quarters.includes(quarter)) {
      quarters.push(quarter);
    }
    currentDate.setMonth(currentDate.getMonth() + 3);
    currentDate.setDate(1);
  }
  return quarters;
}

function PerformanceChart({ kpi }) {
  const [chartData, setChartData] = useState<any>(null);

  useEffect(() => {
    const quarters = getQuartersInRange(
      new Date(kpi.start_date),
      new Date(kpi.end_date)
    );

    console.log("quaters", quarters);
    const quarterLabels = quarters;

    const achievedData = new Array(quarters.length).fill(kpi.achieved);
    const targetData = new Array(quarters.length).fill(kpi.target);

    setChartData({
      labels: quarterLabels,
      datasets: [
        {
          label: "Achieved",
          data: achievedData,
          borderColor: "blue",
          backgroundColor: "rgba(0, 0, 255, 0.1)",
          fill: "start",
        },
        {
          label: "Target",
          data: targetData,
          borderColor: "red",
          backgroundColor: "rgba(255, 0, 0, 0.1)",
          fill: false,
        },
      ],
    });
  }, [kpi]);

  return (
    <div>
      {chartData && (
        <Line
          data={chartData}
          options={{
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          }}
          id="10000"
        />
      )}
    </div>
  );
}

export default PerformanceChart;
