import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { Colors } from "../../config/theme/theme";
import React from "react";

export const CLoadingButton = ({
  loading,
  label,
}: {
  loading?: boolean;
  label?: string;
}) => {
  return (
    <Button
      variant="contained"
      disabled={loading}
      type="submit"
      sx={{ bgcolor: Colors.info.main }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        {loading ? (
          <CircularProgress color="inherit" size={15} />
        ) : (
          <SaveIcon fontSize="small" />
        )}
        <Typography textTransform="capitalize">
          {label ? label : "Submit"}
        </Typography>
      </Stack>
    </Button>
  );
};
