import { useMutation, useQuery } from "@tanstack/react-query";
import { client, jsonToFormData, ResultArray, ResultObject, ResultPaginatedArray } from "../common/common";
import { handleResFormat } from "../common/common.util";

interface Request {
	organisation: number,
	attachment: string | Blob,
	name: string,
	indicator: number,
	target: number,
	report_period_to: string,
	report_period_from: string,
	verification_means: string,
	gender_disaggregated: string
	achieved: string,
	level: number | string
}


interface Response {
	id: number,
	organisation: number,
	name: string,
	outcome: string,
	target: string,
	report_period_to: string,
	report_period_from: string,
	verification_means: string,
	attachment: any,
	gender_disaggregated: string,
	activity: null,
	action: null,
	task: null,
	kpi: null,
	achieved: string
	maleachieved: boolean,
	femaleachieved: boolean,
	level: string | null,
	created_by: string,
}

export type ReportResponse = Response

interface ReportParams {
	partner?: string,
	page?: number | string,
	page_size?: number | string,
	name?: string
}

export const useReportQuery = (params?: ReportParams) =>
	useQuery<ResultPaginatedArray<Response>>({
		queryKey: ['getReports', { ...params }],
		queryFn: () =>
			client.get('/api/reports', { params })
				.then(handleResFormat)
				.catch(err => err)
	})


export const useReportMutation = () =>
	useMutation<ResultObject<Response>, string, Request>({
		mutationKey: ["addReport"],
		mutationFn: (body) =>
			client.post('/api/reports/', jsonToFormData(body))
				.then(handleResFormat)
				.catch(err => err)
	})

export const useReportQueryById = (id: string) =>
	useQuery<ResultObject<Response>>({
		queryKey: ['getReportById'],
		queryFn: () =>
			client.get('/api/reports/' + id)
				.then(handleResFormat)
				.catch(err => err)
	})


export const useUpdateReportMutation = () =>
	useMutation<ResultObject<Response>, string, Request & { id: string }>({
		mutationKey: ["editReport"],
		mutationFn: (body) =>
			client.put('/api/reports/' + body.id, jsonToFormData(body))
				.then(handleResFormat)
				.catch(err => err)
	})

export const useDeleteReportMutation = () =>
	useMutation({
		mutationKey: ['deleteReport'],
		mutationFn: (id: string) =>
			client.delete('/api/reports/' + id)
				.then(handleResFormat)
				.catch(err => err)

	})
