import { Button, Stack, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { CustomModal } from "../../components/modals/custom-modal";
import { FileField } from "../../lib/ui/forms/text-field";
import { useActivityQuery } from "../../lib/xhr/activity/activity";
import { useKpiUploadMutation } from "../../lib/xhr/kpi/kpi";
import { CLoadingButton } from "../../components/buttons/button";

const schema = z
  .object({
    file: z.any(),
  })
  .required({
    file: true,
  });

type TSchema = z.infer<typeof schema>;

export const UploadKPIPage = () => {
  const form = useForm<TSchema>({
    resolver: zodResolver(schema),
  });
  const mut = useKpiUploadMutation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { refetch } = useActivityQuery();

  const submit = (data: TSchema) => {
    // console.log(data.file[0]);
    mut.mutate(
      { file: data.file[0] },
      {
        onSuccess: (data) => {
          //   console.log(data);
          if (data.results?.messages) {
            return enqueueSnackbar(data.results.messages, {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "center" },
            });
          }

          enqueueSnackbar("success", {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          });

          refetch();
          return navigate("/activities");
        },
      }
    );
  };

  return (
    <CustomModal>
      <Stack direction="row" justifyContent="center" p={1}>
        <Typography variant="h5">Upload KPI</Typography>
      </Stack>
      <Stack
        py={2}
        spacing={2}
        component="form"
        onSubmit={form.handleSubmit(submit)}
      >
        <FileField
          labelProps={{ variant: "h6" }}
          errors={form.formState.errors}
          type="file"
          label="Kpis"
          {...form.register("file")}
        />

        <CLoadingButton loading={mut.isLoading} />
      </Stack>
    </CustomModal>
  );
};
