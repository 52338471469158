import { MenuItem, Pagination, Select, Stack, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useKPIQuery } from "../../lib/xhr/kpi/kpi";

export const CustomPagination = (props: {
  pages: number;
  page?: number;
  rowsPerPage?: number;
  count: number;
  position: string;
  advanced: boolean;
  setPage?: (page: number) => void;
  setRowsPerPage?: (page: number) => void;
}) => {
  const [params, setParams] = useSearchParams();

  const p = {
    name: params.get("name") ?? "",
    partner: params.get("partner") ?? "",
    page: params.get("page") ?? "",
    page_size: params.get("page_size") ?? "",
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent={props.position}
      py={2}
      px={3}
    >
      {props.advanced && (
        <Pagination
          count={props.pages}
          page={parseInt(params.get("page") as string)}
          onChange={(_, page) => setParams({ ...p, page: `${page}` })}
        />
      )}
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{ fontSize: "1rem" }}
      >
        <Typography fontSize={".9rem"}>Rows per page:</Typography>
        <Select
          value={params.get("page_size")}
          onChange={(e) =>
            setParams({ ...p, page_size: e.target.value as string })
          }
          disableUnderline={true}
          size="small"
          variant="standard"
          sx={{ fontSize: ".9rem", p: ".1rem", background: "whitesmoke" }}
        >
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={props.count}>All</MenuItem>
        </Select>
      </Stack>
      <Typography sx={{ fontSize: "1rem" }}>
        1-
        {parseInt(p.page_size) > props.count
          ? props.count
          : p.page_size} of {props.count}
      </Typography>
    </Stack>
  );
};
