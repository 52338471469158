import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { Colors } from "../../config/theme/theme";
import { StatusContainer } from "../../lib/ui/forms/common/status-container";
import {
  useChangePasswordMutation,
  useProfileQuery,
} from "../../lib/xhr/profile/profile";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { PasswordField, TextField } from "../../lib/ui/forms/text-field";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const schema = z.object({
  old_password: z.string().min(5),
  new_password: z.string().min(5),
  new_password2: z.string().min(5),
});

type TSchema = z.infer<typeof schema>;

export function ProfilePage() {
  const { data, status } = useProfileQuery();
  const change = useChangePasswordMutation();
  const { enqueueSnackbar } = useSnackbar();

  const form = useForm<TSchema>({
    resolver: zodResolver(schema),
  });

  // console.log(form.formState.errors);
  const submit = (data: TSchema) => {
    // console.log(data);
    change.mutate(data, {
      onSuccess: (data) => {
        if (typeof data.results.messages) {
          return enqueueSnackbar(data.results.messages, {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          });
        }
      },
    });
  };

  return (
    <Box sx={{ p: "2rem" }}>
      <Box sx={{ background: "white", borderRadius: ".5rem" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          p={3}
        >
          <Typography
            component={"div"}
            sx={{
              fontSize: "1.18rem",
              fontWeight: 700,
              color: Colors.primary.darkest,
            }}
          >
            Profile
          </Typography>
        </Stack>
      </Box>
      <StatusContainer status={status}>
        <Box component={Paper} sx={{ p: 5 }}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Stack px={3}>
                <TableContainer
                  sx={{ border: "1px solid", borderColor: "grey.200" }}
                >
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography>Email</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{data?.results?.email}</Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography>Name</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{data?.results?.name}</Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography>Organization</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{data?.results?.organisation}</Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack
                direction="column"
                component={"form"}
                onSubmit={form.handleSubmit(submit)}
                spacing={3}
              >
                <Typography variant="h6" fontSize={"1.5rem"}>
                  Change password
                </Typography>
                <PasswordField
                  label="Old Password"
                  {...form.register("old_password")}
                  control={form.control}
                  errors={form.formState.errors}
                />
                <PasswordField
                  label="New Password"
                  {...form.register("new_password")}
                  control={form.control}
                  errors={form.formState.errors}
                />
                <PasswordField
                  label="Confirm password"
                  {...form.register("new_password2")}
                  control={form.control}
                  errors={form.formState.errors}
                />

                <Button
                  variant="contained"
                  type="submit"
                  sx={{ width: "30ch" }}
                >
                  Change
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </StatusContainer>
    </Box>
  );
}
