import * as React from "react";
import Box from "@mui/material/Box";
import { StatusContainer } from "../../lib/ui/forms/common/status-container";
import { ActivityTable } from "../../components/tables/activities/activties-tables";
import { useActivityQuery } from "../../lib/xhr/activity/activity";
import { ActivitiyHeader } from "./header";
import { useSearchParams } from "react-router-dom";

export default function ActivitiesPage() {
  const [params, setParams] = useSearchParams();
  const block = React.useRef(false);

  const { data, status, refetch } = useActivityQuery(undefined, {
    partner: params.get("partner") as string,
    page: params.get("page") ?? 1,
    page_size: params.get("page_size") ?? 10,
    name: (params.get("name") as string) ?? "",
  });

  const p = {
    name: params.get("name") ?? "",
    partner: params.get("partner") ?? "",
    page: params.get("page") ?? "",
    page_size: params.get("page_size") ?? "",
  };

  React.useEffect(() => {
    const initializePage = () => {
      setParams({
        name: params.get("name") ?? "",
        page: params.get("page") ?? "1",
        partner: params.get("partner") ?? "",
        page_size: params.get("page_size") ?? "10",
      });
    };

    if (block.current === false) {
      block.current = true;
      initializePage();
      // console.log("loaded");
    }
  }, []);

  return (
    <Box sx={{ p: "2rem" }}>
      <Box sx={{ background: "white", borderRadius: ".5rem" }}>
        <ActivitiyHeader />
        <StatusContainer status={status}>
          <ActivityTable
            activities={data?.results}
            count={data?.count}
            pages={data?.pages as number}
          />
        </StatusContainer>
      </Box>
    </Box>
  );
}
