import { Typography, Button, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { CustomAutoComplete } from "../../lib/ui/forms/autoComplete";
import { StatusContainer } from "../../lib/ui/forms/common/status-container";
import { CustomDatePicker } from "../../lib/ui/forms/date-picker";
import { TextField } from "../../lib/ui/forms/text-field";
import { usePartnerQuery } from "../../lib/xhr/partners/partners";
import { zodResolver } from "@hookform/resolvers/zod";
import { useKPIMutation, useKPIQuery } from "../../lib/xhr/kpi/kpi";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../config/theme/theme";
import { CustomModal } from "../../components/modals/custom-modal";
import { CheckboxField } from "../../lib/ui/forms/checkbox";
import { z } from "zod";
import { useActivityQuery } from "../../lib/xhr/activity/activity";
import {
  useMilestoneMutation,
  useMilestoneQuery,
} from "../../lib/xhr/milestones/milestone";
import { CLoadingButton } from "../../components/buttons/button";

const schema = z
  .object({
    activity: z.object({ label: z.string(), value: z.number() }),
    name: z.string().min(1, "Required"),
    start_date: z.date(),
    end_date: z.date(),
    verification_means: z.any(z.string().min(1, "Required")),
    outcome: z.string().min(1, "Required"),
    target: z.string().min(1, "Required"),
    gender_disaggregated: z.any(z.boolean()),
  })
  .superRefine(({ start_date, end_date }, ctx) => {
    const start = new Date(start_date);
    const end = new Date(end_date);

    if (start.getTime() > end.getTime()) {
      // console.log("it is");
      ctx.addIssue({
        code: "custom",
        message: "Start date must be less than end date",
        path: ["end_date"],
      });
    }
  });

type ISchema = z.infer<typeof schema>;

export const AddMilestonePage: React.FC<any> = () => {
  const activityQuery = useActivityQuery();
  const { mutateAsync: milestoneMutAsync, isLoading } = useMilestoneMutation();
  const form = useForm<ISchema>({
    resolver: zodResolver(schema),
    mode: "onChange",
  });
  const milestone = useMilestoneQuery();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  // console.log(form.formState.errors);

  const submit = async (data: ISchema) => {
    const payload = {
      ...data,
      activity: data.activity.value,
      start_date: dayjs(data.start_date).format("YYYY-MM-DD"),
      end_date: dayjs(data.end_date).format("YYYY-MM-DD"),
      gender_disaggregated: data.gender_disaggregated ?? false,
    };
    // console.log(payload);
    const res = await milestoneMutAsync(payload as any);
    if (res.results.messages) {
      return enqueueSnackbar(res.results.messages, {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }

    enqueueSnackbar("Successfully added 1 Milestone", {
      variant: "success",
      anchorOrigin: { vertical: "top", horizontal: "center" },
    });
    // milestone.refetch();
    return navigate(`/milestones?page=1&page_size=10&name=${res.results.name}`);
  };

  return (
    <CustomModal>
      <Stack spacing={3} component="form" onSubmit={form.handleSubmit(submit)}>
        <Stack direction="row" justifyContent="center">
          <Typography variant="h5"> Add Milestone</Typography>
        </Stack>
        <Stack direction="column" spacing={2}>
          <StatusContainer status={activityQuery.status}>
            <CustomAutoComplete
              name="activity"
              options={
                activityQuery.data?.results?.map((activity) => ({
                  label: activity.name,
                  value: activity.id,
                })) ?? []
              }
              labelProps={{ variant: "h6" }}
              label={"Activity"}
              control={form.control}
              errors={form.formState.errors}
            />
          </StatusContainer>
          <TextField
            label="Name"
            labelProps={{ variant: "h6" }}
            name="name"
            control={form.control}
            errors={form.formState.errors}
          />
          <CustomDatePicker
            name="start_date"
            label="Start date"
            labelProps={{ variant: "h6" }}
            control={form.control}
            errors={form.formState.errors}
          />
          <CustomDatePicker
            name="end_date"
            label="End date"
            labelProps={{ variant: "h6" }}
            control={form.control}
            errors={form.formState.errors}
          />
          <TextField
            label="Verification means"
            labelProps={{ variant: "h6" }}
            name="verification_means"
            control={form.control}
            errors={form.formState.errors}
          />
          <TextField
            label="Outcome"
            labelProps={{ variant: "h6" }}
            name="outcome"
            control={form.control}
            errors={form.formState.errors}
            // type="number"
          />
          <TextField
            label="Target"
            labelProps={{ variant: "h6" }}
            name="target"
            control={form.control}
            errors={form.formState.errors}
          />

          <CheckboxField
            name="gender_disaggregated"
            label="Gender disaggregated"
            errors={form.formState.errors}
            control={form.control}
          />
        </Stack>
        <CLoadingButton loading={isLoading} />
      </Stack>
    </CustomModal>
  );
};
