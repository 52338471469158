import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link, useLocation } from "react-router-dom";
import { IsGizGuard } from "../../lib/context/guards/isGizGuard";
import { IsAdminGuard } from "../../lib/context/guards/isAdminGuard";

const ITEM_HEIGHT = 48;

export default function MilestoneActionMenu({ id }: { id: number | string }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    // event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const location = useLocation();

  /**This method removes any '#' character that may cause unexpected bahavior when routing */
  const cleanName = (name: string) => {
    return name?.replace("#", "");
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        <IsGizGuard>
          <MenuItem
            component={Link}
            to={`/milestones/${id}/edit`}
            state={{ backgroundLocation: location }}
          >
            <IconButton></IconButton>
            <Typography>Edit</Typography>
          </MenuItem>
          <IsAdminGuard>
            <MenuItem
              component={Link}
              to={`/milestones/${id}/configure`}
              // state={{ backgroundLocation: location }}
            >
              <IconButton></IconButton>
              <Typography>Configure</Typography>
            </MenuItem>
            <MenuItem
              component={Link}
              to={`/delete/milestones/bymilestoneid/${id}/`}
              state={{ backgroundLocation: location }}
            >
              <IconButton></IconButton>
              <Typography>Delete</Typography>
            </MenuItem>
          </IsAdminGuard>
        </IsGizGuard>
        <MenuItem
          component={Link}
          to={`/milestones/${id}/report`}
          state={{ backgroundLocation: location }}
        >
          <IconButton></IconButton>
          <Typography>Add Report</Typography>
        </MenuItem>
        <MenuItem
          component={Link}
          to={`/milestones-reports/${id}`}
          // state={{ backgroundLocation: location }}
        >
          <IconButton></IconButton>
          <Typography>View Reports</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}
