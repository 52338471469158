import React from "react";
import { useAuth } from "../auth.context";

export const IsGizGuard: React.FC<React.PropsWithChildren> = ({ children }) => {
  const {
    state: { user },
  } = useAuth();

  // console.log("this code was ran", user);

  if (user?.organisation === "GIZ") {
    return <>{children}</>;
  }

  return <>{undefined}</>;
};
