import dayjs from "dayjs";
import { z } from 'zod';


export const cleanActivityPayload = (data: IActivitySchema) => {
	// console.log(data)
	const partner = data.partner?.map((i: any) => i.value);
	const start_date = dayjs(data.start_date).format("YYYY-MM-DD");
	const end_date = dayjs(data.end_date).format("YYYY-MM-DD");
	const gender_disaggregated = data.gender_disaggregated ?? false;
	return {
		...data, partner, end_date, start_date, gender_disaggregated
		// kpi
	};
};

export const cleanActionPayload = (data: IActionSchema) => {
	const start_date = dayjs(data.start_date).format("YYYY-MM-DD");
	const end_date = dayjs(data.end_date).format("YYYY-MM-DD");
	const activity = data.activity?.value;
	const kpi = data.kpi?.value;
	return { ...data, end_date, start_date, activity, kpi };
};

export const cleanTaskPayload = (data: ITaskSchema) => {
	const start_date = dayjs(data.start_date).format("YYYY-MM-DD");
	const end_date = dayjs(data.end_date).format("YYYY-MM-DD");
	const action = data.action?.value;
	const kpi = data.kpi?.value;
	return { ...data, end_date, start_date, action, kpi };
};


export const activitySchema = z.object({
	partner: z.array(z.object({ label: z.string(), value: z.number() })),
	name: z.string(),
	start_date: z.date(),
	end_date: z.date(),
	target: z.string(),
	verification_means: z.any(z.string()),
	// kpi: z.any(z.object({ label: z.string(), value: z.string() })),
	// conversion: z.string(),
	gender_disaggregated: z.any(z.boolean()),
	outcome: z.string()
}).superRefine(({ start_date, end_date }, ctx) => {
	const start = new Date(start_date);
	const end = new Date(end_date);

	if (start.getTime() > end.getTime()) {
		// console.log("it is");
		ctx.addIssue({
			code: "custom",
			message: "Start date must be less than end date",
			path: ["end_date"],
		});
	}
});;

export const actionSchema = z.object({
	name: z.string(),
	start_date: z.date(),
	end_date: z.date(),
	activity: z.object({ label: z.string(), value: z.number() }),
	target: z.string(),
	verification_means: z.string(),
	kpi: z.any(z.object({ label: z.string(), value: z.string() })),
	// conversion: z.string(),
	gender_disaggregated: z.boolean(),
	outcome: z.string()
});

export const taskSchema = z.object({
	name: z.string(),
	start_date: z.date(),
	end_date: z.date(),
	action: z.object({ label: z.string(), value: z.number() }),
	target: z.string(),
	verification_means: z.string(),
	kpi: z.any(z.object({ label: z.string(), value: z.string() })),
	// conversion: z.string(),
	gender_disaggregated: z.boolean(),
	outcome: z.string()
});


export type IActivitySchema = z.infer<typeof activitySchema>
export type IActionSchema = z.infer<typeof actionSchema>
export type ITaskSchema = z.infer<typeof taskSchema>
