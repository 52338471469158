import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import React from "react";
import { Colors } from "../../config/theme/theme";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute" as "absolute",
  top: 0,
  left: "50%",
  transform: "translate(-50%, 5%)",
  width: "40vw",
  height: "60vh",
  maxHeight: "90vh",
  overflow: "auto",
  background: "white",
  padding: "3rem",
  borderRadius: "5px",
  border: "none",
  outline: "none",
};

export const CustomModal: React.FC<
  React.PropsWithChildren<{ height?: any; width?: any; closecb?: any }>
> = (props) => {
  const navigate = useNavigate();
  const handleClose = () => {
    // props.closecb();
    navigate(-1);
  };

  return (
    <Modal
      open={true}
      onClose={handleClose}
      slots={{
        backdrop: () =>
          (
            <Box
              sx={{
                background: Colors.primary.dark,
                height: "100%",
                opacity: 0.6,
              }}
              onClick={(e: any) => handleClose()}
            ></Box>
          ) as any,
      }}
    >
      <Stack sx={{ ...style }} spacing={3}>
        {props.children}
      </Stack>
    </Modal>
  );
};
