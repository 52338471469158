import { Paper, Typography, Button, Stack } from "@mui/material";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { StatusContainer } from "../../lib/ui/forms/common/status-container";
import { CustomModal } from "../../components/modals/custom-modal";
import React from "react";
import { TextField } from "../../lib/ui/forms/text-field";
import { CustomAutoComplete } from "../../lib/ui/forms/autoComplete";
import { CLoadingButton } from "../../components/buttons/button";
import { Colors } from "../../config/theme/theme";
import { useForm } from "react-hook-form";
import {
  UserResponse,
  useUserQuery,
  useUserQueryById,
  useUserUpdateMutation,
} from "../../lib/xhr/users/users";
import {
  PartnerResponse,
  usePartnerQuery,
} from "../../lib/xhr/partners/partners";
import { useQueryClient } from "@tanstack/react-query";

const schema = z.object({
  name: z.string().min(1, "Required"),
  email: z.string().email(),
  organisation: z.object({ label: z.string(), value: z.number() }),
});

export const EditUserPage = () => {
  const { id } = useParams();
  const userQuery = useUserQueryById(id as string);
  const partnerQuery = usePartnerQuery();

  return (
    <Paper sx={{ minHeight: "100vh" }}>
      <StatusContainer status={userQuery.status}>
        <Content
          user={userQuery.data?.results!}
          partners={partnerQuery.data?.results!}
        />
      </StatusContainer>
    </Paper>
  );
};

const Content = ({
  user,
  partners,
}: {
  user: UserResponse;
  partners: PartnerResponse[];
}) => {
  const partnerResult = partners
    ?.map((partner) => {
      if (user.organisation === partner.code) {
        return {
          label: partner.code,
          value: partner.id,
        };
      }
      return undefined;
    })
    .filter((item) => item !== undefined);

  //   console.log("partner result", partnerResult);
  const queryClient = useQueryClient();
  const query = useUserQuery();

  const { mutateAsync: UserMutAsync, isLoading } = useUserUpdateMutation();

  React.useEffect(() => {
    form.setValue("name", user?.name);
    form.setValue("email", user?.email);
    form.setValue("organisation", partnerResult.at(0));
  }, [user]);

  const form = useForm({
    resolver: zodResolver(schema),
    mode: "onChange",
  });

  // console.log(form.formState.errors);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const submit = async (data: any) => {
    const payload = {
      ...data,
      organisation: data.organisation.value,
      user_type: user.user_type,
      id: user.id,
    };
    // console.log("payload", payload);

    await UserMutAsync(
      { ...payload },
      {
        onSuccess: (data) => {
          if (data.results.messages) {
            return enqueueSnackbar(data.results.messages, {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "center" },
            });
          }
          queryClient.invalidateQueries(["getUsers"]);
          enqueueSnackbar("Successfully Edited 1 User", {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          });
          query.refetch();
          return navigate(
            `/user-management?page=1&page_size=10&search_term=${data.results.name}`
          );
        },
      }
    );
  };

  return (
    <CustomModal>
      <Stack direction="row" justifyContent="center" p={1}>
        <Typography variant="h5"> Edit User</Typography>
      </Stack>
      <Stack
        direction="column"
        component="form"
        spacing={2}
        onSubmit={form.handleSubmit(submit)}
      >
        <TextField
          label="Email"
          labelProps={{ variant: "h6" }}
          name="email"
          control={form.control}
          errors={form.formState.errors}
          defaultValue={user?.email}
        />
        <CustomAutoComplete
          name="organisation"
          options={
            partners?.map((partner) => ({
              label: partner.code,
              value: partner.id,
            })) ?? []
          }
          labelProps={{ variant: "h6" }}
          label={"Partner"}
          control={form.control}
          errors={form.formState.errors}
          defaultValue={partnerResult}
          multiple={false}
        />
        <TextField
          label="Name"
          labelProps={{ variant: "h6" }}
          name="name"
          control={form.control}
          errors={form.formState.errors}
          defaultValue={user?.name}
        />
        <Stack direction="row" justifyContent="space-between">
          <Button
            onClick={(e) => navigate(-1)}
            variant="contained"
            sx={{ bgcolor: Colors.info.main }}
          >
            Cancel
          </Button>
          <CLoadingButton loading={isLoading} />
        </Stack>
      </Stack>
    </CustomModal>
  );
};
