import React from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useProfileQuery } from "../../xhr/profile/profile";
import { useAuth } from "../auth.context";

const LoginGuardContext = React.createContext(undefined);

export const LoginGuardProvider: React.FC<React.PropsWithChildren<any>> = ({
  children,
}) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [search, setSearchParams] = useSearchParams();
  const profile = useProfileQuery();

  React.useEffect(() => {
    if (!auth.state.isLoggedIn) {
      if (location.pathname === "/reset/verify") {
        return navigate(
          `/reset/verify?uid=${search.get("uid")}&token=${search.get("token")}`
        );
      }

      return navigate("/auth/login");
    }
    
    if (location.pathname === "/") {
      if(profile && profile.data && profile.data.code === 401)
        {
          return navigate("/auth/logout");
        }
      return navigate("/dashboard");
    }
  }, [auth]);

  return (
    <LoginGuardContext.Provider value={undefined}>
      {children}
    </LoginGuardContext.Provider>
  );
};
