/**
 * Here there will be a context to hold on to the user auth status and reducer to handle all changes
 */

import React, { Reducer, useEffect } from "react";
import { useContext } from "react";
import { useMemo } from "react";
import { UserResponse } from "../xhr/profile/profile";
import { AuthStorageInterface } from "./auth-storage-interface";
import { AuthContextType, AuthActions, AuthState } from "./auth-types";

const AuthContext = React.createContext<AuthContextType | undefined>(undefined);

/** reducer to handle user action on authentication */
function authReducer(state: AuthState, action: AuthActions): AuthState {
  switch (action.action) {
    case "LOGIN":
      if (!action.token) return { loading: false, isLoggedIn: false };

      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        token: action.token,
        refresh: action.refresh,
        // user: action.user,
      };
    case "LOGOUT":
      // console.log("logging out");
      return { loading: false, isLoggedIn: false };
    case "LOAD_USER":
      // console.log("loading user", action);
      return { ...state, user: action.user };
    default:
      return state;
  }
}

/**
 * auth provider for initializing and setting auth status
 */
export const AuthProvider: React.FC<
  React.PropsWithChildren<{ storage: AuthStorageInterface }>
> = ({ children, storage }) => {
  const initialState: AuthState = useMemo(() => storage.get(), [storage]);

  const [auth, dispatch] = React.useReducer<Reducer<AuthState, AuthActions>>(
    authReducer,
    initialState
  );

  useEffect(() => {
    storage.save(auth);
  }, [auth, storage]);

  const context = useMemo<AuthContextType>(
    () => ({
      state: auth,
      login: (token: string, refresh: string) =>
        dispatch({ action: "LOGIN", token, refresh }),
      logout: () => dispatch({ action: "LOGOUT" }),
      load_user: (user: UserResponse) =>
        dispatch({ action: "LOAD_USER", user }),
    }),
    [auth, dispatch]
  );

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext) as AuthContextType;
