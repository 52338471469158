import { Typography, Button, Stack, Modal, Box, Divider } from "@mui/material";
import { useForm } from "react-hook-form";
import { CustomSelect } from "../../lib/ui/forms/select";
import { z } from "zod";
import { CustomModal } from "../../components/modals/custom-modal";
import { ActivityReportForm } from "./report-activity-form";
import { ActionReportForm } from "./report-action-form";
import { TaskReportForm } from "./report-task-form";
import { KPIReportForm } from "./report-kpi-form";

// const schema = z
//   .object({
//     organisation: z.object({ label: z.string(), value: z.number() }),
//   })
//   .required({
//     organisation: true,
//     name: true,
//     report_date: true,
//     report_period: true,
//     verification_means: true,
//     indicator: true,
//     target: true,
//     gender_disaggregated: true,
//     attachment: true,
//     achieved: true,
//   });

export const AddReportPage: React.FC<any> = () => {
  const form = useForm();

  const level = form.watch("level");

  const RenderForm = () => {
    // if (level === "two") {
    //   return <ActionReportForm />;
    // }

    // if (level === "three") {
    //   return <TaskReportForm />;
    // }

    // if (level === "four") {
    return <KPIReportForm />;
    // }
    // return <ActivityReportForm />;
  };

  return (
    <CustomModal>
      <Stack spacing={3}>
        <Stack direction="row" justifyContent="center">
          <Typography variant="h5"> Add Report</Typography>
        </Stack>
        {/* <Stack component="form">
          <CustomSelect
            values={[
              { label: "Activity", value: "one" },
              // { label: "Action", value: "two" },
              // { label: "Task", value: "three" },
              { label: "Kpi", value: "four" },
            ]}
            stackProps={{
              direction: "column",
              spacing: 2,
              alignItems: "start",
            }}
            label="Level"
            labelProps={{ variant: "h6" }}
            inputWidth={"100%"}
            errors={form.formState.errors}
            {...form.register("level")}
          />
        </Stack> */}
        <RenderForm />
      </Stack>
    </CustomModal>
  );
};
