import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { SERVER_URL } from "../../../config/environments/env.config";
import { client, jsonToFormData, ResultObject, ResultPaginatedArray } from "../common/common";
import { handleResFormat } from "../common/common.util";


interface Request {
	name: string,//"Professionalization of ICS production",
	start_date: string,//"2021-10-25",
	end_date: string,//"2024-10-25",
	indicator: number,//"1",
	target: string,//"of professionalised producers",
	verification_means: string,//"test",
	gender_disaggregated: boolean//"male"
	action: number //
}


interface Response {
	id: number,//1,
	action: number,//1,
	code: string,//"1.1.1.1.1",
	name: string,//"test",
	start_date: string,//"2021-10-25T00:00:00Z",
	end_date: string,//"2024-10-25T00:00:00Z",
	kpi: any,//null,
	outcome: string,//"1",
	target: string,//"test",
	created_by: number,//1,
	verification_means: string,//"test",
	gender_disaggregated: boolean,//"male",
	creation_date: string,//"2023-01-01T09:06:11.366846Z",
	last_updated: string,//"2023-01-01T09:06:11.366863Z"
	achieved: string
}

interface UploadReq {
	file: string
}

export type TaskResponse = Response

export const useTaskQuery = () =>
	useQuery<ResultPaginatedArray<Response>>({
		queryKey: ['getTasks'],
		queryFn: (data) =>
			client.get('/api/tasks')
				.then(handleResFormat)
				.catch(err => err)
	})


export const useTaskQueryById = (id: string, enabled?: boolean) =>
	useQuery<ResultObject<Response>>({
		queryKey: ["getTaskById"],
		queryFn: () =>
			client.get('/api/tasks/' + id)
				.then(handleResFormat)
				.catch(err => err),
		enabled: enabled
	})

export const useUpdateTaskMutation = () =>
	useMutation<ResultObject<Response>, string, Request & { id: number }>({
		mutationKey: ['updateTask'],
		mutationFn: (body) =>
			client.put(`/api/tasks/` + body.id, body)
				.then(handleResFormat)
				.catch(err => err)
	})

export const useTaskMutation = () =>
	useMutation<ResultObject<Response>, string, Request>({
		mutationKey: ["addTask"],
		mutationFn: (body) =>
			client.post(`/api/tasks/`, body)
				.then(res => res.data)
				.catch(err => err)

	})

export const useDeleteTaskMutation = () =>
	useMutation({
		mutationFn: (id: string) =>
			client.delete('/api/tasks/' + id)
				.then(handleResFormat)
				.catch(err => err)
	})

export const useTaskUploadMutation = () =>
	useMutation<ResultPaginatedArray<Response>, string, UploadReq>({
		mutationFn: (body) =>
			client.post('/api/uploadexcel/tasks/', jsonToFormData(body))
				.then(handleResFormat)
				.catch(err => err)
	})