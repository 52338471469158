import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CLoadingButton } from "../../../components/buttons/button";
import { Colors } from "../../../config/theme/theme";
import { CustomAutoComplete } from "../../../lib/ui/forms/autoComplete";
import { CheckboxField } from "../../../lib/ui/forms/checkbox";
import { StatusContainer } from "../../../lib/ui/forms/common/status-container";
import { CustomDatePicker } from "../../../lib/ui/forms/date-picker";
import { TextField } from "../../../lib/ui/forms/text-field";
import {
  useActivityMutation,
  useActivityQuery,
} from "../../../lib/xhr/activity/activity";
import { useKPIQuery } from "../../../lib/xhr/kpi/kpi";
import { usePartnerQuery } from "../../../lib/xhr/partners/partners";
import { activitySchema, cleanActivityPayload, IActivitySchema } from "./util";

export const AddActivityForm = () => {
  const activityQuery = useActivityQuery();
  const partnerQuery = usePartnerQuery();
  const mut = useActivityMutation();

  const patrnerList =
    partnerQuery?.data?.results?.map((partner) => ({
      label: partner.code,
      value: partner.id,
    })) ?? [];

  const form = useForm<IActivitySchema>({
    resolver: zodResolver(activitySchema),
    mode: "onChange",
  });

  // console.log(form.formState.errors);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const submit = (data: IActivitySchema) => {
    const payload = cleanActivityPayload(data);
    // console.log(payload);
    mut.mutate(payload as any, {
      onSuccess: (data) => {
        // console.log(data);
        if (data.results.messages) {
          return enqueueSnackbar(data.results.messages, {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          });
        }
        // console.log(data);

        enqueueSnackbar("Successfully Added 1 Item", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
        return navigate(
          `/activities?page=1&page_size=10&name=${data.results.name}`
        );
      },
    });
  };

  return (
    <Stack
      direction="column"
      component="form"
      spacing={2}
      onSubmit={form.handleSubmit(submit)}
    >
      <StatusContainer status={partnerQuery.status}>
        <CustomAutoComplete
          name="partner"
          options={patrnerList ? patrnerList : []}
          labelProps={{ variant: "h6" }}
          label={"Partner"}
          control={form.control}
          multiple={true}
          errors={form.formState.errors}
        />
      </StatusContainer>
      <TextField
        label="Name"
        labelProps={{ variant: "h6" }}
        name="name"
        control={form.control}
        errors={form.formState.errors}
      />
      <CustomDatePicker
        name="start_date"
        label="Start date"
        labelProps={{ variant: "h6" }}
        control={form.control}
        errors={form.formState.errors}
      />
      <CustomDatePicker
        name="end_date"
        label="End date"
        labelProps={{ variant: "h6" }}
        control={form.control}
        errors={form.formState.errors}
      />
      <TextField
        label="Outcome"
        labelProps={{ variant: "h6" }}
        name="outcome"
        control={form.control}
        errors={form.formState.errors}
      />
      <TextField
        label="Target"
        labelProps={{ variant: "h6" }}
        name="target"
        control={form.control}
        errors={form.formState.errors}
      />
      <TextField
        label="Verification means"
        labelProps={{ variant: "h6" }}
        name="verification_means"
        control={form.control}
        errors={form.formState.errors}
      />
      <CheckboxField
        name="gender_disaggregated"
        label="Gender disaggregated"
        errors={form.formState.errors}
        control={form.control}
      />
      <CLoadingButton loading={mut.isLoading} />
    </Stack>
  );
};
