import * as React from "react";
import Box from "@mui/material/Box";
// import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PieChartIcon from "@mui/icons-material/PieChart";
import ReceiptIcon from "@mui/icons-material/Receipt";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import GroupsIcon from "@mui/icons-material/Groups";
import CandlestickChartIcon from "@mui/icons-material/CandlestickChart";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { NavLink, Outlet, useMatch, useNavigate } from "react-router-dom";
import Header from "./header.component";
import Typography from "@mui/material/Typography";
import { Colors } from "../../config/theme/theme";
import { IsGizGuard } from "../../lib/context/guards/isGizGuard";
import { Drawer, DrawerHeader } from "./drawer";
import { IconButton, useTheme } from "@mui/material";

const drawerWidth = 240;

export default function Layout() {
  // const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  // console.log("this code was ran");
  return (
    <Box sx={{ display: "flex" }}>
      <Header open={open} handleDrawerOpen={handleDrawerToggle} />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            // width: drawerWidth,
            boxSizing: "border-box",
            background: Colors.primary.dark,
          },
        }}
        variant="permanent"
        // anchor="left"
        open={open}
      >
        <Toolbar
          sx={{ background: Colors.primary.dark, display: "flex", gap: 3 }}
        >
          <img src="/logo.svg" />
          <Typography
            variant="h1"
            noWrap
            component="div"
            sx={{ fontSize: "18px", color: "white", fontWeight: "bold" }}
          >
            GCF Monitoring{" "}
          </Typography>
        </Toolbar>
        {/* <DrawerHeader>
          <IconButton onClick={handleDrawerClose} sx={{ color:'' }} >
            {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader> */}
        <Divider />
        <List sx={{ marginTop: "5rem", color: "white" }}>
          <MenuItem
            text="Dashboard"
            Icon={(props: any) => (
              <PieChartIcon
                fontSize="small"
                sx={{
                  color: props.active
                    ? Colors.primary.light
                    : Colors.primary.contrastText,
                }}
              />
            )}
            to="/dashboard"
          />
          <MenuItem
            text="KPIs"
            Icon={(props: any) => (
              <AssessmentIcon
                fontSize="small"
                sx={{
                  color: props.active
                    ? Colors.primary.light
                    : Colors.primary.contrastText,
                }}
              />
            )}
            to="/kpis"
          />
          <MenuItem
            text="Activities"
            Icon={(props: any) => (
              <PendingActionsIcon
                fontSize="small"
                sx={{
                  color: props.active
                    ? Colors.primary.light
                    : Colors.primary.contrastText,
                }}
              />
            )}
            to="/activities"
          />
          <MenuItem
            text="Milestones"
            Icon={(props: any) => (
              <ManageAccountsIcon
                fontSize="small"
                sx={{
                  color: props.active
                    ? Colors.primary.light
                    : Colors.primary.contrastText,
                }}
              />
            )}
            to="/milestones"
          />
          <MenuItem
            text="KPI Reporting"
            Icon={(props: any) => (
              <ReceiptIcon
                fontSize="small"
                sx={{
                  color: props.active
                    ? Colors.primary.light
                    : Colors.primary.contrastText,
                }}
              />
            )}
            to="/reports"
          />
          <MenuItem
            text="Summary"
            Icon={(props: any) => (
              <SummarizeIcon
                fontSize="small"
                sx={{
                  color: props.active
                    ? Colors.primary.light
                    : Colors.primary.contrastText,
                }}
              />
            )}
            to="/summary"
          />
          <IsGizGuard>
            <MenuItem
              text="Role Management"
              Icon={(props: any) => (
                <GroupsIcon
                  fontSize="small"
                  sx={{
                    color: props.active
                      ? Colors.primary.light
                      : Colors.primary.contrastText,
                  }}
                />
              )}
              to="/roles"
            />

            <MenuItem
              text="User Management"
              Icon={(props: any) => (
                <ManageAccountsIcon
                  fontSize="small"
                  sx={{
                    color: props.active
                      ? Colors.primary.light
                      : Colors.primary.contrastText,
                  }}
                />
              )}
              to="/user-management"
            />
            <MenuItem
              text="Activity Log"
              Icon={(props: any) => (
                <CandlestickChartIcon
                  fontSize="small"
                  sx={{
                    color: props.active
                      ? Colors.primary.light
                      : Colors.primary.contrastText,
                  }}
                />
              )}
              to="/activity-log"
            />
          </IsGizGuard>

          <MenuItem
            text="Profile"
            Icon={(props: any) => (
              <LoginIcon
                fontSize="small"
                sx={{
                  color: props.active
                    ? Colors.primary.light
                    : Colors.primary.contrastText,
                }}
              />
            )}
            to="/profile"
          />
          <MenuItem
            text="Logout"
            Icon={(props: any) => (
              <LogoutIcon
                fontSize="small"
                sx={{
                  color: props.active
                    ? Colors.primary.light
                    : Colors.primary.contrastText,
                }}
              />
            )}
            to="/auth/logout"
          />
          {/* <MenuItem
            text="Sign Up"
            Icon={(props: any) => (
              <LockOpenIcon
                fontSize="small"
                sx={{
                  color: props.active
                    ? Colors.primary.light
                    : Colors.primary.contrastText,
                }}
              />
            )}
            to="/auth/sign-up"
          /> */}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: Colors.info.extraLight, p: 3 }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}

const MenuItem = ({ text, Icon, to }: any) => {
  const navigate = useNavigate();
  const match = useMatch(to);

  return (
    <ListItem
      disablePadding
      onClick={(e) => navigate(to)}
      sx={{
        background: Boolean(match) ? Colors.primary.main : "",
        my: 1,
        borderLeft: Boolean(match) ? `5px solid ${Colors.primary.light}` : "",
      }}
    >
      <ListItemButton>
        <ListItemIcon>
          <Icon active={Boolean(match)} />
        </ListItemIcon>
        <ListItemText
          primary={text}
          sx={{
            color: Boolean(match)
              ? Colors.primary.light
              : Colors.primary.contrastText,
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};
