import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import {
  GroupResponse,
  useGroupRemovePermissionMutation,
} from "../../../lib/xhr/roles/roles";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useLocation, Link } from "react-router-dom";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { CustomPagination } from "../../Pagination/pagination-component";
import { Colors } from "../../../config/theme/theme";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof GroupResponse;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "ID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "permissions",
    numeric: false,
    disablePadding: false,
    label: "Permissions",
  },
];

interface EnhancedTableProps {
  numSelected?: number;
  onRequestSort?: (
    event: React.MouseEvent<unknown>,
    property: keyof GroupResponse
  ) => void;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order?: Order;
  orderBy?: string;
  rowCount?: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof GroupResponse) => (event: React.MouseEvent<unknown>) => {
      onRequestSort!(event, property);
    };

  return (
    <TableHead>
      <TableRow sx={{ background: Colors.primary.dark }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{
                color: "white! important",
                "&:hover": { color: "red! important" },
                "&:focus": { color: "red! important" },
                "& .MuiTableSortLabel-icon": {
                  color: "white",
                },
                "&.Mui-active .MuiTableSortLabel-icon": {
                  opacity: 1,
                  color: "red",
                },
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell
          sx={{
            color: "white",
            "&:hover": { color: "red! important" },
            "&:focus": { color: "red! important" },
          }}
        >
          More Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
}

export default function GroupsTable(props: any) {
  const { rows, refetch } = props;
  // debugger;
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof GroupResponse>("id");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  // const [page, setPage] = React.useState(0);
  // const [dense, setDense] = React.useState(false);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const location = useLocation();
  const purge = useGroupRemovePermissionMutation();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof GroupResponse
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <CustomPagination
          advanced={false}
          count={props.count}
          page={props.page}
          pages={props.pages}
          position="start"
          rowsPerPage={props.rowsPerPage}
          setPage={props.setPage}
          setRowsPerPage={props.setRowsPerPage}
        />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy as any}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy)).map(
                (row: any, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        // padding="none"
                        align="left"
                      >
                        {row.id}
                      </TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">
                        {row.permissions.map((p: any) => (
                          <Button
                            variant="outlined"
                            sx={{ m: "1px" }}
                            size="small"
                          >
                            <Stack direction="row" spacing={2}>
                              {p.name}{" "}
                              <HighlightOffIcon
                                onClick={(e) => {
                                  purge.mutate(
                                    {
                                      group: row.name,
                                      permissions: [p.id as string],
                                    },
                                    {
                                      onSuccess: (data) => {
                                        refetch();
                                      },
                                    }
                                  );
                                }}
                              />
                            </Stack>
                          </Button>
                        ))}
                      </TableCell>
                      <TableCell>
                        <Button
                          component={Link}
                          state={{ backgroundLocation: location }}
                          to={`/roles/add-permission/${row.name}`}
                        >
                          Add permission
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomPagination
          advanced={true}
          count={props.count}
          page={props.page}
          pages={props.pages}
          position="end"
          rowsPerPage={props.rowsPerPage}
          setPage={props.setPage}
          setRowsPerPage={props.setRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
