import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link, useLocation } from "react-router-dom";
import { useKPIQuery } from "../../lib/xhr/kpi/kpi";
import { useActivityQuery } from "../../lib/xhr/activity/activity";
import { IsGizGuard } from "../../lib/context/guards/isGizGuard";

const ITEM_HEIGHT = 48;

export default function ActionMenu({
  id,
  name,
  route,
}: {
  id: number | string;
  name: string;
  route: string;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    // event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const location = useLocation();

  /**This method removes any '#' character that may cause unexpected bahavior when routing */
  const cleanName = (name: string) => {
    return name?.replace("#", "");
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {route === "reports" ? (
          <>
            <MenuItem
              component={Link}
              to={`/${route}/edit/${id}`}
              state={{ backgroundLocation: location }}
            >
              <IconButton>
                <EditIcon fontSize="small" />
              </IconButton>
              <Typography>Edit</Typography>
            </MenuItem>
            <MenuItem
              component={Link}
              to={`/delete/${route}/${cleanName(name)}/${id}/`}
              state={{ backgroundLocation: location }}
            >
              <IconButton>
                <DeleteIcon fontSize="small" />
              </IconButton>
              <Typography>Delete</Typography>
            </MenuItem>
          </>
        ) : (
          <IsGizGuard>
            <MenuItem
              component={Link}
              to={`/${route}/edit/${id}`}
              state={{ backgroundLocation: location }}
            >
              <IconButton>
                <EditIcon fontSize="small" />
              </IconButton>
              <Typography>Edit</Typography>
            </MenuItem>
            <MenuItem
              component={Link}
              to={`/delete/${route}/${cleanName(name)}/${id}/`}
              state={{ backgroundLocation: location }}
            >
              <IconButton>
                <DeleteIcon fontSize="small" />
              </IconButton>
              <Typography>Delete</Typography>
            </MenuItem>
          </IsGizGuard>
        )}
      </Menu>
    </div>
  );
}
