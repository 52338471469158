import { AxiosError } from 'axios';
import { ResultObject } from './common';


export function handleResFormat(data: any) {
	// if (data.data.code === 401) {
	// 	console.log("you should login")

	// }
	if (data?.data.messages) {
		// console.log("we have messages")
		// we have an error, we should get field and message
		if (typeof data.data.messages === "object") {
			// console.log("its an object")
			data.results = {}
			data.results.messages = Object.entries(data.data.messages).map(([, value]) => {
				if (typeof value === "object") {
					return Object.entries(value!).map(([key, value]) => `${key} ${value}`).join('</br>')
				}

				return value;
			})
				.join('<br/>');
			return data
		}

		if (typeof data.data.messages === "string") {
			data.results = {}
			data.results.messages = data.data.messages;
			return data;
		}
	}
	return data?.data;
}


export type ErrorModel = Omit<ResultObject<unknown>, 'data'> & {
	errors?: Record<string, string[]>;
	detail?: any
};


export function handleErrFormat(error: AxiosError) {
	// console.log(error.response?.data.errors as any) 
	// console.log(error.message)
	const status = error.response?.status
	if (error instanceof AxiosError) {
		const data = error.response?.data as ErrorModel;
		// console.log('it is', data.errors)
		// if (!data?.errors) return { error: data.detail, status };
		if (typeof data.errors === 'string') return { error: data.errors, status };
		if (data.errors instanceof Array) return { error: data.errors?.join('<br/>'), status };
		if (typeof data.errors === 'object')
			return {
				error: Object.entries(data.errors)
					.map(([, value]) => value)
					.join('<br/>'),
				status
			}
	}
	return error?.message ? { error: error?.message, status } : null;

}