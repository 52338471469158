import React from "react";
import { useAuth } from "../auth.context";

export const UploadDownloadGuard: React.FC<
  React.PropsWithChildren<{ created_by_id: number }>
> = ({ children, created_by_id }) => {
  const {
    state: { user },
  } = useAuth();

  // console.log("this code was ran", user);

  if (user?.organisation === "GIZ" || user?.id === created_by_id) {
    return <>{children}</>;
  }

  return <>{undefined}</>;
};
