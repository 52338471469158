import { useMutation, useQuery } from "@tanstack/react-query";
import { client, ResultPaginatedArray } from "../common/common";
import { handleErrFormat, handleResFormat } from "../common/common.util";

interface PermissionResponse {
	id: number,
	name: string
	codename: string
}

export interface GroupResponse {
	id: number,
	name: string,
	permissions: string[]
}

interface AddGroupRequest {
	name: string,//"system user",
	permissions: number[]
}

interface AddGroupPermissionRequest {
	group: string,
	permissions: number[]
}

interface RemoveGroupPermissionRequest {
	group: string,
	permissions: string[]
}

interface GroupQueryParams {
	page?: number | string,
	page_size?: number | string
}


export const usePermissionQuery = () =>
	useQuery<ResultPaginatedArray<PermissionResponse>>({
		queryKey: ["getPermissions"],
		queryFn: () =>
			client
				.get("/api/permissions/")
				.then(handleResFormat)
				.catch(handleErrFormat),
	});


export const useGroupQuery = (params?: GroupQueryParams) =>
	useQuery<ResultPaginatedArray<GroupResponse>>({
		queryKey: ["getGroups", { ...params }],
		queryFn: () =>
			client
				.get("/api/groups/", { params })
				.then(handleResFormat)
				.catch(handleErrFormat),
	});


export const useGroupAddMutation = () =>
	useMutation<GroupResponse, string, AddGroupRequest>({
		mutationKey: ['addGroup'],
		mutationFn: (body) =>
			client.post('/api/groups/', body)
				.then(handleResFormat)
				.catch(handleErrFormat)
	})

export const useGroupAddPermissionMutation = () =>
	useMutation<GroupResponse, string, AddGroupPermissionRequest>({
		mutationKey: ['addGroupPermission'],
		mutationFn: (body) =>
			client.post('/api/groups/addpermission/', body)
				.then(handleResFormat)
				.catch(handleErrFormat)
	})

export const useGroupRemovePermissionMutation = () =>
	useMutation({
		mutationKey: ['removeGroupPermission'],
		mutationFn: (body: RemoveGroupPermissionRequest) =>
			client.post('/api/groups/removepermission/', body)
				.then(handleResFormat)
				.catch(handleErrFormat)
	})

