import React, { useEffect } from "react";
import { MenuItem, Select, Stack, Typography } from "@mui/material";
import { CustomModal } from "../../components/modals/custom-modal";
import { useForm } from "react-hook-form";
import { CLoadingButton } from "../../components/buttons/button";
import { useNavigate, useParams } from "react-router-dom";
import {
  useMilestoneParamsQueryById,
  useUpdateMilestoneParamsMutation,
} from "../../lib/xhr/milestones/milestone";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { TextField } from "../../lib/ui/forms/text-field";
import { CheckboxField } from "../../lib/ui/forms/checkbox";
import { useSnackbar } from "notistack";
import { useQueryClient } from "@tanstack/react-query";

const schema = z.object({
  id: z.number(),
  milestone: z.number(),
  label_name: z.string(),
  label_desc: z.string(),
  label_type: z.string(),
  label_order: z.string(),
  is_required: z.boolean(),
});
export const EditMilestoneParamPage = () => {
  const navigate = useNavigate();
  const { param_id, milestone_id } = useParams();
  const query = useMilestoneParamsQueryById(milestone_id as string);
  const mut = useUpdateMilestoneParamsMutation();
  const param = query.data?.results?.filter(
    (param) => param.id === parseInt(param_id as string)
  );
  
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const form = useForm({
    resolver: zodResolver(schema),
    mode: "onChange",
  });

  //   console.log("error", form.formState.errors);
  useEffect(() => {
    if (param?.length) {
      form.setValue("id", param[0].id);
      form.setValue("milestone", param[0].milestone);
      form.setValue("label_name", param[0].label_name);
      form.setValue("label_desc", param[0].label_desc);
      form.setValue("label_type", param[0].label_type);
      form.setValue("label_order", param[0].label_order);
      form.setValue("is_required", param[0].is_required);
    }
  }, [param, query]);

  const submit = (data: any) => {
    console.log("submit", data);
    mut.mutate(data, {
      onSuccess: (data) => {
        enqueueSnackbar("Milestone param updated successfully", {
          variant: "success",
        });
        queryClient.invalidateQueries(["get miletone params"]);
        query.refetch();
        navigate(-1);
      },
      onError: (error) => {
        enqueueSnackbar("Failed to update milestone param", {
          variant: "error",
        });
      },
    });
  };
// 
  return (
    <CustomModal>
      <Stack spacing={3} component="form" onSubmit={form.handleSubmit(submit)}>
        <Stack direction="row" justifyContent="center">
          <Typography variant="h5"> Edit Milestone Param</Typography>
        </Stack>
        <Stack direction="column" spacing={2}>
          <TextField
            name="label_name"
            label="Label name*"
            errors={form.formState.errors}
            control={form.control}
            defaultValue={param?.at(0)?.label_name}
          />
          <TextField
            name="label_desc"
            label="Label description*"
            errors={form.formState.errors}
            control={form.control}
            defaultValue={param?.at(0)?.label_name}
          />
          <TextField
            name="label_order"
            label="Label order*"
            type="number"
            errors={form.formState.errors}
            control={form.control}
            defaultValue={param?.at(0)?.label_order}
          />
          <Select
            // name="label_type"
            // value={param?.at(0)?.label_type}
            {...form.register("label_type")}
            size="small"
            displayEmpty
            sx={{ minWidth: "100%" }}
            onChange={(e) => {
              console.log(e.target.value);
              form.setValue("label_type", e.target.value);
            }}
            defaultValue={param?.at(0)?.label_type ?? "text"}
          >
            <MenuItem disabled>Select input type</MenuItem>
            <MenuItem value="text">TEXT</MenuItem>
            <MenuItem value="number">NUMBER</MenuItem>
            <MenuItem value="select">SELECT</MenuItem>
            <MenuItem value="file">FILE</MenuItem>
            <MenuItem value="date">DATE</MenuItem>
          </Select>
          <CheckboxField
            name="is_required"
            label="Is Required*"
            errors={form.formState.errors}
            control={form.control}
            // defaultChecked={param?.at(0)?.is_required}
          />
        </Stack>
        <CLoadingButton loading={form.formState.isSubmitting} />
      </Stack>
    </CustomModal>
  );
};
