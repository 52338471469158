import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import React from "react";
import { useMilestoneParamsQueryById } from "../../lib/xhr/milestones/milestone";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export const MilestoneParamListing = () => {
  const { id } = useParams();
  const query = useMilestoneParamsQueryById(id as string);
  const location = useLocation();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align={"left"}>#</TableCell>
            <TableCell align={"left"}>Name</TableCell>
            <TableCell align={"left"}>Label Description</TableCell>
            <TableCell align={"left"}>Is Required*</TableCell>
            <TableCell align={"left"}>Type*</TableCell>
            <TableCell align={"left"}>Label Order*</TableCell>
            <TableCell align={"left"}>Edit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            Array.isArray(query.data?.results) ? (
              query.data?.results?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align={"left"}>{index + 1}</TableCell>
                  <TableCell align={"left"}>{row.label_name}</TableCell>
                  <TableCell align={"left"}>{row.label_desc}</TableCell>
                  <TableCell align={"left"}>
                    {row.is_required ? "Yes" : "No"}
                  </TableCell>
                  <TableCell align={"left"}>{row.label_type}</TableCell>
                  <TableCell align={"left"}>{row.label_order}</TableCell>
                  <TableCell align={"left"}>
                    <IconButton
                      component={Link}
                      to={`/milestone-param/${id}/${row.id}/edit`}
                      state={{ backgroundLocation: location }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      component={Link}
                      to={`/delete/milestone-param/byid/${row.id}/`}
                      state={{
                        backgroundLocation: {
                          ...location,
                          state: {
                            id,
                          }
                        }
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No data available.
                </TableCell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};
