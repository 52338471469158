import { Button, Paper, Toolbar, Typography, Grid } from "@mui/material";
import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import { Colors } from "../../config/theme/theme";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { TextField } from "../../lib/ui/forms/text-field";
import { Link, useNavigate } from "react-router-dom";
import { useForgotPassword, useLoginMutation } from "../../lib/xhr/auth/auth";
import { useAuth } from "../../lib/context/auth.context";
import { useSnackbar } from "notistack";

const schema = z
  .object({
    email: z.string().email(),
  })
  .required({ email: true });

export default function ForgotPasswordPage() {
  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
  } = useForm({
    resolver: zodResolver(schema),
  });

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const mut = useForgotPassword();

  // console.log(errors);

  const submitHandler = (data: any) => {
    mut.mutate(data, {
      onSuccess: (data: any) => {
        // console.log(data);
        if (data.results.messages) {
          return enqueueSnackbar(data.results.messages, {
            variant: "success",
            anchorOrigin: { horizontal: "center", vertical: "top" },
          });
        }
      },
    });
  };

  return (
    <Paper
      sx={{
        background: Colors.primary.dark,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Grid container justifyContent={"center"}>
        <Grid item xs={12} md={4}>
          <Paper
            sx={{ p: 5, background: "white" }}
            component="form"
            onSubmit={handleSubmit(submitHandler)}
          >
            <Stack direction="column" alignItems={"center"} spacing={2}>
              <img src="/logo.svg" alt="logo" />
              <Typography color={`${Colors.primary.main}`}>
                GCF Monitoring Tool
              </Typography>

              <Typography
                color={`${Colors.secondary.dark}`}
                variant="h4"
                fontWeight="600"
              >
                Forgot password
              </Typography>
              <Typography>
                Enter your email below, an email will be sent with further
                instructions
              </Typography>
            </Stack>
            <Toolbar />
            <Stack spacing={2} direction="column">
              <TextField
                label="Email"
                errors={errors}
                name="email"
                control={control}
              />
              <Button variant="contained" type="submit">
                Submit
              </Button>
            </Stack>
            <Stack direction={"column"} spacing={2} sx={{ my: 2 }}>
              <Typography>
                Remembered password ?
                <Typography component={Link} to="/auth/login">
                  Login
                </Typography>
              </Typography>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
}
