import { useMutation, useQuery } from "@tanstack/react-query";
import { client, jsonToFormData, ResultArray, ResultObject, ResultPaginatedArray } from "../common/common";
import { handleErrFormat, handleResFormat } from "../common/common.util";


interface MilestoneRequest {
	activity: string,//1.3.1,
	name: string,//milestone B,
	start_date: string,//2021-10-25T00:00:00Z,
	end_date: string,//2024-10-25T00:00:00Z,
	outcome: string,//sample indicator,
	target: string,//10000,
	verification_means: string,//report,
	gender_disaggregated: string,//false
	id?: string
}


interface Response {
	id: string,//1,
	activity: number,//5,
	name: string,//milestone B,
	start_date: string,//2021-10-25T00:00:00Z,
	end_date: string,//2024-10-25T00:00:00Z,
	outcome: string,//sample indicator,
	target: string,//10000,
	verification_means: string,//report,
	gender_disaggregated: boolean,//false,
	created_by: string,//null,
	creation_date: string,//2023-02-09T12:06:49.012486Z,
	last_updated: string,//2023-02-09T12:06:49.012501Z
	achieved: string,
	code: string,
	male_achieved: string,
	female_achieved: string
}


interface MilestoneReportResponse {
	id: number | string//1,
	milestone: number | string,
	created_by: string,//null,
	records: {
		id: number,//1,
		milestonereport: number,//1,
		label: string,//"producer_name",
		value: string,//"realer",
		value_file: string,//null
	}[]
}

export type MilestoneResponse = Response

export interface ParamResponse {
	id: number,//1,
	milestone: number,//1,
	label_name: string,//"producer_name",
	label_desc: string,//"Producer Name",
	label_type: string,//"text",
	is_required: boolean,//true
	label_order: string //i changed it from string to number
}


interface MilestoneReportRequest {
	milestone: string,
	records: any
	// {
	// 	label: string,
	// 	value: any,
	// 	value_file: any
	// }
}

interface MilestoneParams {
	page?: number | string,
	page_size?: number | string,
	name?: string,
	search_term?: string,
	partner?: string
}


export const useMilestoneMutation = () =>
	useMutation<ResultObject<Response>, string, MilestoneRequest>({
		mutationKey: ['add milestone'],
		mutationFn: (body) =>
			client.post('/api/milestones/', body)
				.then(handleResFormat)
				.catch(handleErrFormat)
	})

export const useDeleteMilestoneMutation = () =>
	useMutation({
		mutationFn: (id: string) =>
			client.delete('/api/milestones/' + id)
				.then(handleResFormat)
				.catch(err => err)
	})

export const useMilestoneUpdateMutation = () =>
	useMutation<ResultObject<Response>, string, MilestoneRequest>({
		mutationKey: ['add milestone'],
		mutationFn: ({ id, ...body }) =>
			client.put('/api/milestones/' + id + '/', body)
				.then(handleResFormat)
				.catch(handleErrFormat)
	})


export const useMilestoneQuery = (params?: MilestoneParams) =>
	useQuery<ResultPaginatedArray<Response>>({
		queryKey: ["get milestone", { ...params }],
		queryFn: () =>
			client.get('/api/milestones', { params })
				.then(handleResFormat)
				.catch(handleErrFormat)
	})

export const useMilestoneQueryById = (id: string) =>
	useQuery<ResultObject<Response>>({
		queryKey: ["get milestone", id],
		queryFn: () =>
			client.get('/api/milestones/' + id)
				.then(handleResFormat)
				.catch(handleErrFormat)
	})
export const useMileStoneReportsQueryById = (id: string, params?: MilestoneParams) =>
	useQuery<ResultPaginatedArray<MilestoneReportResponse>>({
		queryKey: ["get milestone report", id, { ...params }],
		queryFn: () =>
			client.get('/api/milestonesreport/getbymilestoneid/' + id, { params })
				.then(handleResFormat)
				.catch(handleErrFormat),
		staleTime: 0,
		cacheTime: 0
	})



export const useMileStoneReportsDeleteById = () =>
	useMutation<ResultObject<MilestoneReportResponse>, string, { id: string }>({
		mutationKey: ["delete milestone report"],
		mutationFn: ({ id }) =>
			client.delete('/api/milestonesreport/' + id)
				.then(handleResFormat)
				.catch(handleErrFormat)
	})
// export const useMilestoneParamsQueryById = () =>
// 	useMutation<ResultObject<ParamResponse>, string, { milestone: string }>({
// 		mutationKey: ['get miletone params'],
// 		mutationFn: (body) =>
// 			client.post('/api/milestoneparams/getbymilestoneid/', body)
// 				.then(handleResFormat)
// 				.catch(handleErrFormat)
// 	})

export const useMilestoneParamsQueryById = (id: string) =>
	useQuery<ResultArray<ParamResponse>>({
		queryKey: ['get miletone params'],
		queryFn: () =>
			client.get(`/api/milestoneparams/getbymilestoneid/${id}`)
				.then(handleResFormat)
				.catch(handleErrFormat)
	})


export const useReportMilestoneMutation = () =>
	useMutation<ResultObject<any>, string, any>({
		mutationFn: (body) => {
			// console.log("body", body)
			return client.post('/api/milestonesreport/',
				// jsonToFormData(body)
				body
			)
				.then(handleResFormat)
				.catch(handleErrFormat)

		}
	})

export const useDeleteMilestoneParamsMutation = () =>
	useMutation<ResultObject<ParamResponse>, string, { id: string }>({
		mutationKey: ["delete milestone param"],
		mutationFn: ({ id }) =>
			client.delete('/api/milestoneparams/' + id)
				.then(handleResFormat)
				.catch(handleErrFormat)
	})

interface MilestoneParamsRequest {
	milestone: string,
	label_name: string,
	label_desc: string,
	label_type: string,
	is_required: boolean,
	label_order: string,
}

interface UpdateMilestoneParamRequest extends MilestoneParamsRequest {
	id: string
}
export const useAddMilestoneParamsMutation = () =>
	useMutation<ResultObject<any>, string, MilestoneParamsRequest[]>({
		mutationFn: (body) => {
			return client.post('/api/milestoneparams/',
				body
			)
				.then(handleResFormat)
				.catch(handleErrFormat)

		}
	})

export const useUpdateMilestoneParamsMutation = () =>
	useMutation<ResultObject<UpdateMilestoneParamRequest>, string, UpdateMilestoneParamRequest>({
		mutationFn: (body) => {
			return client.put('/api/milestoneparams/' + body.id + '/',
				body
			)
				.then(handleResFormat)
				.catch(handleErrFormat)

		}
	})
