import { Box } from "@mui/material";
import Badge from "@mui/material/Badge";

export const AchievedBadge = ({
  target,
  achieved,
  dueDate,
}: {
  target: number;
  achieved: number;
  dueDate: Date | string;
}) => {
  // if today is past due date and achieved is less than target ===> badge color red
  // if achieved is equal to or greater than  target ==> badge color green
  // if not past due date, badge color amber

  const today = new Date();
  const due_date = new Date(dueDate);

  const getColor = () => {
    if (due_date.getTime() < today.getTime() && target > achieved) {
      return "#F12B2C";
    }

    if (achieved >= target) {
      return "#29CC97";
    }

    return "#FEC400";
  };

  return (
    <Box
      //   bgcolor={getColor()}
      sx={{
        background: getColor(),
        height: ".3rem",
        width: "auto",
        px: 4,
        py: 2,
        color: "blue",
        borderRadius: "25px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "bold",
      }}
    >
      {achieved}
    </Box>
  );
};
