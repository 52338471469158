import { useForm } from "react-hook-form";
import { Stack, Typography } from "@mui/material";
import { CustomSelect } from "../../../lib/ui/forms/select";
import { CustomModal } from "../../../components/modals/custom-modal";
import { UploadActivtyForm } from "./upload-activity";
import { UploadActionForm } from "./upload-action";
import { UploadTaskForm } from "./upload-task";

export const UploadActivitiesPage = () => {
  const form = useForm();
  const level = form.watch("level");

  const RenderForm = () => {
    // if (level === "one") {
    //   return <UploadActivtyForm />;
    // }

    // if (level === "two") {
    //   return <UploadActionForm />;
    // }

    // if (level === "three") {
    //   return <UploadTaskForm />;
    // }

    return <UploadActivtyForm />;
  };

  return (
    <CustomModal>
      <Stack direction="row" justifyContent="center" p={1}>
        <Typography variant="h5"> Upload Activity</Typography>
      </Stack>
      {/* <Stack direction="column" spacing={2} component={"form"}>
        <CustomSelect
          values={[
            { label: "Activity", value: "one" },
            { label: "Action", value: "two" },
            { label: "Task", value: "three" },
          ]}
          defaultValue={{ label: "Activity", value: "one" }}
          stackProps={{
            direction: "column",
            spacing: 2,
            alignItems: "start",
          }}
          label="Level"
          labelProps={{ variant: "h6" }}
          inputWidth={"100%"}
          errors={form.formState.errors}
          {...form.register("level")}
        />
      </Stack> */}
      <RenderForm />
    </CustomModal>
  );
};
