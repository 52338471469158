import {
  Box,
  Grid,
  Typography,
  Button,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  IconButton,
  Table,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import MilestonesTable from "../../components/tables/milestones/milestones";
import { Colors } from "../../config/theme/theme";
import {
  LoadingContainer,
  StatusContainer,
} from "../../lib/ui/forms/common/status-container";
import {
  useMilestoneParamsQueryById,
  useMileStoneReportsDeleteById,
  useMileStoneReportsQueryById,
} from "../../lib/xhr/milestones/milestone";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import { UploadDownloadGuard } from "../../lib/context/guards/uploadGuard";

export const MilestoneDetailPage = () => {
  const { id } = useParams();
  const [queryParams, setQueryParams] = useSearchParams();
  const [search_term, setSearchTerm] = React.useState<string>(null!);
  const params = useMilestoneParamsQueryById(id as string);
  const { data, isLoading } = useMileStoneReportsQueryById(id as string, {
    search_term: search_term ?? (queryParams.get("search_term") as string),
  });
  const navigate = useNavigate();
  const deleteMut = useMileStoneReportsDeleteById();

  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  // console.log("rendered", data?.results);
  React.useEffect(() => {
    if (search_term) {
      setQueryParams({
        search_term,
        page: (queryParams.get("page") as string) ?? 1,
        page_size: (queryParams.get("page_size") as string) ?? 20,
      });
    } else {
      setQueryParams({
        search_term: "",
        page: (queryParams.get("page") as string) ?? 1,
        page_size: (queryParams.get("page_size") as string) ?? 20,
      });
    }
  }, [search_term]);

  const renderTableHead = (data: any) => {
    if (data) {
      if (data?.data?.code === 404) {
        return <TableCell>No Data to display at the moment</TableCell>;
      }

      return params?.data?.results?.map((r: any, index: number) => (
        <TableCell key={index}>
          <Typography textTransform="capitalize">{r.label_name}</Typography>
        </TableCell>
      ));
    }
  };

  const renderTableBody = (data: any) => {
    if (data) {
      if (data?.data?.code === 404) {
        return;
        // <TableCell>No Data to display at the moment</TableCell>;
      }

      // console.log("data", data?.results);
      const fields = params.data?.results?.map((f) => f.label_name);
      const records = data?.results?.map((d: any) => d.records);
      // console.log("records", records);

      const rows = records?.map((r: any) => {
        let sorted: any = [];
        fields?.map((label_name) => {
          const field = r.filter((field: any) => field.label === label_name);
          sorted.push(field?.at(0));
        });
        return sorted;
      });

      return rows?.map((r: any, index: number) => (
        <TableRow key={index}>
          <TableCell>{index + 1}</TableCell>
          <TableCell>{data.results?.at(index)?.owner.email} </TableCell>
          {r?.map((r: any, i: number) => {
            if (r?.value_file) {
              const url = r?.value_file.replace(
                "https://apigcf.ccak.or.ke/uploads/uploads/",
                "https://apigcf.ccak.or.ke/api/uploads/uploads/"
              );

              return (
                <TableCell align="left">
                  {/* <UploadDownloadGuard
                    created_by_id={data?.results?.at(index)?.created_by}
                  > */}
                  <IconButton
                    LinkComponent={"a"}
                    download
                    href={url}
                    target="_blank"
                  >
                    <FileDownloadIcon />
                  </IconButton>
                  {/* </UploadDownloadGuard> */}
                </TableCell>
              );
            }
            return <TableCell key={i}>{r?.value}</TableCell>;
          })}
          <TableCell>
            <IconButton
              component={Link}
              to={`/delete/milestone-report/milestone report/${data?.results[index]?.id}`}
              state={{
                backgroundLocation: {
                  ...location,
                  search: `?page=${queryParams.get("page") ?? 1}&page_size=${
                    queryParams.get("page_size") ?? 20
                  }`,
                  state: {
                    id,
                  },
                },
              }}
            >
              <DeleteIcon sx={{ cursor: "pointer" }} />
            </IconButton>
          </TableCell>
        </TableRow>
      ));
    }
  };

  return (
    <Box sx={{ p: "2rem" }}>
      <Box sx={{ background: "white", borderRadius: ".5rem" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                display: "flex",
                gap: "5rem",
                px: "2rem",
                alignItems: "center",
              }}
            >
              <Typography
                component={"a"}
                sx={{
                  fontSize: "1.18rem",
                  fontWeight: 700,
                  color: Colors.primary.darkest,
                  cursor: "pointer",
                }}
                onClick={(e: any) => navigate("/milestones")}
              >
                <ArrowBackIcon />
              </Typography>
              <Typography
                component={"div"}
                sx={{
                  fontSize: "1.18rem",
                  fontWeight: 700,
                  color: Colors.primary.darkest,
                }}
              >
                Milestone Report
              </Typography>
              <TextField
                placeholder="Search"
                onChange={(e) => setSearchTerm(e.target.value)}
                value={queryParams.get("search_term")}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                display: "flex",
                gap: "5rem",
                px: "2rem",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                component={Link}
                to={`/milestones/${id}/report`}
                state={{ backgroundLocation: location }}
              >
                ADD REPORT
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <LoadingContainer loading={isLoading}>
        <TableContainer sx={{ background: "white" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Reported By</TableCell>
                {renderTableHead(data)}
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderTableBody(data)}</TableBody>
          </Table>
        </TableContainer>
      </LoadingContainer>
    </Box>
  );
};
