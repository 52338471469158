import {
  Autocomplete,
  StackProps,
  TextField,
  FormHelperText,
  Stack,
  TypographyProps,
  Typography,
} from "@mui/material";
import React from "react";
import { Controller, FieldErrorsImpl } from "react-hook-form";

let CustomController: any = Controller;

export const CustomAutoComplete = React.forwardRef(
  (
    {
      stackProps,
      label,
      labelProps,
      options = [],
      errors,
      control,
      name,
      multiple,
      defaultValue,
      disabled,
    }: {
      stackProps?: StackProps;
      labelProps: TypographyProps;
      label?: string;
      options: any[];
      errors: Partial<
        FieldErrorsImpl<{
          [x: string]: any;
        }>
      >;
      control: any;
      name: any;
      multiple?: boolean;
      defaultValue?: any;
      disabled?: boolean;
    },
    ref
  ) => {
    const error = errors[name]?.message;

    return (
      <Stack direction="column" spacing={4} {...stackProps}>
        {label ? (
          <Typography variant="h4" {...labelProps}>
            {label}{" "}
          </Typography>
        ) : undefined}
        <Controller
          render={({ field }) => (
            <Autocomplete
              className="form-item"
              options={options}
              onChange={(_, data) => field.onChange(data)}
              getOptionLabel={(option) => option.label}
              multiple={multiple}
              value={field.value ?? defaultValue}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={label}
                  placeholder={`Select ${label}`}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              )}
              disabled={disabled}
            />
          )}
          name={name}
          control={control}
        />
        <FormHelperText sx={{ color: "red" }}>{error as string}</FormHelperText>
      </Stack>
    );
  }
);
