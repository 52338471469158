import {
  CheckboxProps,
  FormControl,
  Stack,
  StackProps,
  Typography,
  TypographyProps,
  FormHelperText,
} from "@mui/material";
import React from "react";
import { Controller, FieldErrorsImpl } from "react-hook-form";
import Checkbox from "@mui/material/Checkbox";

export type MCheckboxProps = CheckboxProps & {
  labelProps?: TypographyProps;
  stackProps?: StackProps;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
  name: string;
  control?: any;
  label: string;
  defaultChecked?: boolean;
  disabled?: boolean;
};

export const CheckboxField = React.forwardRef(
  (
    {
      label,
      labelProps,
      stackProps,
      errors,
      defaultChecked,
      disabled,
      ...props
    }: MCheckboxProps,
    ref
  ) => {
    const error = errors[props.name]?.message;

    return (
      <Stack spacing={1} direction="row" alignItems="center" {...stackProps}>
        {label && (
          <Typography variant="subtitle2" {...labelProps}>
            {label}
          </Typography>
        )}
        <FormControl>
          <Controller
            render={({ field }) => (
              <Checkbox
                onChange={(e) => field.onChange(e.target.checked)}
                checked={
                  field.value !== undefined ? field.value : defaultChecked
                }
                disabled={disabled}
                defaultChecked={defaultChecked!}
              />
            )}
            name={props.name}
            control={props.control}
          />
          <FormHelperText sx={{ color: "red" }}>
            {error as string}
          </FormHelperText>
        </FormControl>
      </Stack>
    );
  }
);
