import React from "react";
import { useAuth } from "../auth.context";

export const IsAdminGuard: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const {
    state: { user },
  } = useAuth();

  // console.log("this code was ran", user);

  if (user?.organisation === "GIZ" && user?.user_type === "1") {
    return <>{children}</>;
  }

  return <>{undefined}</>;
};
