import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { KPIResponse } from "../../../lib/xhr/kpi/kpi";
import { EnhancedTableHead } from "./kpi-table-head";
import { Order } from "./kpi-table-types";
import { getComparator, stableSort } from "./kpi-table-utils";
import {
  Autocomplete,
  IconButton,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ActionMenu from "../../menus/activities-action-menu";
import { AchievedBadge } from "../../marker/marker";
import dayjs from "dayjs";
import { CustomAutoComplete } from "../../../lib/ui/forms/autoComplete";
import { CustomPagination } from "../../Pagination/pagination-component";

export default function KPIsTable(props: {
  rows: KPIResponse[];
  // page: n;
  // setPage: (page: number) => void;
  count: number;
  // rowsPerPage: number;
  pages: number;
  // setRowsPerPage: (total: number) => void;
}) {
  const { rows } = props;
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof KPIResponse>("id");
  const [selected, setSelected] = React.useState<readonly string[]>([]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof KPIResponse
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <CustomPagination
          advanced={false}
          count={props.count}
          // page={props.page}
          pages={props.pages}
          position="start"
          // rowsPerPage={props.rowsPerPage}
          // setPage={props.setPage}
          // setRowsPerPage={props.setRowsPerPage}
        />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.sort(getComparator(order, orderBy)).slice() */}
              {stableSort(rows as any, getComparator(order, orderBy)).map(
                (row: any, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        // padding="none"
                        align="left"
                      >
                        {row.id}
                      </TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">
                        <Stack>
                          {row.partner.map((p: any, index: number) => (
                            <small>
                              {p.code} {index !== row.partner.length - 1 && ","}
                            </small>
                          ))}
                        </Stack>
                      </TableCell>
                      <TableCell align="left">{row.outcome}</TableCell>
                      <TableCell align="left">{row.target}</TableCell>
                      <TableCell align="left">
                        <AchievedBadge
                          achieved={Number(row.achieved)}
                          dueDate={row.end_date as string}
                          target={Number(row.target)}
                        />
                      </TableCell>
                      <TableCell align="left">{row.maleachieved}</TableCell>
                      <TableCell align="left">{row.femaleachieved}</TableCell>
                      <TableCell align="left">
                        {dayjs(row.start_date).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell align="left">
                        {dayjs(row.end_date).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell align="left">
                        {row.verification_means}
                      </TableCell>
                      <TableCell align="left">
                        {row.gender_disaggregated ? (
                          <Typography
                            sx={{ color: "blue", fontSize: "inherit" }}
                          >
                            True
                          </Typography>
                        ) : (
                          <Typography
                            sx={{ color: "red", fontSize: "inherit" }}
                          >
                            False
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell align="left">{row.created_by}</TableCell>

                      <TableCell align="left">
                        <ActionMenu
                          id={row.id}
                          name={row.name as string}
                          route="kpis"
                        />
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomPagination
          advanced={true}
          count={props.count}
          // page={props.page}
          pages={props.pages}
          position="end"
          // rowsPerPage={props.rowsPerPage}
          // setPage={props.setPage}
          // setRowsPerPage={props.setRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
