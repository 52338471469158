import React from "react";
import { WebLocalAuthStorage } from "../storage/auth-storage";
import { AuthProvider } from "./auth.context";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { SnackbarProvider } from "notistack";

const queryClient = new QueryClient();

/**
 * local storage key
 */
const AUTH_STATE_LOCAL_STORAGE_KEY = "auth";
const authStorage = new WebLocalAuthStorage(AUTH_STATE_LOCAL_STORAGE_KEY);

export const Providers: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider>
        <AuthProvider storage={authStorage}>{children}</AuthProvider>
      </SnackbarProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
