import { AuthStorageInterface } from "../context/auth-storage-interface";
import { AuthState } from "../context/auth-types";

const defaultValue: AuthState = { isLoggedIn: false, loading: false, refresh: '', token: '' };
export class WebLocalAuthStorage implements AuthStorageInterface {
	constructor(private key: string) { }
	save(state: AuthState): void {
		localStorage.setItem(this.key, JSON.stringify(state));
	}
	get() {
		return JSON.parse(
			localStorage.getItem(this.key) ?? JSON.stringify(defaultValue)
		);
	}
}
