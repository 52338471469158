import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Colors } from "../../config/theme/theme";
import Box from "@mui/material/Box";

//TODO solve hover bug
export function InfoCard(props: any) {
  const [hovered, setHovered] = React.useState(false);

  return (
    <Card
      variant="outlined"
      onMouseOver={(e) => setHovered(!hovered)}
      onMouseLeave={(e) => setHovered(!hovered)}
      sx={{ borderRadius: ".5rem" }}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: 2,
          py: "1rem",
          cursor: "pointer",
          //   background: "red",
        }}
      >
        <Typography
          variant="h4"
          noWrap
          component="div"
          sx={{
            fontSize: "1.18rem",
            fontWeight: "bold",
            color: hovered ? Colors.info.main : Colors.primary.main,
          }}
        >
          {props.title}
        </Typography>
        <Typography
          variant="h1"
          noWrap
          component="div"
          sx={{
            fontSize: "2.5rem",
            fontWeight: "bold",
            color: hovered ? Colors.info.main : Colors.primary.darkest,
          }}
        >
          {props.value}
        </Typography>
      </CardContent>
    </Card>
  );
}

export function TrendsCard(props: { subscript: string; superscript: string }) {
  return (
    <Box
      component={"div"}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "0.625rem",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        flexGrow: 1,
        px: 5,
        textAlign: "center",
      }}
    >
      <Typography
        variant="h4"
        component="div"
        sx={{
          fontSize: "1rem",
          fontWeight: 600,
          color: Colors.primary.main,
        }}
      >
        {props.superscript}
      </Typography>
      <Typography
        variant="h1"
        component="div"
        sx={{
          fontSize: "1.5rem",
          fontWeight: 700,
          color: Colors.primary.darkest,
        }}
      >
        {props.subscript}
      </Typography>
    </Box>
  );
}
