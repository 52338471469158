import { Paper, Grid, Typography, Button, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { CustomAutoComplete } from "../../../lib/ui/forms/autoComplete";
import { StatusContainer } from "../../../lib/ui/forms/common/status-container";
import { CustomDatePicker } from "../../../lib/ui/forms/date-picker";
import { CustomSelect } from "../../../lib/ui/forms/select";
import { TextField } from "../../../lib/ui/forms/text-field";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import { Colors } from "../../../config/theme/theme";
import {
  ActivityResponse,
  useActivityQuery,
  useActivityQueryById,
} from "../../../lib/xhr/activity/activity";
import {
  ActionResponse,
  useActionQueryById,
  useUpdateActionMutation,
} from "../../../lib/xhr/action/action";
import { CustomModal } from "../../../components/modals/custom-modal";
import { KPIResponse, useKPIQuery } from "../../../lib/xhr/kpi/kpi";
import { CheckboxField } from "../../../lib/ui/forms/checkbox";

const schema = z
  .object({
    activity: z.object({ label: z.string(), value: z.number() }),
    name: z.string().min(1, "Required"),
    start_date: z.date(),
    end_date: z.date(),
    verification_means: z.string().min(1, "Required"),
    kpi: z.object({ label: z.string(), value: z.number() }),
    target: z.string().min(1, "Required"),
    gender_disaggregated: z.boolean(),
    outcome: z.string(),
  })
  .required({
    activity: true,
    name: true,
    start_date: true,
    end_date: true,
    verification_means: true,
    indicator: true,
    target: true,
    gender_disaggregated: true,
    outcome: true,
  });

export const EditActionPage = () => {
  const { id } = useParams();
  const actionQuery = useActionQueryById(id as string);
  const allActivitiesQuery = useActivityQuery();
  const activityQuery = useActivityQueryById(
    actionQuery.data?.results?.activity as number,
    actionQuery.isSuccess
  );
  const kpiQuery = useKPIQuery();

  // console.log(actionQuery.data?.results?.activity);

  return (
    <Paper sx={{ minHeight: "100vh" }}>
      <StatusContainer status={kpiQuery.status}>
        <Content
          action={actionQuery.data?.results!}
          activity={activityQuery.data?.results!}
          allActivities={allActivitiesQuery.data?.results!}
          kpis={kpiQuery.data?.results as any}
        />
      </StatusContainer>
    </Paper>
  );
};

const Content = ({
  action,
  activity,
  allActivities,
  kpis,
}: {
  action: ActionResponse;
  activity: ActivityResponse;
  allActivities: ActivityResponse[];
  kpis: KPIResponse[];
}) => {
  const kpiList =
    kpis?.map((kpi) => ({
      label: kpi.name,
      value: kpi.id,
    })) ?? [];

  const kpi = kpis
    ?.filter((k) => k.id === Number(action?.kpi))
    .map((kpi) => ({
      label: kpi.name,
      value: kpi.id,
    }));

  // console.log("kpi", kpi);
  const { mutateAsync: ActionMutAsync } = useUpdateActionMutation();
  const form = useForm({
    resolver: zodResolver(schema),
    mode: "onChange",
    defaultValues: {
      activity: activity?.name
        ? {
            label: activity?.name,
            value: activity?.id,
          }
        : undefined,
      name: action?.name,
      start_date: new Date(action?.start_date),
      end_date: new Date(action?.end_date),
      verification_means: action?.verification_means,
      indicator: kpi.at(0),
      target: action?.target,
      gender_disaggregated: action?.gender_disaggregated,
    },
  });
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const activityQuery = useActivityQuery();

  const submit = async (data: any) => {
    const payload = {
      ...data,
      start_date: dayjs(data.start_date).format("YYYY-MM-DD"),
      end_date: dayjs(data.end_date).format("YYYY-MM-DD"),
      id: activity.id,
      code: action.code,
      activity: data.activity.value,
    };

    // console.log(payload);

    await ActionMutAsync(payload, {
      onSuccess: (data) => {
        if (data.results.messages) {
          return enqueueSnackbar(data.results.messages, {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          });
        }

        enqueueSnackbar("Successfully Edited 1 Action", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
        activityQuery.refetch();
        return navigate("/activities");
      },
    });
  };
  // console.log(activity);
  return (
    <CustomModal>
      <Stack direction="row" justifyContent="center" p={3}>
        <Typography variant="h5"> Edit Action</Typography>
      </Stack>
      <Stack
        direction="column"
        component="form"
        spacing={2}
        onSubmit={form.handleSubmit(submit)}
      >
        <TextField
          label="Name"
          labelProps={{ variant: "h6" }}
          name="name"
          control={form.control}
          errors={form.formState.errors}
          defaultValue={action?.name}
        />
        <CustomAutoComplete
          name="activity"
          options={
            allActivities?.map((activity) => ({
              label: activity.name,
              value: activity.id,
            })) ?? []
          }
          labelProps={{ variant: "h6" }}
          label={"Activity"}
          control={form.control}
          multiple={false}
          errors={form.formState.errors}
          defaultValue={{
            label: activity?.name,
            value: activity?.id,
          }}
        />
        <CustomDatePicker
          name="start_date"
          label="Start date"
          labelProps={{ variant: "h6" }}
          control={form.control}
          errors={form.formState.errors}
          defaultValue={new Date(action?.start_date)}
        />
        <CustomDatePicker
          name="end_date"
          label="End date"
          labelProps={{ variant: "h6" }}
          control={form.control}
          errors={form.formState.errors}
          defaultValue={new Date(action?.end_date)}
        />
        <TextField
          label="Verification means"
          labelProps={{ variant: "h6" }}
          name="verification_means"
          control={form.control}
          errors={form.formState.errors}
          defaultValue={action?.verification_means}
        />
        <TextField
          label="Outcome"
          labelProps={{ variant: "h6" }}
          name="outcome"
          control={form.control}
          errors={form.formState.errors}
          defaultValue={action?.outcome}
        />
        <CustomAutoComplete
          name="kpi"
          options={kpiList ?? []}
          labelProps={{ variant: "h6" }}
          label="KPI"
          control={form.control}
          multiple={false}
          errors={form.formState.errors}
          defaultValue={kpi.at(0)}
        />
        <TextField
          label="Target"
          labelProps={{ variant: "h6" }}
          name="target"
          control={form.control}
          errors={form.formState.errors}
          defaultValue={action?.target}
        />
        <CheckboxField
          name="gender_disaggregated"
          label="Gender disaggregated"
          errors={form.formState.errors}
          control={form.control}
          defaultChecked={action?.gender_disaggregated}
        />
        <Stack direction="row" justifyContent="space-between">
          <Button
            onClick={(e) => navigate(-1)}
            variant="contained"
            sx={{ bgcolor: Colors.info.main }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            sx={{ bgcolor: Colors.info.main }}
          >
            Submit
          </Button>
        </Stack>
      </Stack>
    </CustomModal>
  );
};
