import { Button, Paper, Toolbar, Typography, Grid } from "@mui/material";
import { Stack, Box } from "@mui/material";
import { Colors } from "../../config/theme/theme";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { PasswordField, TextField } from "../../lib/ui/forms/text-field";
import { Link, useNavigate } from "react-router-dom";
import { useLoginMutation } from "../../lib/xhr/auth/auth";
import { useAuth } from "../../lib/context/auth.context";
import { useSnackbar } from "notistack";
import { CLoadingButton } from "../../components/buttons/button";
import React from "react";

const schema = z
  .object({
    email: z.string().email(),
    password: z.string().min(4),
  })
  .required({ email: true, password: true });

export default function LoginPage() {
  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
  } = useForm({
    resolver: zodResolver(schema),
  });

  const { mutate, isLoading } = useLoginMutation();
  const { login } = useAuth();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const submitHandler = (data: any) => {
    mutate(data, {
      onSuccess: (data: any) => {
        if (data.code === 200) {
          login(data.results.access, data.results.refresh);
          navigate("/dashboard");
          return;
        }

        console.log("data", data);
        if (data.code === 401) {
          return enqueueSnackbar("Invalid email or password", {
            variant: "error",
            anchorOrigin: { horizontal: "center", vertical: "top" },
          });
        }

        return enqueueSnackbar(data.message, {
          variant: "error",
          anchorOrigin: { horizontal: "center", vertical: "top" },
        });
      },
    });
  };

  return (
    <Paper
      sx={{
        background: Colors.primary.dark,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Grid container justifyContent={"center"}>
        <Grid item xs={12} md={4}>
          <Paper
            sx={{ p: 5, background: "white" }}
            component="form"
            onSubmit={handleSubmit(submitHandler)}
          >
            <Stack direction="column" alignItems={"center"} spacing={2}>
              <img src="/logo.svg" alt="logo" />
              <Typography color={`${Colors.primary.main}`}>
                GCF Monitoring Tool
              </Typography>

              <Typography
                color={`${Colors.secondary.dark}`}
                variant="h4"
                fontWeight="600"
              >
                Log In
              </Typography>
              <Typography>Enter your email and password below</Typography>
            </Stack>
            <Toolbar />
            <Stack spacing={2} direction="column">
              <TextField
                label="Email"
                errors={errors}
                name="email"
                control={control}
                placeholder="email@example.com"
              />
              <Stack>
                <Typography variant="subtitle2">Password</Typography>
                <PasswordField
                  // label="Password"
                  type="password"
                  errors={errors}
                  {...register("password")}
                  name="password"
                  control={control}
                />
              </Stack>
              {/* <Button variant="contained" type="submit">
                Login
              </Button> */}
              <CLoadingButton loading={isLoading} label="Login" />
            </Stack>
            <Stack direction={"column"} spacing={2} sx={{ my: 2 }}>
              <Typography component={Link} to="/auth/forgot-password">
                forgot password ?
              </Typography>
              <Typography>
                Don't have an account?
                <Typography component={Link} to="/auth/sign-up">
                  Sign up
                </Typography>
              </Typography>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
}
