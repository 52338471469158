import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../lib/context/auth.context";
import { useLogoutMutation } from "../../lib/xhr/auth/auth";

export function Logout() {
  const auth = useAuth();
  const logout = useLogoutMutation();
  const navigate = useNavigate();

  React.useEffect(() => {
    logout.mutate(
      { refresh: auth.state.refresh as string },
      {
        onSuccess: () => {
          auth.logout();
          navigate("/login");
        },
      }
    );
  }, []);

  return <></>;
}
