import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import * as React from "react";
import dayjs from "dayjs";
import Stack, { StackProps } from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Controller, FieldErrorsImpl } from "react-hook-form";
import Typography, { TypographyProps } from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import { addDays, addMonths } from "date-fns";
import { DateRange } from "react-date-range";
import { DateRangePicker } from "react-date-range";

export const CustomDatePicker = React.forwardRef(
  (
    {
      stackProps,
      label,
      labelProps,
      control,
      name,
      errors,
      defaultValue,
    }: {
      stackProps?: StackProps;
      labelProps: TypographyProps;
      label: string;
      control: any;
      name: any;
      errors: Partial<
        FieldErrorsImpl<{
          [x: string]: any;
        }>
      >;
      defaultValue?: any;
    },
    ref
  ) => {
    const error = errors[name]?.message;

    return (
      <Stack direction="column" spacing={4} {...stackProps}>
        {label ? (
          <Typography variant="h4" {...labelProps}>
            {label}{" "}
          </Typography>
        ) : undefined}
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={0}>
                <DesktopDatePicker
                  label={label}
                  value={field.value ?? defaultValue}
                  inputFormat="MM/DD/YYYY"
                  onChange={(date) => {
                    // console.log(date);
                    field.onChange(dayjs(date).toDate());
                  }}
                  renderInput={(params) => (
                    <TextField
                      // error={error ? true : false}
                      // helperText={error as string}
                      {...params}
                    />
                  )}
                />
                <FormHelperText sx={{ color: "red" }}>
                  {error as string}
                </FormHelperText>
              </Stack>
            </LocalizationProvider>
          )}
        />
        {/* <FormHelperText sx={{ color: "red" }}>{error as string}</FormHelperText> */}
      </Stack>
    );
  }
);

export const CustomRangePicker = ({
  name,
  control,
  errors,
  label,
}: // ranges,
{
  name: string;
  control: any;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
  label: string;
  // ranges: any[];
}) => {
  const [state, setState] = React.useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), +2),
      key: "selection",
      // showDateDisplay: false,
    },
  ]);

  const error = errors[name]?.message;

  return (
    <Stack direction="column" spacing={4}>
      {label && <Typography variant="h6">{label} </Typography>}
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <DateRange
            editableDateInputs={true}
            onChange={(item: any) => {
              setState([item.selection]);
              field.onChange([item.selection]);
            }}
            moveRangeOnFirstSelection={false}
            ranges={state}
            months={2}
            direction="horizontal"
            min
          />
        )}
      />
      <FormHelperText color="warning">{error as string}</FormHelperText>
    </Stack>
  );
};
