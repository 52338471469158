import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { useAddMilestoneParamsMutation } from "../../lib/xhr/milestones/milestone";
import { useSnackbar } from "notistack";
import DeleteIcon from "@mui/icons-material/Delete";

const schema = z.object({
  params: z.array(
    z.object({
      label_name: z.string(),
      milestone: z.string(),
      label_desc: z.string(),
      label_type: z.string(),
      label_order: z.string(),
      is_required: z.boolean(),
    })
  ),
});

export const AddMilestoneParams = () => {
  const mut = useAddMilestoneParamsMutation();
  const { id } = useParams();
  const form = useForm({
    resolver: zodResolver(schema),
    mode: "onChange",
  });
  const [rows, setRows] = React.useState<any>([{}]);
  const { enqueueSnackbar } = useSnackbar();

  const handleAddRow = () => {
    return setRows((prevRows) => [...prevRows, {}]);
  };

  const handleChange = (
    key: string,
    index: number,
    newValue: string | boolean
  ) => {
    const row = rows.at(index);
    if (row) {
      row[key] = newValue;
      rows[index] = row;

      return setRows((prevRows) => [...rows]);
    }
  };

  const handleDeleteROw = (index: number) => {
    return setRows((prevRows) => [...rows.filter((_, i) => i !== index)]);
  };

  const onSubmit = () => {
    form.setValue(
      "params",
      rows.map((r) => ({ ...r, milestone: id }))
    );

    const { params } = form.getValues();
    console.log("values", params);
    mut.mutate(params, {
      onSuccess: (data) => {
        enqueueSnackbar("Success", { variant: "success" });
        return;
      },
      onError: (err) => {
        enqueueSnackbar("Error", { variant: "error" });
        return;
      },
    });
  };

  return (
    <Stack p={3}>
      <FormProvider {...form}>
        <Stack spacing={2}>
          <Typography variant={"h5"}>Add Milestone Params</Typography>
          <Divider sx={{ my: 2 }} />
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align={"left"}>#</TableCell>
                  <TableCell align={"left"}>
                    <Stack>
                      <Typography>Label Name*</Typography>
                      <small>e.g 'producer_type'</small>
                    </Stack>
                  </TableCell>
                  <TableCell align={"left"}>
                    <Stack>
                      <Typography>Label Description*</Typography>
                      <small>e.g 'Producer Type'</small>
                    </Stack>
                  </TableCell>
                  <TableCell align={"left"}>Is Required*</TableCell>
                  <TableCell align={"left"}>Type*</TableCell>
                  <TableCell align={"left"}>Label Order*</TableCell>
                  <TableCell align={"left"}>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((arg, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <TextField
                        value={arg.label_name}
                        size="small"
                        onChange={(e) =>
                          handleChange("label_name", index, e.target.value)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={arg.label_desc}
                        size="small"
                        onChange={(e) =>
                          handleChange("label_desc", index, e.target.value)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={arg.is_required}
                        onChange={(e) =>
                          handleChange("is_required", index, e.target.checked)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Box sx={{ width: "100%", position: "relative" }}>
                        <Select
                          value={arg.label_type}
                          size="small"
                          displayEmpty
                          onChange={(e) =>
                            handleChange("label_type", index, e.target.value)
                          }
                          sx={{ minWidth: "100%" }}
                        >
                          <MenuItem disabled selected>
                            Select input type
                          </MenuItem>
                          <MenuItem value="text">TEXT</MenuItem>
                          <MenuItem value="number">NUMBER</MenuItem>
                          <MenuItem value="select">SELECT</MenuItem>
                          <MenuItem value="file">FILE</MenuItem>
                          <MenuItem value="date">DATE</MenuItem>
                        </Select>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={arg.label_order}
                        type="number"
                        size="small"
                        onChange={(e) =>
                          handleChange("label_order", index, e.target.value)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDeleteROw(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Stack
              sx={{
                justifyContent: "end",
                p: 3,
              }}
              direction="row"
              spacing={2}
            >
              <Button variant="contained" onClick={handleAddRow}>
                + row
              </Button>
              <Button variant="contained" onClick={onSubmit}>
                Submit
              </Button>
            </Stack>
          </TableContainer>
        </Stack>
      </FormProvider>
    </Stack>
  );
};
