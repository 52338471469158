import {
  Button,
  IconButton,
  Stack,
  TableContainer,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { MilestoneParamListing } from "./param-list";
import { AddMilestoneParams } from "./add-params";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {/* <Typography>{children}</Typography> */}
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Params List" {...a11yProps(0)} />
          <Tab label="Add Params" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <MilestoneParamListing />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AddMilestoneParams />
      </TabPanel>
    </Box>
  );
}

export const MilestoneConfiguration = () => {
  const navigate = useNavigate();
  return (
    <Stack p={5} sx={{ background: "white" }}>
      <Stack direction="row" spacing={2}>
        <Typography variant="h6">Milestone Configuration</Typography>
        <Button variant="contained" onClick={(e) => navigate(-1)}>
          Back
        </Button>
      </Stack>
      <Toolbar />
      <BasicTabs />
    </Stack>
  );
};
