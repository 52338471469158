import { Paper, Grid, Typography, Button, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { CustomAutoComplete } from "../../lib/ui/forms/autoComplete";
import { StatusContainer } from "../../lib/ui/forms/common/status-container";
import { CustomDatePicker } from "../../lib/ui/forms/date-picker";
import { TextField } from "../../lib/ui/forms/text-field";
import {
  PartnerResponse,
  usePartnerQuery,
} from "../../lib/xhr/partners/partners";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  KPIResponse,
  useKPIEditMutation,
  useKPIQuery,
  useKPIQueryById,
} from "../../lib/xhr/kpi/kpi";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { Colors } from "../../config/theme/theme";
import { CustomModal } from "../../components/modals/custom-modal";
import { CheckboxField } from "../../lib/ui/forms/checkbox";
import React from "react";
import { CLoadingButton } from "../../components/buttons/button";

const schema = z
  .object({
    partner: z.array(z.object({ label: z.string(), value: z.number() })),
    name: z.string().min(1, "Required"),
    start_date: z.date(),
    end_date: z.date(),
    verification_means: z.string().min(1, "Required"),
    outcome: z.string().min(1, "Required"),
    target: z.string().min(1, "Required"),
    gender_disaggregated: z.boolean(),
  })
  .superRefine(({ start_date, end_date }, ctx) => {
    const start = new Date(start_date);
    const end = new Date(end_date);

    if (start.getTime() > end.getTime()) {
      // console.log("it is");
      ctx.addIssue({
        code: "custom",
        message: "Start date must be less than end date",
        path: ["end_date"],
      });
    }
  });

export const EditKPIPage = () => {
  const { id } = useParams();
  const kpiQuery = useKPIQueryById(id as string);
  const partnerQuery = usePartnerQuery();

  return (
    <Paper sx={{ minHeight: "100vh" }}>
      <StatusContainer status={partnerQuery.status}>
        <Content
          kpi={kpiQuery.data?.results!}
          partners={partnerQuery.data?.results!}
        />
      </StatusContainer>
    </Paper>
  );
};

const Content = ({
  kpi,
  partners,
}: {
  kpi: KPIResponse;
  partners: PartnerResponse[];
}) => {
  const { refetch } = useKPIQuery();
  const partnerResult = partners
    ?.map((partner) => {
      if (kpi?.partner.find((item) => item.id === partner.id)) {
        return {
          label: partner.code,
          value: partner.id,
        };
      }
    })
    .filter((partner) => partner !== undefined);

  const { mutateAsync: kpiMutAsync, isLoading } = useKPIEditMutation();

  React.useEffect(() => {
    form.setValue("name", kpi?.name);
    form.setValue("start_date", new Date(kpi?.start_date));
    form.setValue("end_date", new Date(kpi?.end_date));
    form.setValue("verification_means", kpi?.verification_means);
    form.setValue("outcome", kpi?.outcome);
    form.setValue("partner", partnerResult);
    form.setValue("gender_disaggregated", kpi?.gender_disaggregated);
    form.setValue("target", kpi?.target);
  }, [kpi]);

  const form = useForm({
    resolver: zodResolver(schema),
    mode: "onChange",
  });
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  // console.log(form.formState.errors);

  const submit = async (data: any) => {
    const payload = {
      ...data,
      partner: data.partner.map((org: any) => org.value),
      start_date: dayjs(data.start_date).format("YYYY-MM-DD"),
      end_date: dayjs(data.end_date).format("YYYY-MM-DD"),
      id: kpi.id,
    };

    // console.log(payload);

    await kpiMutAsync(payload, {
      onSuccess: (data) => {
        if (data.results.messages) {
          return enqueueSnackbar(data.results.messages, {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          });
        }

        enqueueSnackbar("Successfully edited 1 KPI", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
        // refetch();
        return navigate(`/kpis?&page=1&page_size=10&name=${data.results.name}`);
      },
    });
  };

  return (
    <CustomModal>
      <Stack spacing={3} component="form" onSubmit={form.handleSubmit(submit)}>
        <Stack direction="row" justifyContent="center">
          <Typography variant="h5"> Edit KPI</Typography>
        </Stack>
        <Stack direction="column" spacing={2}>
          <CustomAutoComplete
            name="partner"
            options={
              partners?.map((partner) => ({
                label: partner.code,
                value: partner.id,
              })) ?? []
            }
            labelProps={{ variant: "h6" }}
            label={"Partner"}
            control={form.control}
            multiple={true}
            errors={form.formState.errors}
            defaultValue={partnerResult}
          />
          <TextField
            label="Name"
            labelProps={{ variant: "h6" }}
            name="name"
            control={form.control}
            errors={form.formState.errors}
            defaultValue={kpi?.name}
            // defaultValue={kpi?.name}
          />
          <CustomDatePicker
            name="start_date"
            label="Start date"
            labelProps={{ variant: "h6" }}
            control={form.control}
            errors={form.formState.errors}
            defaultValue={new Date(kpi?.start_date)}
          />
          <CustomDatePicker
            name="end_date"
            label="End date"
            labelProps={{ variant: "h6" }}
            control={form.control}
            errors={form.formState.errors}
            defaultValue={new Date(kpi?.end_date)}
          />
          <TextField
            label="Verification means"
            labelProps={{ variant: "h6" }}
            name="verification_means"
            control={form.control}
            errors={form.formState.errors}
            defaultValue={kpi?.verification_means}
          />
          <TextField
            label="Outcome"
            labelProps={{ variant: "h6" }}
            name="outcome"
            control={form.control}
            errors={form.formState.errors}
            defaultValue={kpi?.outcome}
            // type="number"
          />
          <TextField
            label="Target"
            labelProps={{ variant: "h6" }}
            {...form.register("target")}
            name="target"
            control={form.control}
            errors={form.formState.errors}
            defaultValue={kpi?.target}
          />
          <CheckboxField
            name="gender_disaggregated"
            label="Gender disaggregated"
            errors={form.formState.errors}
            control={form.control}
            defaultChecked={kpi?.gender_disaggregated}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Button
            onClick={(e) => {
              navigate(-1);
            }}
            variant="contained"
            sx={{ bgcolor: Colors.info.main }}
          >
            Cancel
          </Button>

          <CLoadingButton loading={isLoading} />
        </Stack>
      </Stack>
    </CustomModal>
  );
};
