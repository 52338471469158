import { VisibilityOff, Visibility } from "@mui/icons-material";
import {
  TextFieldProps as MTextFieldProps,
  TextField as MTextField,
  Stack,
  Typography,
  TypographyProps,
  StackProps,
  FormHelperText,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import React from "react";
import { Controller, FieldErrorsImpl } from "react-hook-form";
import { matchIsValidTel, MuiTelInput } from "mui-tel-input";

interface PhoneInputProps {
  name: string;
  control: any;
  countries: string[];
  defaultCountry: string;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
  labelProps?: TypographyProps;
  label: string;
  disabled?: boolean;
}

export type TextFieldProps = MTextFieldProps & {
  labelProps?: TypographyProps;
  stackProps?: StackProps;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
  name: string;
  control?: any;
  disabled?: boolean;
  t?: string;
  placeholder?: string;
};

export const TextField = React.forwardRef(
  (
    {
      label,
      labelProps,
      stackProps,
      errors,
      disabled,
      t,
      placeholder,
      ...props
    }: TextFieldProps,
    ref
  ) => {
    const error = errors[props.name]?.message;

    return (
      <Stack
        spacing={1}
        direction="column"
        alignItems="stretch"
        {...stackProps}
      >
        {label && (
          <Typography variant="subtitle2" {...labelProps}>
            {label}
          </Typography>
        )}
        <FormControl>
          <Controller
            render={({ field }) => (
              <MTextField
                inputRef={ref}
                FormHelperTextProps={{
                  sx: { mx: 0 },
                }}
                onChange={(e) => {
                  field.onChange(e.target.value);
                }}
                value={field.value ?? props.defaultValue}
                type={props.type}
                disabled={disabled}
                placeholder={placeholder}
              />
            )}
            name={props.name}
            control={props.control}
          />
          <FormHelperText sx={{ color: "red" }}>
            {error as string}
          </FormHelperText>
        </FormControl>
      </Stack>
    );
  }
);

export const NumField = React.forwardRef(
  (
    {
      label,
      labelProps,
      stackProps,
      errors,
      disabled,
      ...props
    }: TextFieldProps,
    ref
  ) => {
    const error = errors[props.name]?.message;

    return (
      <Stack
        spacing={1}
        direction="column"
        alignItems="stretch"
        {...stackProps}
      >
        {label && (
          <Typography variant="subtitle2" {...labelProps}>
            {label}
          </Typography>
        )}
        <FormControl>
          <Controller
            render={({ field }) => (
              <MTextField
                inputRef={ref}
                FormHelperTextProps={{
                  sx: { mx: 0 },
                }}
                onChange={(e) => {
                  field.onChange(parseInt(e.target.value));
                }}
                value={field.value ?? props.defaultValue}
                type={props.type}
                disabled={disabled}
              />
            )}
            name={props.name}
            control={props.control}
          />
          <FormHelperText sx={{ color: "red" }}>
            {error as string}
          </FormHelperText>
        </FormControl>
      </Stack>
    );
  }
);
export const CTextField = ({ label, ...props }: { label: string }) => {
  return (
    <Stack>
      <Typography>{label}</Typography>
      <MTextField
        FormHelperTextProps={{
          sx: { mx: 0 },
        }}
        {...props}
      />
    </Stack>
  );
};

export const FileField = React.forwardRef(
  (
    { label, labelProps, stackProps, errors, ...props }: TextFieldProps,
    ref
  ) => {
    const error = (errors[props.name] as any)?.message;

    return (
      <Stack
        spacing={1}
        direction="column"
        alignItems="stretch"
        {...stackProps}
      >
        {label && (
          <Typography variant="subtitle2" {...labelProps}>
            {label}
          </Typography>
        )}
        <FormControl>
          <MTextField
            inputRef={ref}
            FormHelperTextProps={{
              sx: { mx: 0 },
            }}
            {...props}
          />
          <FormHelperText sx={{ color: "red" }}>
            {error as string}
          </FormHelperText>
        </FormControl>
      </Stack>
    );
  }
);

export const MFileField = React.forwardRef(
  (
    {
      label,
      labelProps,
      stackProps,
      errors,
      control,
      ...props
    }: TextFieldProps,
    ref
  ) => {
    const error = (errors[props.name] as any)?.message;

    return (
      <Stack
        spacing={1}
        direction="column"
        alignItems="stretch"
        {...stackProps}
      >
        {label && (
          <Typography variant="subtitle2" {...labelProps}>
            {label}
          </Typography>
        )}
        <FormControl>
          <Controller
            render={({ field }) => (
              <MTextField
                type="file"
                // value={field.value ?? props.defaultValue}
                inputRef={ref}
                FormHelperTextProps={{
                  sx: { mx: 0 },
                }}
                onChange={({ target: { files } }: any) => {
                  const file: File = files[0];
                  // console.log(typeof file);
                  files &&
                    !!files.length &&
                    field.onChange("attachment", files[0] as File);
                }}
                // onChange={(e) =>
                //   field.onChange(
                //     t === "number" ? Number(e.target.value) : e.target.value
                //   )
                // }
              />
            )}
            control={control}
            name={props.name}
          />
          <FormHelperText sx={{ color: "red" }}>
            {error as string}
          </FormHelperText>
        </FormControl>
      </Stack>
    );
  }
);

export const PasswordField = ({
  label,
  control,
  name,
  errors,
  ...props
}: TextFieldProps) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const error = errors[name]?.message;

  return (
    <FormControl sx={{ width: "100%" }} variant="outlined">
      <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
      <Controller
        render={({ field }) => (
          <OutlinedInput
            type={showPassword ? "text" : "password"}
            placeholder="password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label={label}
            onChange={(e) => field.onChange(e.target.value)}
          />
        )}
        name={name}
        control={control}
      />
      <FormHelperText sx={{ color: "red" }}>{error as string}</FormHelperText>
    </FormControl>
  );
};

export function customPhoneValidation(value: string) {
  // Your custom validation logic goes here
  // For example, let's say you want to validate that the phone number is 10 digits long
  return matchIsValidTel(value);
}

export const PhoneNumberInput = ({
  label,
  name,
  countries,
  defaultCountry,
  labelProps,
  errors,
  control,
  disabled,
}: PhoneInputProps) => {
  const error = errors?.[name]?.message;

  return (
    <Stack>
      {label && (
        <Typography variant="subtitle2" {...labelProps}>
          {label}
        </Typography>
      )}
      <FormControl>
        <Controller
          render={({ field }) => (
            <MuiTelInput
              defaultCountry={defaultCountry as any}
              onlyCountries={countries as any}
              inputRef={field.ref}
              disabled={disabled}
              value={field.value}
              // {...rest}
              onChange={(e) => field.onChange(e.replace(/ /g, ""))}
            />
          )}
          {...{ name, control }}
        />
        <FormHelperText sx={{ color: "red", pl: 0, ml: 0 }}>
          {error as string}
        </FormHelperText>
      </FormControl>
    </Stack>
  );
};
