import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import { useAuth } from "../auth.context";

export const LogoutTimer: React.FC<React.PropsWithChildren<any>> = ({
  children,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [seconds, setSeconds] = useState(10);
  let inactiveTimeoutRef = useRef<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Set up the inactive timeout timer
    const resetTimer = () => {
      if (inactiveTimeoutRef) {
        clearTimeout(inactiveTimeoutRef.current);
      }
      inactiveTimeoutRef.current = setTimeout(() => {
        setDialogOpen(true);
      }, 1200000); // Prompt for reset after 1 minute of inactivity
    };

    const clearTimer = () => {
      if (inactiveTimeoutRef) {
        clearTimeout(inactiveTimeoutRef.current);
      }
    };

    resetTimer();

    // Add event listeners to reset the timer on user activity
    document.addEventListener("mousemove", resetTimer);
    document.addEventListener("keydown", resetTimer);

    // Remove event listeners on component unmount
    return () => {
      document.removeEventListener("mousemove", resetTimer);
      document.removeEventListener("keydown", resetTimer);
      clearTimer();
    };
  }, []);

  useEffect(() => {
    if (dialogOpen) {
      //   Decrement the timer every second
      const intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
      // Clear the interval and redirect to the login page on logout
      if (seconds === 0) {
        clearInterval(intervalId);
        navigate("/auth/logout");
      }

      // Clear the interval on component unmount
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [seconds, dialogOpen]);

  const resetTimeout = () => {
    if (inactiveTimeoutRef) {
      clearTimeout(inactiveTimeoutRef.current);
    }

    inactiveTimeoutRef.current = setTimeout(() => {
      setDialogOpen(true);
    }, 1200000); // Prompt for reset after 1 minute of inactivity
    setSeconds(10);
    setDialogOpen(false); // Close the dialog after resetting the timer
  };

  const handleResetTimer = () => {
    resetTimeout();
  };

  return (
    <>
      <Dialog open={dialogOpen}>
        <DialogTitle>Inactivity Warning</DialogTitle>
        <DialogContent>
          <Typography>
            Your session will expire in <strong>{seconds}</strong> seconds due
            to inactivity. Do you want to reset the session?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => navigate("/auth/logout")}>Log out</Button>
          <Button onClick={handleResetTimer}>Keep me logged in.</Button>
        </DialogActions>
      </Dialog>
      {children}
    </>
  );
};
