import { Button, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { CustomAutoComplete } from "../../lib/ui/forms/autoComplete";
import { StatusContainer } from "../../lib/ui/forms/common/status-container";
import { CustomRangePicker } from "../../lib/ui/forms/date-picker";
import { FileField, NumField, TextField } from "../../lib/ui/forms/text-field";
import { usePartnerQuery } from "../../lib/xhr/partners/partners";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../config/theme/theme";
import {
  useReportMutation,
  useReportQuery,
} from "../../lib/xhr/reports/report";
import { z } from "zod";
import dayjs from "dayjs";
import { useKPIQuery } from "../../lib/xhr/kpi/kpi";
import { CheckboxField } from "../../lib/ui/forms/checkbox";
import React from "react";
import { useAuth } from "../../lib/context/auth.context";
import addDays from "date-fns/addDays";
import { TextField as MTextField } from "@mui/material";
import { CLoadingButton } from "../../components/buttons/button";

const schema = z
  .object({
    kpi: z.object({ label: z.string(), value: z.number() }),
    organisation: z.number(),
    name: z.any(z.string().min(1, "Required")),
    report_period: z.array(
      z.object({
        startDate: z.date(),
        endDate: z.date(),
        key: z.string(),
        // color: z.string(),
      })
    ),
    verification_means: z.any(z.string().min(1, "Required")),
    outcome: z.any(z.string()),
    target: z.any(z.string().min(1, "Required")),
    gender_disaggregated: z.any(z.boolean()),
    attachment: z
      .any()
      .refine((files) => files?.length === 1, "File is required."),
    achieved: z.number(),
    maleachieved: z.number(),
    femaleachieved: z.number(),
  })
  .required({
    organisation: true,
    name: true,
    report_period: true,
    verification_means: undefined,
    outcome: true,
    target: true,
    gender_disaggregated: true,
    attachment: true,
    achieved: true,
    maleachieved: true,
    femaleachieved: true,
  });

type ISchema = z.infer<typeof schema>;

export const KPIReportForm: React.FC<any> = () => {
  const auth = useAuth();
  const partnerQuery = usePartnerQuery();
  const { mutateAsync: reportMutAsync, isLoading } = useReportMutation();
  const kpiQuery = useKPIQuery();
  const kpiList =
    kpiQuery?.data?.results?.map((kpi) => ({
      label: kpi.name,
      value: kpi.id,
    })) ?? [];

  const [aggregate, setAggregate] = React.useState(false);
  const { refetch } = useReportQuery();

  const form = useForm<ISchema>({
    resolver: zodResolver(schema),
    mode: "onChange",
  });
  const [disaggregated, kpi] = form.watch(["gender_disaggregated", "kpi"]);

  React.useEffect(() => {
    //filter activityid
    const a = kpiQuery.data?.results?.filter((a) => a.id === kpi?.value);

    // construct default values for the form
    const partnerResult = partnerQuery.data?.results?.filter(
      (partner) => partner.code === auth.state.user?.organisation
    );

    const name = a?.at(0)?.name!;
    const organisation: any = partnerResult?.at(0)?.id!;
    const gender_disaggregated = a?.at(0)?.gender_disaggregated!;
    const outcome = a?.at(0)?.outcome!;
    const target: any = a?.at(0)?.target!;
    const verification_means = a?.at(0)?.verification_means!;

    form.setValue("name", name);
    form.setValue("target", target);
    form.setValue("outcome", outcome);
    form.setValue("target", target);
    form.setValue("verification_means", verification_means);
    form.setValue("gender_disaggregated", gender_disaggregated);
    form.setValue("organisation", organisation);
    form.setValue("report_period", [
      {
        startDate: new Date(),
        endDate: addDays(new Date(), +2),
        key: "selection",
        // color: "blue",
      },
    ]);

    // console.log(gender_disaggregated);
    setAggregate(true);
  }, [kpi]);

  React.useEffect(() => {
    if (aggregate) {
      // console.log("its changed", disaggregated);
      if (disaggregated) {
        form.setValue("achieved", 0 as any);
      } else {
        form.setValue("maleachieved", 0 as any);
        form.setValue("femaleachieved", 0 as any);
      }
    }
  }, [disaggregated]);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  // console.log("errors", form.formState.errors);

  const submit = async (data: ISchema) => {
    const payload: any = {
      organisation: data.organisation,
      report_period_to: dayjs(data.report_period.at(0)?.endDate).format(
        "YYYY-MM-DD"
      ),
      report_period_from: dayjs(data.report_period.at(0)?.startDate).format(
        "YYYY-MM-DD"
      ),
      attachment: data.attachment[0],
      outcome: data.outcome,
      level: 4,
      level_id: data.kpi.value,
      target: data.target,
      verification_means: data.verification_means,
      gender_disaggregated: data.gender_disaggregated,
      achieved: data.achieved,
      maleachieved: data.maleachieved,
      femaleachieved: data.femaleachieved,
      name: data.name,
    };

    // console.log(payload);
    const res = await reportMutAsync(payload);

    if (res.results.messages) {
      return enqueueSnackbar(res.results.messages, {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }

    enqueueSnackbar("Successfully added 1 Report", {
      variant: "success",
      anchorOrigin: { vertical: "top", horizontal: "center" },
    });
    refetch();
    return navigate(`/reports?page=1&page_size=10&name=${res.results.name}`);
  };

  return (
    <Stack spacing={3} component="form" onSubmit={form.handleSubmit(submit)}>
      <Stack direction="column" spacing={2}>
        <StatusContainer status={kpiQuery.status}>
          <CustomAutoComplete
            name="kpi"
            options={
              kpiQuery.data?.results?.map((kpi) => ({
                label: kpi.name,
                value: kpi.id,
              })) ?? []
            }
            labelProps={{ variant: "h6" }}
            label={"KPI"}
            control={form.control}
            multiple={false}
            errors={form.formState.errors}
          />
        </StatusContainer>
        <TextField
          label="Name"
          labelProps={{ variant: "h6" }}
          {...form.register("name")}
          name="name"
          control={form.control}
          errors={form.formState.errors}
          disabled={true}
        />

        <TextField
          label="Outcome"
          labelProps={{ variant: "h6" }}
          name="outcome"
          control={form.control}
          errors={form.formState.errors}
          disabled={true}
        />
        <TextField
          label="Target"
          labelProps={{ variant: "h6" }}
          errors={form.formState.errors}
          name="target"
          control={form.control}
          disabled={true}
        />
        <TextField
          label="Verification means"
          labelProps={{ variant: "h6" }}
          name="verification_means"
          control={form.control}
          errors={form.formState.errors}
        />
        <CustomRangePicker
          name="report_period"
          control={form.control}
          errors={form.formState.errors}
          label="Reporting Period"
        />
        <CheckboxField
          label="Gender Disaggregated"
          control={form.control}
          errors={form.formState.errors}
          {...form.register("gender_disaggregated")}
          defaultChecked={
            form.getValues("gender_disaggregated") === undefined
              ? false
              : form.getValues("gender_disaggregated")
          }
          disabled={true}
        />
        {disaggregated ? (
          <Stack direction="row" spacing={2}>
            <NumField
              label="Male"
              labelProps={{ variant: "h6" }}
              errors={form.formState.errors}
              name="maleachieved"
              control={form.control}
              type="number"
            />
            <NumField
              label="Female"
              labelProps={{ variant: "h6" }}
              errors={form.formState.errors}
              name="femaleachieved"
              control={form.control}
              type="number"
            />
          </Stack>
        ) : (
          <NumField
            label="Achieved"
            labelProps={{ variant: "h6" }}
            errors={form.formState.errors}
            name="achieved"
            control={form.control}
            type="number"
          />
        )}
        <FileField
          labelProps={{ variant: "h6" }}
          errors={form.formState.errors}
          type="file"
          label="Attachment"
          {...form.register("attachment")}
        />
      </Stack>
      <CLoadingButton loading={isLoading} />
    </Stack>
  );
};
