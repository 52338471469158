import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { SERVER_URL } from "../../../config/environments/env.config"
import { client, ResultArray, ResultObject } from "../common/common";


interface Response {
	id: number,//1,
	code: string, //"GIZ",
	description: string //"Deutsche Gesellschaft für Internationale Zusammenarbeit"
}

export type PartnerResponse = Response;


export const usePartnerQuery = () =>
	useQuery<ResultArray<Response>>({
		queryKey: ["getOrganizations"],
		queryFn: () =>
			client.get(`/api/organisations/`)
				.then(res => res.data)
				.catch(err => err)
	})