import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { SERVER_URL } from "../../../config/environments/env.config";
import { client, jsonToFormData, ResultArray, ResultObject, ResultPaginatedArray } from "../common/common";
import { handleResFormat } from "../common/common.util";
import { TaskResponse } from "../task/task";

interface Request {
  name: string; //"Professionalization of ICS production",
  start_date: string; //"2021-10-25",
  end_date: string; //"2024-10-25",
  indicator: number; //"1",
  target: string; //"of professionalised producers",
  verification_means: string; //"test",
  gender_disaggregated: boolean; //"male"
  activity: number; //
}

interface Response {
  id: number,//1,
  activity: number,//1,
  code: string,//"1.1.1.1",
  name: string,//"test",
  start_date: string,//"2021-10-25T00:00:00Z",
  end_date: string,//"2024-10-25T00:00:00Z",
  kpi: any,
  outcome: string,//"1",
  target: string,//"test",
  verification_means: string,//"test",
  gender_disaggregated: boolean,//"male",
  created_by: number,//1,
  creation_date: string,//"2023-01-01T09:04:24.438502Z",
  last_updated: string,//"2023-01-01T09:04:24.438520Z",
  tasks: TaskResponse[]
  achieved: string
}

interface UploadReq {
  file: string
}

export type ActionResponse = Response;

export const useActionMutation = () =>
  useMutation<ResultObject<Response>, string, Request>({
    mutationKey: ["addAction"],
    mutationFn: (body) =>
      client
        .post(`/api/actions/`, body, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then(handleResFormat)
        .catch((err) => err),
  });


export const useActionQuery = (enabled?: boolean) =>
  useQuery<ResultArray<Response>>({
    queryKey: ["actionQuery"],
    queryFn: () =>
      client.get("/api/actions/")
        .then(res => res.data)
        .catch(err => err)
    ,
    enabled
  })

export const useActionQueryById = (id: string | number, enabled?: boolean) =>
  useQuery<ResultObject<Response>>({
    queryKey: ["actionQueryById"],
    queryFn: () =>
      client.get("/api/actions/" + id)
        .then(res => handleResFormat(res))
        .catch(err => err),
    enabled
  })


export const useUpdateActionMutation = () =>
  useMutation<ResultObject<Response>, string, Request & { id: number }>({
    mutationKey: ['updateAction'],
    mutationFn: (body) =>
      client.put(`/api/actions/` + body.id, body)
        .then(handleResFormat)
        .catch(err => err)
  })


export const useDeleteActionMutation = () =>
  useMutation({
    mutationFn: (id: string) =>
      client.delete('/api/actions/' + id)
        .then(handleResFormat)
        .catch(err => err)
  })

export const useActionUploadMutation = () =>
  useMutation<ResultPaginatedArray<Response>, string, UploadReq>({
    mutationFn: (body) =>
      client.post('/api/uploadexcel/actions/', jsonToFormData(body))
        .then(handleResFormat)
        .catch(err => err)
  })