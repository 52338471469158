import * as React from "react";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { CustomSelect } from "../../../lib/ui/forms/select";
import { CustomModal } from "../../../components/modals/custom-modal";
import { AddActivityForm } from "./add-activity-form";

export default function AddActivityPage() {
  const form = useForm();
  const level = form.watch("level");

  const RenderForm = () => {
    // if (level === "two") {
    //   return <AddActionForm />;
    // }

    // if (level === "three") {
    //   return <AddTaskForm />;
    // }

    return <AddActivityForm />;
  };

  return (
    <CustomModal height={"80vh"}>
      <Stack direction="row" justifyContent="center" p={1}>
        <Typography variant="h5"> New Activity</Typography>
      </Stack>
      {/* <Stack direction="column" spacing={2} component={"form"}>
        <CustomSelect
          values={[
            { label: "Activity", value: "one" },
            // { label: "Two", value: "two" },
            // { label: "Three", value: "three" },
          ]}
          stackProps={{
            direction: "column",
            spacing: 2,
            alignItems: "start",
          }}
          label="Level"
          labelProps={{ variant: "h6" }}
          inputWidth={"100%"}
          errors={form.formState.errors}
          {...form.register("level")}
        />
      </Stack> */}
      {/* <RenderForm /> */}
      <AddActivityForm />
    </CustomModal>
  );
}
