import { useMutation, useQuery } from "@tanstack/react-query";
import { identity } from "lodash";
import { client, ResultObject, ResultPaginatedArray } from "../common/common";
import { handleResFormat } from "../common/common.util";

interface AddUserToGroupRequest {
  userid: string;
  group: string;
}

interface RemoveUserToGroupRequest {
  userid: string;
  group: string;
}

interface UserParams {
  partner?: string,
  page?: number | string,
  page_size?: number | string,
  search_term?: string
}


export interface UserResponse {
  id: number; //1,
  email: string; //"admin@admin.com",
  name: string; //"admin",
  user_type: string; //"1",
  organisation: string; //1
  permission_count: number,
  groups: any[],
  is_admin: boolean,//false,
  is_active: boolean,//true,
  permissions: string[]
}

interface UserPermissionRequest {
  userid: string;
  permissions: number[];
}

interface UpdateUserRequest {
  name: string, email: string,
  user_type: string,
  organisation: string,
  id: string
}

export const useUserQuery = (params?: UserParams) =>
  useQuery<ResultPaginatedArray<UserResponse>>({
    queryKey: ["getUsers", { ...params }],
    queryFn: () =>
      client
        .get("/api/users/", { params })
        .then(handleResFormat)
        .catch((err) => err),
  });

export const useUserQueryById = (id: string) =>
  useQuery<ResultObject<UserResponse>, string>({
    queryKey: ["getUserById"],
    queryFn: () =>
      client
        .get("/api/users/" + id)
        .then(handleResFormat)
        .catch((err) => err),
  });

export const useActivateUserIdMutation = () =>
  useMutation<any, string, { id: number | string }>({
    mutationKey: ["/api/activateUserId"],
    mutationFn: (body) =>
      client
        .post("/api/users/activate/", body)
        .then(handleResFormat)
        .catch((err) => err),
  });

export const useDeactivateUserIdMutation = () =>
  useMutation<any, string, { id: number | string }>({
    mutationKey: ["deactivateUserId"],
    mutationFn: (body) =>
      client
        .post("/api/users/deactivate/", body)
        .then(handleResFormat)
        .catch((err) => err),
  });

export const useUserAddToGroupMutation = () =>
  useMutation<ResultObject<string>, string, AddUserToGroupRequest>({
    mutationKey: ["addUserToGroup"],
    mutationFn: ({ userid, ...group }) =>
      client
        .put(`/api/users/adduser/${userid}/`, group)
        .then(handleResFormat)
        .catch((err) => err),
  });

export const useUserRemoveFromGroup = () =>
  useMutation<ResultObject<string>, string, AddUserToGroupRequest>({
    mutationKey: ["removeUserFromGroup"],
    mutationFn: ({ userid, ...group }) =>
      client
        .put(`/api/users/removeuser/${userid}/`, group)
        .then(handleResFormat)
        .catch((err) => err),
  });

export const useUserRemovePermission = () =>
  useMutation<ResultObject<string>, string, UserPermissionRequest>({
    mutationKey: ["removeUserFromGroup"],
    mutationFn: (body) =>
      client
        .post("/api/groups/removeuserpermission/", body)
        .then(handleResFormat)
        .catch((err) => err),
  });

export const useUserAddPermission = () =>
  useMutation<ResultObject<string>, string, UserPermissionRequest>({
    mutationKey: ["addUserPermission"],
    mutationFn: (body) =>
      client
        .post("/api/groups/adduserpermission/", body)
        .then(handleResFormat)
        .catch((err) => err),
  });

export const useChangePasswordMutation = () =>
  useMutation({
    mutationKey: ["changePassword"],
    mutationFn: (body: any) =>
      client
        .post("/api/" + body.id, body)
        .then(handleResFormat)
        .catch((err) => err),
  });


export const useUserUpdateMutation = () =>
  useMutation<ResultObject<UserResponse>, string, UpdateUserRequest>({
    mutationKey: ["update user"],
    mutationFn: ({ id, ...body }) =>
      client
        .put("/api/users/" + id, body)
        .then(handleResFormat)
        .catch((err) => err),
  });

export const useUserDeleteMutation = () =>
  useMutation<ResultObject<any>, string, { id: string }>({
    mutationKey: ["delete user"],
    mutationFn: ({ id }) =>
      client
        .delete("/api/users/" + id)
        .then(handleResFormat)
        .catch((err) => err),
  });

