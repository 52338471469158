import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Colors } from "../../../config/theme/theme";
import { TaskResponse } from "../../../lib/xhr/task/task";
import dayjs from "dayjs";
import { Typography } from "@mui/material";
import { AchievedBadge } from "../../marker/marker";

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number
) {
  return { name, calories, fat, carbs, protein };
}

// const rows = [
//   createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
//   createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
//   createData("Eclair", 262, 16.0, 24, 6.0),
//   createData("Cupcake", 305, 3.7, 67, 4.3),
//   createData("Gingerbread", 356, 16.0, 49, 3.9),
// ];

export default function BasicTable(props: { rows: TaskResponse[] }) {
  return (
    <TableContainer sx={{ background: "white" }}>
      <Table
        sx={{
          minWidth: "100%",
          background: "white",
          border: "none",
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow sx={{ background: Colors.primary.dark }}>
            <TableCell
              align="left"
              sx={{
                color: "white",
                "&:hover": { color: "red! important" },
                "&:focus": { color: "red! important" },
              }}
            >
              Name
            </TableCell>
            <TableCell
              align="left"
              sx={{
                color: "white",
                "&:hover": { color: "red! important" },
                "&:focus": { color: "red! important" },
              }}
            >
              Target
            </TableCell>
            <TableCell
              align="left"
              sx={{
                color: "white",
                "&:hover": { color: "red! important" },
                "&:focus": { color: "red! important" },
              }}
            >
              Achieved
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows?.map((row) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left" component="th" scope="row">
                {row.name}
              </TableCell>
              {/* <TableCell align="left">
                {dayjs(row.start_date).format("YYYY-MM-DD")}
              </TableCell>
              <TableCell align="left">
                {dayjs(row.end_date).format("YYYY-MM-DD")}
              </TableCell>
              <TableCell align="left">{row.outcome}</TableCell> */}
              <TableCell align="left">{row.target}</TableCell>
              <TableCell align="left">
                <AchievedBadge
                  achieved={Number(row.achieved)}
                  dueDate={row.end_date as string}
                  target={Number(row.target)}
                />
              </TableCell>
              {/* <TableCell align="left">{row.verification_means}</TableCell>
              <TableCell align="left">
                {row.gender_disaggregated ? (
                  <Typography sx={{ color: "blue", fontSize: "inherit" }}>
                    True
                  </Typography>
                ) : (
                  <Typography sx={{ color: "red", fontSize: "inherit" }}>
                    False
                  </Typography>
                )}
              </TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
