import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";
import { useSearchParams } from "react-router-dom";
import UserTable from "../../components/tables/users/user";
import { Colors } from "../../config/theme/theme";
import { StatusContainer } from "../../lib/ui/forms/common/status-container";
import { useUserQuery } from "../../lib/xhr/users/users";

export const UserManagementPage: React.FC<React.PropsWithChildren<any>> = (
  props
) => {
  const [queryParams, setQueryParams] = useSearchParams();
  const block = React.useRef(false);
  const users = useUserQuery({
    page: queryParams.get("page") ?? 1,
    page_size: queryParams.get("page_size") ?? 10,
    search_term: (queryParams.get("search_term") as string) ?? "",
  });
  // console.log(users.data?.results);

  const p = {
    name: queryParams.get("name") ?? "",
    partner: queryParams.get("partner") ?? "",
    page: queryParams.get("page") ?? "",
    page_size: queryParams.get("page_size") ?? "",
  };

  React.useEffect(() => {
    const initializePage = () => {
      setQueryParams({
        search_term: queryParams.get("search_term") ?? "",
        page: queryParams.get("page") ?? "1",
        // partner: queryParams.get("partner") ?? "",
        page_size: queryParams.get("page_size") ?? "10",
      });
    };

    if (block.current === false) {
      block.current = true;
      initializePage();
      // console.log("loaded");
    }
  }, []);

  return (
    <Box sx={{ p: "2rem" }}>
      <Box sx={{ background: "white", borderRadius: ".5rem" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          p={3}
          spacing={2}
        >
          <Typography
            component={"div"}
            sx={{
              fontSize: "1.18rem",
              fontWeight: 700,
              color: Colors.primary.darkest,
            }}
          >
            Users
          </Typography>
          <TextField
            placeholder="Search"
            onChange={(e) =>
              setQueryParams({ ...p, search_term: e.target.value })
            }
            value={queryParams.get("search_term")}
          />
        </Stack>
      </Box>
      <StatusContainer status={users.status}>
        <UserTable
          rows={users.data?.results ?? []}
          refetch={users.refetch}
          count={users.data?.count as number}
          pages={users.data?.pages as number}
        />
      </StatusContainer>
    </Box>
  );
};
