import { Paper, Grid, Typography, Button, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { CustomAutoComplete } from "../../lib/ui/forms/autoComplete";
import { StatusContainer } from "../../lib/ui/forms/common/status-container";
import { CustomDatePicker } from "../../lib/ui/forms/date-picker";
import { CustomSelect } from "../../lib/ui/forms/select";
import { TextField, FileField, NumField } from "../../lib/ui/forms/text-field";
import {
  PartnerResponse,
  usePartnerQuery,
} from "../../lib/xhr/partners/partners";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import { Colors } from "../../config/theme/theme";
import {
  ReportResponse,
  useReportQuery,
  useReportQueryById,
  useUpdateReportMutation,
} from "../../lib/xhr/reports/report";
import { CustomModal } from "../../components/modals/custom-modal";
import { CheckboxField } from "../../lib/ui/forms/checkbox";
import { useAuth } from "../../lib/context/auth.context";
import { CLoadingButton } from "../../components/buttons/button";
import { useQueryClient } from "@tanstack/react-query";

const fileSchema = z.object({
  0: z.object({
    lastModified: z.any(),
    lastModifiedDate: z.any(),
    name: z.any(),
    size: z.any(),
    type: z.any(),
    webkitRelativePath: z.any(),
  }),
  length: z.number(),
});

const schema = z
  .object({
    // organisation: z.number(),
    name: z.string().min(1, "Required"),
    report_period_to: z.date(),
    report_period_from: z.date(),
    verification_means: z.string().min(1, "Required"),
    outcome: z.string().min(1, "Required"),
    target: z.string().min(1, "Required"),
    gender_disaggregated: z.boolean(),
    attachment: z.any(),
    achieved: z.any(z.number()),
    maleachieved: z.any(z.number()),
    femaleachieved: z.any(z.number()),
  })
  .superRefine(({ report_period_from, report_period_to }, ctx) => {
    const start = new Date(report_period_from);
    const end = new Date(report_period_to);

    if (start.getTime() > end.getTime()) {
      console.log("it is");
      ctx.addIssue({
        code: "custom",
        message: "Report period from  must be less than report period to",
        path: ["report_period_to"],
      });
    }
  });

export const EditReportPage = () => {
  const { id } = useParams();
  const partnerQuery = usePartnerQuery();
  const reportQuery = useReportQueryById(id as string);

  return (
    <Paper sx={{ minHeight: "100vh" }}>
      <StatusContainer status={reportQuery.status}>
        <Content
          report={reportQuery.data?.results!}
          partners={partnerQuery.data?.results!}
          id={id as string}
        />
      </StatusContainer>
    </Paper>
  );
};

const Content = ({
  report,
  partners,
  id,
}: {
  report: ReportResponse;
  partners: PartnerResponse[];
  id: string;
}) => {
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const reportQuery = useReportQuery();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    form.setValue("name", report?.name);
    form.setValue("report_period_from", new Date(report?.report_period_from));
    form.setValue("report_period_to", new Date(report?.report_period_to));
    form.setValue("verification_means", report?.verification_means);
    form.setValue("outcome", report?.outcome);
    form.setValue("achieved", report?.achieved);
    form.setValue("maleachieved", report?.maleachieved);
    form.setValue("femaleachieved", report?.femaleachieved);
    form.setValue("gender_disaggregated", report?.gender_disaggregated);
    form.setValue("target", report?.target);
  }, [report]);

  const { mutateAsync: reportMutAsync, isLoading } = useUpdateReportMutation();
  const form = useForm({
    resolver: zodResolver(schema),
    mode: "onChange",
    // defaultValues,
  });

  // console.log("report", report);
  // console.log("state", defaultValues);
  // console.log("form", form.getValues());
  const disaggregated = form.watch("gender_disaggregated");

  // console.log(form.formState.errors);

  const submit = async (data: any) => {
    const org = partners.filter(
      (p) => p.code === auth.state.user?.organisation
    );

    const payload = {
      ...data,
      organisation: org.at(0)?.id,
      report_period_from: dayjs(data.report_period_from).format("YYYY-MM-DD"),
      report_period_to: dayjs(data.report_period_to).format("YYYY-MM-DD"),
      attachment: data.attachment[0],
      id,
      level: report.level,
      level_id: report?.activity || report.task || report.action || report.kpi,
    };

    // console.log(new FormData(data.attachment[0]));
    // console.log(payload);

    await reportMutAsync(payload, {
      onSuccess: async (data) => {
        if (data.results.messages) {
          return enqueueSnackbar(data.results.messages, {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          });
        }

        enqueueSnackbar("Successfully edited 1 Report", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
        queryClient.invalidateQueries({
          queryKey: ["getReports"],
        });
        await reportQuery.refetch();
        return navigate(
          `/reports?page=1&page_size=10&name=${data.results?.name}`
        );
      },
      onError: (err) => enqueueSnackbar("error", { variant: "error" }),
    });
  };

  return (
    <CustomModal closecb={form.reset}>
      <Stack spacing={3} component="form" onSubmit={form.handleSubmit(submit)}>
        <Stack direction="row" justifyContent="center">
          <Typography variant="h5"> Edit Report</Typography>
        </Stack>
        <Stack direction="column" spacing={2}>
          <TextField
            label="Name"
            labelProps={{ variant: "h6" }}
            {...form.register("name")}
            name="name"
            control={form.control}
            errors={form.formState.errors}
            defaultValue={report?.name}
          />
          <CustomDatePicker
            name="report_date"
            label="Reporting period from"
            labelProps={{ variant: "h6" }}
            control={form.control}
            errors={form.formState.errors}
            defaultValue={new Date(report?.report_period_from)}
          />
          <CustomDatePicker
            name="report_period_to"
            label="Reporting period to"
            labelProps={{ variant: "h6" }}
            control={form.control}
            errors={form.formState.errors}
            defaultValue={new Date(report?.report_period_to)}
          />
          <TextField
            label="Verification means"
            labelProps={{ variant: "h6" }}
            name="verification_means"
            control={form.control}
            errors={form.formState.errors}
            defaultValue={report?.verification_means}
          />
          <TextField
            label="Outcome"
            labelProps={{ variant: "h6" }}
            {...form.register("outcome")}
            name="indicator"
            control={form.control}
            errors={form.formState.errors}
            // type="number"
            defaultValue={report?.outcome}
          />
          <TextField
            label="Target"
            labelProps={{ variant: "h6" }}
            name="target"
            control={form.control}
            errors={form.formState.errors}
            defaultValue={report?.target}
          />
          <CheckboxField
            label="Gender Disaggregated"
            control={form.control}
            errors={form.formState.errors}
            {...form.register("gender_disaggregated")}
            defaultChecked={
              form.getValues("gender_disaggregated") === undefined
                ? false
                : form.getValues("gender_disaggregated")
            }
          />
          {disaggregated ? (
            <Stack direction="row" spacing={2}>
              <NumField
                label="Male"
                labelProps={{ variant: "h6" }}
                errors={form.formState.errors}
                name="maleachieved"
                control={form.control}
                type="number"
              />
              <NumField
                label="Female"
                labelProps={{ variant: "h6" }}
                errors={form.formState.errors}
                name="femaleachieved"
                control={form.control}
                type="number"
              />
            </Stack>
          ) : (
            <NumField
              label="Achieved"
              labelProps={{ variant: "h6" }}
              errors={form.formState.errors}
              name="achieved"
              control={form.control}
              type="number"
            />
          )}
          <FileField
            labelProps={{ variant: "h6" }}
            errors={form.formState.errors}
            type="file"
            label="Attachment"
            {...form.register("attachment")}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Button
            onClick={(e) => {
              navigate(-1);
            }}
            variant="contained"
            sx={{ bgcolor: Colors.info.main }}
          >
            Cancel
          </Button>
          <CLoadingButton loading={isLoading} />
        </Stack>
      </Stack>
    </CustomModal>
  );
};
