import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Colors } from "../../config/theme/theme";
import BasicSelect from "../../components/BasicSelect/basic-select";
import Button from "@mui/material/Button";
import SearchInput from "../../components/BasicInput/input-with-adornment";
import FilterListIcon from "@mui/icons-material/FilterList";
import { IsGizGuard } from "../../lib/context/guards/isGizGuard";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Autocomplete,
} from "@mui/material";
import {
  PartnerResponse,
  usePartnerQuery,
} from "../../lib/xhr/partners/partners";
import { StatusContainer } from "../../lib/ui/forms/common/status-container";
import { Stack } from "@mui/system";
import React from "react";

export const ActivitiyHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [params, setParams] = useSearchParams();
  const orgs = usePartnerQuery();

  const value = orgs.data?.results?.filter(
    (o) => o.code === params.get("partner")!
  ) as any;

  const p = {
    name: params.get("name") ?? "",
    partner: params.get("partner") ?? "",
    page: params.get("page") ?? "",
    page_size: params.get("page_size") ?? "",
  };

  return (
    <Stack
      direction={{
        xs: "column",
        md: "row",
      }}
      justifyContent="space-between"
      flexWrap="wrap"
      p={3}
      spacing={2}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography
          component={"div"}
          sx={{
            fontSize: "1.18rem",
            fontWeight: 700,
            color: Colors.primary.darkest,
          }}
        >
          All Activities
        </Typography>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-start"
          alignItems="center"
        >
          <IsGizGuard>
            <StatusContainer status={orgs.status}>
              <Autocomplete
                options={(orgs.data?.results as PartnerResponse[]) ?? []}
                getOptionLabel={(o) => o?.code ?? ""}
                value={value ? value.code : ""}
                onChange={(event: any, newValue: PartnerResponse | null) => {
                  setParams({ ...p, partner: newValue?.code ?? "" });
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Partner" variant="outlined" />
                )}
                sx={{ minWidth: "18rem" }}
              />
            </StatusContainer>
          </IsGizGuard>
          <TextField
            placeholder="Search with name"
            onChange={(e) => setParams({ ...p, name: e.target.value })}
            value={params.get("name")}
            sx={{ minWidth: "18rem" }}
          />
        </Stack>
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        py={{
          xs: 2,
          xl: 0,
        }}
      >
        <IsGizGuard>
          <Button
            variant="contained"
            component={Link}
            to="/activities/upload"
            state={{ backgroundLocation: location }}
          >
            Upload
          </Button>
          <Button
            variant="contained"
            component={Link}
            to="/activities/add"
            state={{ backgroundLocation: location }}
          >
            Add New
          </Button>
        </IsGizGuard>
      </Stack>
    </Stack>
  );
};
