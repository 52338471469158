import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import GroupsTable from "../../components/tables/roles/roles-table-component";
import { Colors } from "../../config/theme/theme";
import { StatusContainer } from "../../lib/ui/forms/common/status-container";
import { useGroupQuery } from "../../lib/xhr/roles/roles";

export const RoleManagementPage: React.FC<React.PropsWithChildren<any>> = (
  props
) => {
  const [params, setParams] = useSearchParams();
  const location = useLocation();
  const block = React.useRef(false);

  const { data, status, refetch } = useGroupQuery({
    page: params.get("page") ?? 1,
    page_size: params.get("page_size") ?? 10,
  });

  const p = {
    page: params.get("page") ?? "",
    page_size: params.get("page_size") ?? "",
  };

  React.useEffect(() => {
    const initializePage = () => {
      setParams({
        page: params.get("page") ?? "1",
        page_size: params.get("page_size") ?? "10",
      });
    };

    if (block.current === false) {
      block.current = true;
      initializePage();
      // console.log("loaded");
    }
  }, []);

  return (
    <Box sx={{ p: "2rem" }}>
      <Box sx={{ background: "white", borderRadius: ".5rem" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          p={3}
        >
          <Typography
            component={"div"}
            sx={{
              fontSize: "1.18rem",
              fontWeight: 700,
              color: Colors.primary.darkest,
            }}
          >
            Roles
          </Typography>
          <Button
            variant="contained"
            component={Link}
            to="/roles/add"
            state={{ backgroundLocation: location }}
          >
            ADD
          </Button>
        </Stack>
      </Box>
      <StatusContainer status={status}>
        <GroupsTable
          rows={data?.results ?? []}
          refetch={refetch}
          count={data?.count}
          pages={data?.pages as number}
        />
      </StatusContainer>
    </Box>
  );
};
