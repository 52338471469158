import { Button, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { CustomModal } from "../../components/modals/custom-modal";
import { CustomAutoComplete } from "../../lib/ui/forms/autoComplete";
import { TextField } from "../../lib/ui/forms/text-field";
import {
  useGroupAddMutation,
  useGroupAddPermissionMutation,
  useGroupQuery,
  usePermissionQuery,
} from "../../lib/xhr/roles/roles";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = z.object({
  permissions: z.array(
    z.object({
      label: z.string(),
      value: z.number(),
    })
  ),
});

type TSChema = z.infer<typeof schema>;

export const AddRolePermission = () => {
  const permission = usePermissionQuery();
  const mut = useGroupAddPermissionMutation();
  const { refetch } = useGroupQuery();
  const { group } = useParams();
  const navigate = useNavigate();

  const form = useForm<TSChema>({
    resolver: zodResolver(schema),
  });

  // console.log(form.formState.errors);

  const submit = (data: TSChema) => {
    // console.log(data);
    // console.log(group);
    mut.mutate(
      {
        permissions: data.permissions.map((p) => p.value),
        group: group as string,
      },
      {
        onSuccess: (data) => {
          //TODO reformat response once updated
          refetch();
          navigate(-1);
        },
      }
    );
  };

  return (
    <CustomModal>
      <Stack direction="row" justifyContent="center">
        <Typography variant="h4">Add Permission to Role</Typography>
      </Stack>
      <Stack spacing={2} component="form" onSubmit={form.handleSubmit(submit)}>
        <CustomAutoComplete
          name="permissions"
          options={
            permission.data?.results?.map((permission) => ({
              label: permission.name,
              value: permission.id,
            })) ?? []
          }
          labelProps={{ variant: "h6" }}
          label={"Permissions"}
          control={form.control}
          multiple={true}
          errors={form.formState.errors}
        />
        <Button variant="contained" type="submit">
          Submit
        </Button>
      </Stack>
    </CustomModal>
  );
};
