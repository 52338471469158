import { Button, Grid, Paper, Toolbar, Typography } from "@mui/material";
import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { Colors } from "../../config/theme/theme";
import { CustomAutoComplete } from "../../lib/ui/forms/autoComplete";
import { StatusContainer } from "../../lib/ui/forms/common/status-container";
import { PasswordField, TextField } from "../../lib/ui/forms/text-field";
import { useRegisterMutation } from "../../lib/xhr/auth/auth";
import { usePartnerQuery } from "../../lib/xhr/partners/partners";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSnackbar } from "notistack";
import React from "react";
import { CLoadingButton } from "../../components/buttons/button";

const passwordRegex = /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$/;

const schema = z
  .object({
    email: z.string().email(),
    name: z.string().min(3),
    // user_type: z.string(),
    organisation: z.object({ label: z.string(), value: z.number() }),
    password: z
      .string()
      .regex(
        passwordRegex,
        "Password must contain atleast one uppercase letter, 3 lowercase letters , one special character, 3 numbers and be atleast 5 charcters"
      )
      .min(5),
  })
  .required({ email: true, organization: true, password: true, name: true });

export default function SignUpPage() {
  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
  } = useForm({
    resolver: zodResolver(schema),
    mode: "onChange",
  });

  const organisations = usePartnerQuery();
  const registerMut = useRegisterMutation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // console.log(errors);

  const submitHandler = (data: any) => {
    // console.log(data);
    registerMut.mutate(
      {
        ...data,
        organisation: data.organisation.value,
        user_type: "2",
      },
      {
        onSuccess: (data) => {
          // console.log(data);
          if (data.results.messages) {
            return enqueueSnackbar(data.results.messages, {
              variant: "error",
            });
          }

          enqueueSnackbar("success", {
            variant: "success",
          });
          navigate("/auth/login");
        },
      }
    );
  };
  return (
    <Paper
      sx={{
        background: Colors.primary.dark,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Grid container justifyContent={"center"}>
        <Grid item xs={12} md={4}>
          <Paper
            sx={{ p: 5, background: "white" }}
            component="form"
            onSubmit={handleSubmit(submitHandler)}
          >
            <Stack direction="column" alignItems={"center"} spacing={2}>
              <img src="/logo.svg" alt="logo" />
              <Typography color={`${Colors.primary.main}`}>
                GCF Monitoring Tool
              </Typography>

              <Typography
                color={`${Colors.secondary.dark}`}
                variant="h4"
                fontWeight="600"
              >
                Sign up
              </Typography>
              <Typography>Register your details below</Typography>
            </Stack>
            <Toolbar />
            <Stack spacing={2} direction="column">
              <TextField
                label="Email"
                {...register("email")}
                labelProps={{ variant: "subtitle2" }}
                errors={errors}
                name="email"
                control={control}
                placeholder="email@example.com"
              />
              <TextField
                label="Name"
                labelProps={{ variant: "subtitle2" }}
                name="name"
                control={control}
                errors={errors}
                placeholder="John Doe"
              />
              <StatusContainer status={organisations.status}>
                <CustomAutoComplete
                  labelProps={{ variant: "subtitle2" }}
                  label={"Organisation"}
                  options={
                    organisations.data?.results?.map((partner) => ({
                      label: partner.code,
                      value: partner.id,
                    })) as any
                  }
                  errors={errors}
                  control={control}
                  name={"organisation"}
                />
              </StatusContainer>
              <Stack>
                <Typography variant="subtitle2">Password</Typography>
                <PasswordField
                  // label="Password"
                  {...register("password")}
                  control={control}
                  errors={errors}
                />
              </Stack>
              <CLoadingButton loading={registerMut.isLoading} label="Sign up" />
            </Stack>
            <Stack direction={"row"} spacing={2} sx={{ my: 2 }}>
              <Typography>Already a member? </Typography>
              <Typography component={Link} to="/auth/login">
                Login
              </Typography>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
}
