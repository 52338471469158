import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { KPIResponse } from "../../../lib/xhr/kpi/kpi";
import { EnhancedTableProps, HeadCell } from "./kpi-table-types";
import { visuallyHidden } from "@mui/utils";
import { Colors } from "../../../config/theme/theme";
import React from "react";

const headCells: readonly HeadCell[] = [
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "partner",
    numeric: false,
    disablePadding: false,
    label: "Organisations",
  },
  {
    id: "outcome",
    numeric: false,
    disablePadding: false,
    label: "Outcome",
  },
  {
    id: "target",
    numeric: true,
    disablePadding: false,
    label: "Target",
  },
  {
    id: "achieved",
    numeric: true,
    disablePadding: false,
    label: "Achieved",
  },
  {
    id: "maleachieved",
    numeric: true,
    disablePadding: false,
    label: "Male Achieved",
  },
  {
    id: "femaleachieved",
    numeric: true,
    disablePadding: false,
    label: "Female Achieved",
  },
  {
    id: "start_date",
    numeric: false,
    disablePadding: false,
    label: "Start",
  },
  {
    id: "end_date",
    numeric: false,
    disablePadding: false,
    label: "End",
  },
  {
    id: "verification_means",
    numeric: false,
    disablePadding: false,
    label: "Verification means",
  },
  {
    id: "gender_disaggregated",
    numeric: false,
    disablePadding: false,
    label: "Gender disaggregated",
  },
  {
    id: "created_by",
    numeric: false,
    disablePadding: false,
    label: "Created by",
  },
];

export function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler =
    (property: keyof KPIResponse) => (event: React.MouseEvent<unknown>) => {
      onRequestSort!(event, property);
    };

  return (
    <TableHead>
      <TableRow sx={{ background: Colors.primary.dark }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{
                color: "white! important",
                "&:hover": { color: "red! important" },
                "&:focus": { color: "red! important" },
                "& .MuiTableSortLabel-icon": {
                  color: "white",
                },
                "&.Mui-active .MuiTableSortLabel-icon": {
                  opacity: 1,
                  color: "red",
                },
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell
          sx={{
            color: "white",
            "&:hover": { color: "red! important" },
            "&:focus": { color: "red! important" },
          }}
        >
          More actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
