import { Paper, Typography, Button, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { CustomAutoComplete } from "../../../lib/ui/forms/autoComplete";
import { StatusContainer } from "../../../lib/ui/forms/common/status-container";
import { CustomDatePicker } from "../../../lib/ui/forms/date-picker";
import { TextField } from "../../../lib/ui/forms/text-field";
import {
  PartnerResponse,
  usePartnerQuery,
} from "../../../lib/xhr/partners/partners";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { Colors } from "../../../config/theme/theme";
import {
  ActivityResponse,
  useActivityQuery,
  useActivityQueryById,
  useUpdateActivityMutation,
} from "../../../lib/xhr/activity/activity";
import { CustomModal } from "../../../components/modals/custom-modal";
// import { KPIResponse, useKPIQuery } from "../../../lib/xhr/kpi/kpi";
import { CheckboxField } from "../../../lib/ui/forms/checkbox";
import React from "react";
import { CLoadingButton } from "../../../components/buttons/button";
import { useQueryClient } from "@tanstack/react-query";

const schema = z
  .object({
    partner: z.array(z.object({ label: z.string(), value: z.number() })),
    name: z.string().min(1, "Required"),
    start_date: z.date(),
    end_date: z.date(),
    verification_means: z.string().min(1, "Required"),
    // kpi: z.any(z.object({ label: z.string(), value: z.number() })),
    target: z.string().min(1, "Required"),
    gender_disaggregated: z.boolean(),
    outcome: z.string(),
    code: z.string(),
  })
  .superRefine(({ start_date, end_date }, ctx) => {
    const start = new Date(start_date);
    const end = new Date(end_date);

    if (start.getTime() > end.getTime()) {
      // console.log("it is");
      ctx.addIssue({
        code: "custom",
        message: "Start date must be less than end date",
        path: ["end_date"],
      });
    }
  });

export const EditActivityPage = () => {
  const { id } = useParams();
  const activityQuery = useActivityQueryById(Number(id));
  const partnerQuery = usePartnerQuery();
  // const kpiQuery = useKPIQuery();

  // const kpiList =
  //   kpiQuery.data?.results?.map((kpi) => ({
  //     label: kpi.name,
  //     value: kpi.id,
  //   })) ?? [];

  // const kpi = kpiQuery.data?.results
  //   ?.filter((k) => k.id === Number(activityQuery.data?.results?.kpi))
  //   .map((kpi) => ({
  //     label: kpi.name,
  //     value: kpi.id,
  //   }));

  return (
    <Paper sx={{ minHeight: "100vh" }}>
      <StatusContainer status={activityQuery.status}>
        <Content
          activity={activityQuery.data?.results!}
          partners={partnerQuery.data?.results!}
          // kpis={kpiList as any}
          // kpi={kpi}
        />
      </StatusContainer>
    </Paper>
  );
};

const Content = ({
  activity,
  partners,
}: // kpis,
// kpi,
{
  activity: ActivityResponse;
  partners: PartnerResponse[];
  // kpis: KPIResponse[];
  // kpi: any;
}) => {
  // console.log("kpi", kpi);
  const partnerResult = partners
    ?.map((partner) => {
      if (activity?.partner.find((item) => item.id === partner.id)) {
        return {
          label: partner.code,
          value: partner.id,
        };
      }
      return undefined;
    })
    .filter((item) => item !== undefined);

  const { mutateAsync: ActivityMutAsync, isLoading } =
    useUpdateActivityMutation();

  React.useEffect(() => {
    form.setValue("name", activity?.name);
    form.setValue("start_date", new Date(activity?.start_date));
    form.setValue("end_date", new Date(activity?.end_date));
    form.setValue("verification_means", activity?.verification_means);
    form.setValue("outcome", activity?.outcome);
    form.setValue("partner", partnerResult);
    // form.setValue("kpi", kpi.at(0));
    form.setValue("gender_disaggregated", activity?.gender_disaggregated);
    form.setValue("target", activity?.target);
    form.setValue("code", activity?.code);
  }, [
    activity,
    // kpi
  ]);

  const form = useForm({
    resolver: zodResolver(schema),
    mode: "onChange",
  });

  // console.log(form.formState.errors);
  // const agg = form.watch("gender_disaggregated");
  // console.log("gender disaggregated", agg);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const activityQuery = useActivityQuery();
  const queryClient = useQueryClient();

  const submit = async (data: any) => {
    const payload = {
      ...data,
      partner: data.partner.map((org: any) => org.value),
      start_date: dayjs(data.start_date).format("YYYY-MM-DD"),
      end_date: dayjs(data.end_date).format("YYYY-MM-DD"),
      id: activity.id,
    };

    // console.log(payload);

    await ActivityMutAsync(payload, {
      onSuccess: (data) => {
        if (data.results.messages) {
          return enqueueSnackbar(data.results.messages, {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          });
        }

        enqueueSnackbar("Successfully Edited 1 Activity", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
        queryClient.invalidateQueries(["getActivity"]);
        activityQuery.refetch();
        return navigate(
          `/activities?page=1&page_size=10&name=${data.results.name}`
        );
      },
    });
  };

  return (
    <CustomModal>
      <Stack direction="row" justifyContent="center" p={1}>
        <Typography variant="h5"> Edit Activity</Typography>
      </Stack>
      <Stack
        direction="column"
        component="form"
        spacing={2}
        onSubmit={form.handleSubmit(submit)}
      >
        <TextField
          label="Code"
          labelProps={{ variant: "h6" }}
          name="code"
          control={form.control}
          errors={form.formState.errors}
          defaultValue={activity?.code}
        />
        <CustomAutoComplete
          name="partner"
          options={
            partners?.map((partner) => ({
              label: partner.code,
              value: partner.id,
            })) ?? []
          }
          labelProps={{ variant: "h6" }}
          label={"Partner"}
          control={form.control}
          multiple={true}
          errors={form.formState.errors}
          defaultValue={partnerResult}
        />
        <TextField
          label="Name"
          labelProps={{ variant: "h6" }}
          name="name"
          control={form.control}
          errors={form.formState.errors}
          defaultValue={activity?.name}
        />
        <CustomDatePicker
          name="start_date"
          label="Start date"
          labelProps={{ variant: "h6" }}
          control={form.control}
          errors={form.formState.errors}
          defaultValue={new Date(activity?.start_date)}
        />
        <CustomDatePicker
          name="end_date"
          label="End date"
          labelProps={{ variant: "h6" }}
          control={form.control}
          errors={form.formState.errors}
          defaultValue={new Date(activity?.end_date)}
        />
        <TextField
          label="Verification means"
          labelProps={{ variant: "h6" }}
          name="verification_means"
          control={form.control}
          errors={form.formState.errors}
          defaultValue={activity?.verification_means}
        />
        <TextField
          label="Outcome"
          labelProps={{ variant: "h6" }}
          name="outcome"
          control={form.control}
          errors={form.formState.errors}
          defaultValue={activity?.outcome}
        />
        {/* <CustomAutoComplete
          name="kpi"
          options={kpis ?? []}
          labelProps={{ variant: "h6" }}
          label="KPI"
          control={form.control}
          multiple={false}
          errors={form.formState.errors}
          defaultValue={kpi.at(0)}
        /> */}
        <TextField
          label="Target"
          labelProps={{ variant: "h6" }}
          name="target"
          control={form.control}
          errors={form.formState.errors}
          defaultValue={activity?.target}
        />
        <CheckboxField
          name="gender_disaggregated"
          label="Gender disaggregated"
          errors={form.formState.errors}
          control={form.control}
          defaultChecked={activity?.gender_disaggregated}
        />
        <Stack direction="row" justifyContent="space-between">
          <Button
            onClick={(e) => navigate(-1)}
            variant="contained"
            sx={{ bgcolor: Colors.info.main }}
          >
            Cancel
          </Button>
          <CLoadingButton loading={isLoading} />
        </Stack>
      </Stack>
    </CustomModal>
  );
};
