import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { InfoCard } from "../../components/cards/card";
import { Colors } from "../../config/theme/theme";
import BasicTable from "../../components/tables/dashboard/dashboard.table";
import { Link } from "react-router-dom";
import { useDefaultSummary } from "../../lib/xhr/summary/summary";
import {
  LoadingContainer,
  StatusContainer,
} from "../../lib/ui/forms/common/status-container";
import { useKPIQuery } from "../../lib/xhr/kpi/kpi";
import Stack from "@mui/material/Stack";
import { useMilestoneQuery } from "../../lib/xhr/milestones/milestone";
import PerformanceChart from "../../components/chart-js/line-graph";
import React from "react";
import { MenuItem, Select } from "@mui/material";


const DashboardPage = () => {
  const date = new Date();
  const query = useDefaultSummary();
  const kpiQuery = useKPIQuery();
  const milestones = useMilestoneQuery();
  const [selectedKPI, setSelectedKPI] = React.useState(
    kpiQuery.data?.results?.[0]
  );

  const hanldeFilter = (e: any) => {
    const kpi = kpiQuery.data?.results?.find(
      (kpi) => kpi.id === e.target.value
    );
    setSelectedKPI(kpi);
  };

  React.useEffect(() => {
    if (kpiQuery.data?.results?.length > 0) {
      setSelectedKPI(kpiQuery.data?.results?.[0]);
    }
  }, [kpiQuery.data?.results]);

  return (
    <Box>
      {/* <Toolbar /> */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} xl={2}>
          <InfoCard value={query.data?.results?.activity} title="Activities" />
        </Grid>
        <Grid item xs={12} md={3} xl={2}>
          <InfoCard
            value={query.data?.results?.milestones}
            title="Milestones"
          />
        </Grid>
        <Grid item xs={12} md={3} xl={2}>
          <InfoCard value={query.data?.results?.kpis} title="KPIs" />
        </Grid>
        <Grid item xs={12} md={3} xl={2}>
          <InfoCard value={query.data?.results?.reports} title="KPI Reports" />
        </Grid>
        <Grid item xs={12} md={3} xl={2}>
          <InfoCard value={query.data?.results?.milestone_reports} title="Milestone Reports" />
        </Grid>
      </Grid>
      <Toolbar />
      <Stack>
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ fontSize: "1.19rem", fontWeight: 700 }}
        >
          Kpi Trends
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: "2rem" }}
        >
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ fontSize: ".75rem", color: Colors.primary.main }}
          >
            as of {`${date.getDay()}-${date.getMonth()}-${date.getFullYear()}`}
          </Typography>
          <LoadingContainer loading={kpiQuery.isLoading}>
            <Select
              sx={{ minWidth: "50ch" }}
              displayEmpty
              onChange={hanldeFilter}
              value={selectedKPI?.id ?? 0}
            >
              <MenuItem disabled selected>
                Select KPI
              </MenuItem>
              {kpiQuery.data?.results?.map((kpi, index) => (
                <MenuItem value={kpi.id}>{kpi.name}</MenuItem>
              ))}
            </Select>
          </LoadingContainer>
        </Stack>
        <Box
          sx={{
            height: "80%",
            // maxHeight: "30rem",
            width: "100%",
            position: "relative",
            background: "white",
            p: 3,
            boxSizing: "border-box",
          }}
        >
          <LoadingContainer loading={kpiQuery.isLoading}>
            {/* <LineChart dataSet={kpiQuery.data?.results} /> */}
            <PerformanceChart
              kpi={
                selectedKPI ?? {
                  id: 0,
                  name: "",
                  description: "",
                  target: 0,
                  achieved: 0,
                  start_date: "",
                  end_date: "",
                }
              }
            />
          </LoadingContainer>
        </Box>
      </Stack>

      <Toolbar />
      <Grid container spacing={2}>
        <Grid item xs={12} xl={6}>
          <Stack spacing={3}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography fontWeight="bold">KPIs:</Typography>
              <Typography component={Link} to={"/kpis"} fontWeight="bold">
                View all
              </Typography>
            </Stack>
            <StatusContainer status={kpiQuery?.status}>
              <BasicTable
                rows={(kpiQuery.data?.results?.slice(0, 6) as any) ?? []}
              />
            </StatusContainer>
          </Stack>
        </Grid>
        <Grid item xs={12} xl={6}>
          <Stack spacing={3}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography fontWeight="bold">Milestones:</Typography>
              <Typography component={Link} to={"/milestones"} fontWeight="bold">
                View all
              </Typography>
            </Stack>
            <StatusContainer status={milestones?.status}>
              <BasicTable
                rows={(milestones.data?.results?.slice(0, 6) as any) ?? []}
              />
            </StatusContainer>
          </Stack>
        </Grid>
      </Grid>
      <Toolbar />
    </Box>
  );
};

export default DashboardPage;
