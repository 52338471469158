import {
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableBody,
} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { CustomPagination } from "../../components/Pagination/pagination-component";
import { Colors } from "../../config/theme/theme";
import { StatusContainer } from "../../lib/ui/forms/common/status-container";
import {
  ActivityLogResponse,
  useActivityLogQuery,
} from "../../lib/xhr/activity-log/activity-log";

export function ActivityLogPage() {
  const [params, setParams] = useSearchParams();
  const block = React.useRef(false);

  const query = useActivityLogQuery({
    page: params.get("page") ?? 1,
    page_size: params.get("page_size") ?? 10,
  });

  React.useEffect(() => {
    const initializePage = () => {
      setParams({
        page: params.get("page") ?? "1",
        page_size: params.get("page_size") ?? "10",
      });
    };

    if (block.current === false) {
      block.current = true;
      initializePage();
      // console.log("loaded");
    }
  }, []);

  return (
    <Box sx={{ p: "2rem" }}>
      <Box sx={{ background: "white", borderRadius: ".5rem" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              component={"div"}
              sx={{
                fontSize: "1.18rem",
                fontWeight: 700,
                color: Colors.primary.darkest,
                ml: 3,
              }}
            >
              Activity Log
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <StatusContainer status={query.status}>
              <CustomPagination
                advanced={false}
                count={query.data?.count as number}
                pages={query.data?.pages as number}
                position="start"
              />
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow sx={{ background: Colors.primary.dark }}>
                      <TableCell
                        sx={{
                          color: "white",
                          "&:hover": { color: "red! important" },
                          "&:focus": { color: "red! important" },
                        }}
                      >
                        ID
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          color: "white",
                          "&:hover": { color: "red! important" },
                          "&:focus": { color: "red! important" },
                        }}
                      >
                        Email
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          color: "white",
                          "&:hover": { color: "red! important" },
                          "&:focus": { color: "red! important" },
                        }}
                      >
                        Client IP Address
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          color: "white",
                          "&:hover": { color: "red! important" },
                          "&:focus": { color: "red! important" },
                        }}
                      >
                        Event Name
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          color: "white",
                          "&:hover": { color: "red! important" },
                          "&:focus": { color: "red! important" },
                        }}
                      >
                        Created Time
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {query?.data?.results?.messages
                      ? []
                      : query?.data?.results?.map(
                          (row: ActivityLogResponse) => (
                            <TableRow
                              key={row.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {row.id}
                              </TableCell>
                              <TableCell align="left">{row.email}</TableCell>
                              <TableCell align="left">
                                {row.client_ip_address}
                              </TableCell>
                              <TableCell align="left">
                                {row.event_name}
                              </TableCell>
                              <TableCell align="left">
                                {row.created_time}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                  </TableBody>
                </Table>
              </TableContainer>
              <CustomPagination
                advanced={true}
                count={query.data?.count as number}
                pages={query.data?.pages as number}
                position="end"
              />
            </StatusContainer>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
