import { Button, Paper, Toolbar, Typography, Grid } from "@mui/material";
import { Stack } from "@mui/material";
import { Colors } from "../../config/theme/theme";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { PasswordField } from "../../lib/ui/forms/text-field";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useResetPassword } from "../../lib/xhr/auth/auth";
import { useSnackbar } from "notistack";

const schema = z
  .object({
    password: z.string(),
    new_password2: z.string(),
  })
  .required({ password: true });

export default function ResetPasswordPage() {
  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
  } = useForm({
    resolver: zodResolver(schema),
  });

  const [search, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const mut = useResetPassword();

  const submitHandler = (data: any) => {
    const uid = search.get("uid");
    const token = search.get("token");

    mut.mutate(
      {
        password: data.password,
        new_password2: data.new_password2,
        uid: uid as string,
        token: token as string,
      },
      {
        onSuccess: (data: any) => {
          if (data.message) {
            return enqueueSnackbar(data.message, {
              variant: "success",
              anchorOrigin: { horizontal: "center", vertical: "top" },
            });
          }
        },
      }
    );
  };

  return (
    <Paper
      sx={{
        background: Colors.primary.dark,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Grid container justifyContent={"center"}>
        <Grid item xs={12} md={4}>
          <Paper
            sx={{ p: 5, background: "white" }}
            component="form"
            onSubmit={handleSubmit(submitHandler)}
          >
            <Stack direction="column" alignItems={"center"} spacing={2}>
              <img src="/logo.svg" alt="logo" />
              <Typography color={`${Colors.primary.main}`}>
                GCF Monitoring Tool
              </Typography>

              <Typography
                color={`${Colors.secondary.dark}`}
                variant="h4"
                fontWeight="600"
              >
                Reset password
              </Typography>
              <Typography>Enter your new password</Typography>
            </Stack>
            <Toolbar />
            <Stack spacing={2} direction="column">
              <PasswordField
                label="Password"
                {...register("password")}
                control={control}
                errors={errors}
              />
              <PasswordField
                label="Confirm Password"
                {...register("new_password2")}
                control={control}
                errors={errors}
              />
              <Button variant="contained" type="submit">
                Submit
              </Button>
            </Stack>
            <Stack direction={"column"} spacing={2} sx={{ my: 2 }}>
              <Typography>
                Remembered password ?
                <Typography component={Link} to="/auth/login">
                  Login
                </Typography>
              </Typography>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
}
