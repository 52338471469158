import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Colors } from "../../config/theme/theme";
import KPIsTable from "../../components/tables/KPIs/kpi-table.component";
import { useKPIQuery } from "../../lib/xhr/kpi/kpi";
import { StatusContainer } from "../../lib/ui/forms/common/status-container";
import { Autocomplete, Button, Stack, TextField } from "@mui/material";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { IsGizGuard } from "../../lib/context/guards/isGizGuard";
import React from "react";
import { usePartnerQuery } from "../../lib/xhr/partners/partners";
import { PartnerResponse } from "../../lib/xhr/partners/partners";

export default function KPIPage() {
  const [params, setParams] = useSearchParams();
  const block = React.useRef(false);

  const kpiQuery = useKPIQuery({
    partner: params.get("partner") as string,
    page: params.get("page") ?? 1,
    page_size: params.get("page_size") ?? 10,
    name: (params.get("name") as string) ?? "",
  });
  const orgs = usePartnerQuery();
  const location = useLocation();

  const value = orgs.data?.results?.filter(
    (o) => o.code === params.get("partner")!
  ) as any;

  const p = {
    name: params.get("name") ?? "",
    partner: params.get("partner") ?? "",
    page: params.get("page") ?? "",
    page_size: params.get("page_size") ?? "",
  };

  React.useEffect(() => {
    const initializePage = () => {
      setParams({
        name: params.get("name") ?? "",
        page: params.get("page") ?? "1",
        partner: params.get("partner") ?? "",
        page_size: params.get("page_size") ?? "10",
      });
    };

    if (block.current === false) {
      block.current = true;
      initializePage();
      // console.log("loaded");
    }
  }, []);

  return (
    <Box sx={{ p: "2rem" }}>
      <Box sx={{ background: "white", borderRadius: ".5rem" }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Box
              sx={{
                display: "flex",
                gap: "2rem",
                px: "2rem",
                alignItems: "center",
              }}
            >
              <Typography
                component={"div"}
                sx={{
                  fontSize: "1.18rem",
                  fontWeight: 700,
                  color: Colors.primary.darkest,
                }}
              >
                All KPIs
              </Typography>
              <Stack
                direction="row"
                spacing={1}
                justifyContent="flex-start"
                alignItems="center"
              >
                <IsGizGuard>
                  <StatusContainer status={orgs.status}>
                    <Autocomplete
                      options={(orgs.data?.results as PartnerResponse[]) ?? []}
                      getOptionLabel={(o) => o?.code ?? ""}
                      value={value ? value.code : []}
                      onChange={(
                        event: any,
                        newValue: PartnerResponse | null
                      ) => {
                        setParams({ ...p, partner: newValue?.code ?? "" });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Partner"
                          variant="outlined"
                        />
                      )}
                      sx={{ minWidth: "18rem" }}
                    />
                  </StatusContainer>
                </IsGizGuard>
                <TextField
                  placeholder="Search with name"
                  onChange={(e) => setParams({ ...p, name: e.target.value })}
                  value={params.get("name")}
                />
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                px: "2rem",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              {/* <IsGizGuard> */}
              <Button
                variant="contained"
                component={Link}
                to="/kpis/upload"
                state={{ backgroundLocation: location }}
              >
                Upload
              </Button>
              <Button
                variant="contained"
                component={Link}
                to="/kpis/add"
                state={{ backgroundLocation: location }}
              >
                ADD
              </Button>
              {/* </IsGizGuard> */}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <StatusContainer status={kpiQuery.status}>
        <KPIsTable
          rows={kpiQuery.data?.results ?? []}
          count={kpiQuery?.data?.count as number}
          pages={kpiQuery?.data?.pages as number}
        />
      </StatusContainer>
    </Box>
  );
}
