import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import { Colors } from "../../../config/theme/theme";
import { ActivityResponse } from "../../../lib/xhr/activity/activity";
import { ActionResponse } from "../../../lib/xhr/action/action";
import { TaskResponse } from "../../../lib/xhr/task/task";
import ActionMenu from "../../menus/activities-action-menu";
import { MilestoneResponse } from "../../../lib/xhr/milestones/milestone";
import { AchievedBadge } from "../../marker/marker";
import MilestoneActionMenu from "../../menus/milestones-action-menu";
import Stack from "@mui/material/Stack";
import { useSearchParams } from "react-router-dom";
import { Pagination } from "@mui/material";
import dayjs from "dayjs";
import { CustomPagination } from "../../Pagination/pagination-component";

function TableCellCustom(
  props: {
    children?: any;
    title?: string | undefined;
    minWidth?: any;
    colors?: {
      primary?: string;
      secondary?: string;
      tertiary?: string;
    };
    colspan?: number;
    width?: any;
    align?: "center" | "right" | "left" | "justify" | "inherit" | undefined;
    paddingLeft?: number;
  } = {
    colors: {
      primary: "",
      secondary: "",
      tertiary: "",
    },
  }
) {
  const {
    children,
    title,
    colors,
    minWidth,
    colspan,
    width,
    align,
    paddingLeft,
  } = props;

  return (
    <TableCell
      sx={{ minWidth: minWidth, width: width, paddingLeft: paddingLeft }}
      colSpan={colspan}
      align={align}
    >
      <Typography sx={{ color: colors?.secondary }}>
        {title ? title : ""}
      </Typography>
      <Typography sx={{ color: colors?.primary }}>{children}</Typography>
    </TableCell>
  );
}

function TasksRow(props: { row?: TaskResponse; open: boolean }) {
  const colors = {
    primary: Colors.primary.darkest,
    secondary: Colors.primary.main,
  };
  const { row, open } = props;

  return row ? (
    <TableRow>
      <TableCell style={{ padding: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Table size="small" aria-label="purchases">
            <TableHead>
              <TableRow>
                <TableCellCustom
                  colors={{ primary: Colors.primary.main }}
                  width={"15rem"}
                  align="right"
                >
                  Code
                </TableCellCustom>
                <TableCellCustom
                  colors={{ primary: Colors.primary.darkest }}
                  width={"18rem"}
                >
                  Name
                </TableCellCustom>
                <TableCell sx={{ width: "18rem" }} />
                <TableCell sx={{ width: "18rem" }} />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              <React.Fragment>
                <TableRow
                  sx={{
                    "& > *": { borderBottom: "unset" },
                    cursor: "pointer",
                  }}
                >
                  <TableCellCustom colors={colors} align="right">
                    {row.code}
                  </TableCellCustom>
                  <TableCellCustom colors={colors}>{row.name}</TableCellCustom>
                  <TableCellCustom colors={colors} title="Indicator">
                    {row.outcome}
                  </TableCellCustom>
                  <TableCellCustom colors={colors} title="Target">
                    {row.target}
                  </TableCellCustom>
                  <TableCellCustom colors={colors} title="Achieved">
                    {row.achieved}
                  </TableCellCustom>
                  <TableCell>
                    <ActionMenu
                      id={row.id}
                      name={row.name as string}
                      route="tasks"
                    />
                  </TableCell>
                </TableRow>
              </React.Fragment>
            </TableBody>
          </Table>
        </Collapse>
      </TableCell>
    </TableRow>
  ) : (
    <></>
  );
}

function MilestonesRow(props: { open?: boolean; rows?: MilestoneResponse[] }) {
  const { open: parentOpen, rows } = props;
  const [open, setOpen] = React.useState(false);
  const colors = {
    primary: Colors.info.main,
    secondary: Colors.primary.main,
  };

  if (rows) {
    return (
      <TableRow sx={{ width: "100%" }}>
        <TableCell style={{ padding: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={parentOpen} timeout="auto" unmountOnExit>
            <Box
            // sx={{ background: "blue", width: "100%" }}
            >
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCellCustom
                      colors={{ primary: Colors.primary.main }}
                      width={"15rem"}
                      align="center"
                    >
                      Code
                    </TableCellCustom>
                    <TableCellCustom
                      colors={{ primary: Colors.primary.darkest }}
                      width={"18rem"}
                    >
                      Name
                    </TableCellCustom>
                    <TableCell sx={{ width: "18rem" }} />
                    <TableCell sx={{ width: "18rem" }} />
                    <TableCell sx={{ width: "8rem" }} />
                    <TableCell sx={{ width: "18rem" }} />
                    <TableCell sx={{ width: "18rem" }} />
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index: any) => (
                    <React.Fragment>
                      <TableRow
                        key={index}
                        onClick={() => {
                          setOpen(!open);
                        }}
                        sx={{
                          "& > *": { borderBottom: "unset" },
                          cursor: "pointer",
                        }}
                      >
                        {/* <TableCell sx={{ minWidth: ".5rem" }} /> */}
                        <TableCellCustom colors={colors} align="center">
                          {row.code}
                        </TableCellCustom>
                        <TableCellCustom colors={colors}>
                          {row.name}
                        </TableCellCustom>
                        <TableCellCustom colors={colors} title="Indicator">
                          {row.outcome}
                        </TableCellCustom>
                        <TableCellCustom colors={colors} title="Target">
                          {row.target}
                        </TableCellCustom>
                        <TableCellCustom colors={colors} title="Achieved">
                          <AchievedBadge
                            achieved={Number(row.achieved)}
                            dueDate={row.end_date as string}
                            target={Number(row.target)}
                          />
                        </TableCellCustom>
                        <TableCellCustom colors={colors} title="Start date">
                          {dayjs(row.start_date).format("YYYY-MM-DD")}
                        </TableCellCustom>{" "}
                        <TableCellCustom colors={colors} title="End Date">
                          {dayjs(row.end_date).format("YYYY-MM-DD")}
                        </TableCellCustom>
                        <TableCell>
                          <MilestoneActionMenu id={row.id} />
                        </TableCell>
                      </TableRow>
                      {/* {row.tasks.map((task, index) => (
                        <TasksRow key={index} row={task} open={open} />
                      ))} */}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    );
  }
  return <></>;
}

function ActivityRow(props: { row: ActivityResponse }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const colors = {
    primary: Colors.success.light,
    secondary: Colors.primary.main,
  };

  return (
    <React.Fragment>
      <TableRow
        onClick={() => {
          setOpen(!open);
        }}
        sx={{ "& > *": { borderBottom: "unset" }, cursor: "pointer" }}
      >
        <TableCellCustom colors={colors}>{row.code}</TableCellCustom>
        <TableCellCustom colors={colors}>{row.name}</TableCellCustom>
        <TableCellCustom title="Outcome" colors={colors}>
          {row.outcome}
        </TableCellCustom>
        <TableCellCustom
          // title="Target"
          colors={colors}
        >
          {/* {row.target} */}
        </TableCellCustom>
        <TableCellCustom
          // title="Achieved"
          colors={colors}
        >
          {/* {row.conversion} */}
        </TableCellCustom>
        <TableCellCustom colors={colors} title="Start date">
          {dayjs(row.start_date).format("YYYY-MM-DD")}
        </TableCellCustom>{" "}
        <TableCellCustom colors={colors} title="End Date">
          {dayjs(row.end_date).format("YYYY-MM-DD")}
        </TableCellCustom>
        {/* <TableCellCustom title="Partners" colors={colors} align="left">
          <Stack
            direction="row"
            spacing={2}
            maxWidth="30ch"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="start"
          >
            {row.partner.map((p) => (
              <small>{p.code}</small>
            ))}
          </Stack>
        </TableCellCustom> */}
        <TableCell>
          <ActionMenu
            id={row.id}
            name={row.name as string}
            route="activities"
          />
        </TableCell>
      </TableRow>
      <MilestonesRow
        open={open}
        rows={row.milestones}
        // colors={{ levelTwo, levelThree }}
      />
    </React.Fragment>
  );
}

export function ActivityTable(props: any) {
  return (
    <Paper sx={{ width: "100%" }}>
      <CustomPagination
        advanced={false}
        count={props.count}
        page={props.page}
        pages={props.pages}
        position="start"
        rowsPerPage={props.rowsPerPage}
        setPage={props.setPage}
        setRowsPerPage={props.setRowsPerPage}
      />
      <TableContainer>
        <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 750 }}>
          <TableHead>
            <TableCell sx={{ width: "15rem" }}>{/* Code */}</TableCell>
            <TableCell sx={{ width: "18rem" }} />
            <TableCell sx={{ width: "18rem" }} />
            <TableCell sx={{ width: "18rem" }} />
            <TableCell sx={{ width: "8rem" }} />
            <TableCell sx={{ width: "18rem" }} />
            <TableCell sx={{ width: "18rem" }} />
            <TableCell />
          </TableHead>
          <TableBody>
            {props.activities?.map((row: any) => (
              <ActivityRow key={row.id} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        advanced={true}
        count={props.count}
        page={props.page}
        pages={props.pages}
        position="end"
        rowsPerPage={props.rowsPerPage}
        setPage={props.setPage}
        setRowsPerPage={props.setRowsPerPage}
      />
    </Paper>
  );
}
