import { useMutation, useQueries, useQuery } from "@tanstack/react-query"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { SERVER_URL } from "../../../config/environments/env.config"
import { ActionResponse } from "../action/action"
import { client, jsonToFormData, ResultArray, ResultObject, ResultPaginatedArray } from "../common/common"
import { handleResFormat } from "../common/common.util"
import { MilestoneResponse } from "../milestones/milestone"



interface Request {
	partner: number[]//,[1,2],
	name: string,//"Professionalization of ICS production",
	start_date: string,//"2021-10-25",
	end_date: string,//"2024-10-25",
	indicator: number,//"1",
	target: string,//"of professionalised producers",
	verification_means: string,//"test",
	gender_disaggregated: boolean//"male"
}

interface Response {
	code: string, ///"1.1.1"
	conversion: number,//	1
	created_by: string,//	null
	creation_date: string,//	"2022-12-09T15:04:21.984795Z"
	end_date: string,//	"2024-10-25T00:00:00Z"
	gender_disaggregated: boolean,//	"male"
	id: number,//	1
	outcome: string,//	"of professionalised producers"
	last_updated: string,//	"2022-12-09T15:04:21.984828Z"
	name: string,//	"Professionalization of ICS production"
	partner: {
		code: string, id: any, description: string
	}[],//	(2)[1, 2]
	start_date: string,//"2021-10-25T00:00:00Z"
	supervisor: number,//"1"
	target: number,//"30"
	verification_means: string,//"test"
	actions: ActionResponse[]
	kpi: string
	milestones: MilestoneResponse[]
}

interface UploadReq {
	file: string
}

export type ActivityRequest = Request;
export type ActivityResponse = Response;
interface ActivityParams {
	partner?: string,
	page?: number | string,
	page_size?: number | string,
	name?: string
}

export const useActivityQuery = (shouldEnable?: boolean, params?: ActivityParams) =>
	useQuery<ResultPaginatedArray<Response>>({
		queryKey: ["getActivity", { ...params }],
		queryFn: () =>
			client.get('/api/activities/', { params })
				.then(res => res.data)
				.catch(err => err),
		enabled: shouldEnable,
		staleTime: 0,
		cacheTime: 0,

	})

export const useActivityQueryById = (id: number, enabled?: boolean) =>
	useQuery<ResultObject<Response>>({
		queryKey: ["getActivityById"],
		queryFn: () =>
			client.get('/api/activities/' + id)
				.then(handleResFormat)
				.catch(err => err),
		enabled: enabled
	})

export const useUpdateActivityMutation = () =>
	useMutation<ResultObject<Response>, string, Request & { id: number }>({
		mutationKey: ['updateActivity'],
		mutationFn: (body) =>
			client.put(`/api/activities/` + body.id, body)
				.then(handleResFormat)
				.catch(err => err)
	})

export const useActivityMutation = () =>
	useMutation<ResultObject<Response>, string, Request & { id: number }>({
		mutationKey: ["addActivity"],
		mutationFn: (body) =>
			client.post(`/api/activities/`, body)
				.then(handleResFormat)
				.catch(err => err)
	})

export const useDeleteActivityMutation = () =>
	useMutation({
		mutationFn: (id: string) =>
			client.delete('/api/activities/' + id)
				.then(handleResFormat)
				.catch(err => err)
	})


export const useActivityUploadMutation = () =>
	useMutation<ResultPaginatedArray<Response>, string, UploadReq>({
		mutationFn: (body) =>
			client.post('/api/uploadexcel/activities/', jsonToFormData(body))
				.then(handleResFormat)
				.catch(err => err)
	})