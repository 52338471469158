import { Button, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useForm } from "react-hook-form";
import { CustomModal } from "../../components/modals/custom-modal";
import { CustomAutoComplete } from "../../lib/ui/forms/autoComplete";
import { useGroupQuery } from "../../lib/xhr/roles/roles";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate, useParams } from "react-router-dom";
import {
  useUserAddToGroupMutation,
  useUserQuery,
} from "../../lib/xhr/users/users";

const schema = z.object({
  group: z.object({ label: z.string(), value: z.string() }),
});

type TSchema = z.infer<typeof schema>;

export const AddUserToGroup = () => {
  const group = useGroupQuery();
  const userMut = useUserAddToGroupMutation();
  const { refetch } = useUserQuery();
  const navigate = useNavigate();
  const { id } = useParams();

  const form = useForm<TSchema>({
    resolver: zodResolver(schema),
  });

  const submit = (data: TSchema) => {
    userMut.mutate(
      { userid: id as string, group: data.group.value! },
      {
        onSuccess: (data) => {
          // console.log(data);
          //TODO reformat response
          refetch();
          navigate(-1);
        },
      }
    );
  };

  return (
    <CustomModal>
      <Stack direction="row" justifyContent="center">
        <Typography variant="h4">Add User to Group</Typography>
      </Stack>
      <Stack spacing={2} component="form" onSubmit={form.handleSubmit(submit)}>
        <CustomAutoComplete
          name="group"
          options={
            group.data?.results?.map((g) => ({
              label: g.name,
              value: g.name,
            })) ?? []
          }
          labelProps={{ variant: "h6" }}
          label={"Groups"}
          control={form.control}
          errors={form.formState.errors}
        />
        <Button variant="contained" type="submit">
          Submit
        </Button>
      </Stack>
    </CustomModal>
  );
};
