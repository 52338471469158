import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import Checkbox from "@mui/material/Checkbox";
import {
  useActivateUserIdMutation,
  useDeactivateUserIdMutation,
  UserResponse,
  useUserRemoveFromGroup,
  useUserRemovePermission,
} from "../../../lib/xhr/users/users";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Link, useLocation } from "react-router-dom";
import { CustomPagination } from "../../Pagination/pagination-component";
import { Colors } from "../../../config/theme/theme";
import UsersActionMenu from "../../menus/users-action-menu";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof UserResponse;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "ID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  // {
  //   id: "user_type",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Type",
  // },
  {
    id: "organisation",
    numeric: false,
    disablePadding: false,
    label: "Organisation",
  },
  {
    id: "groups",
    numeric: false,
    disablePadding: false,
    label: "Groups",
  },
];

interface EnhancedTableProps {
  numSelected?: number;
  onRequestSort?: (
    event: React.MouseEvent<unknown>,
    property: keyof UserResponse
  ) => void;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order?: Order;
  orderBy?: string;
  rowCount?: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof UserResponse) => (event: React.MouseEvent<unknown>) => {
      onRequestSort!(event, property);
    };

  return (
    <TableHead>
      <TableRow sx={{ background: Colors.primary.dark }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{
                color: "white! important",
                "&:hover": { color: "red! important" },
                "&:focus": { color: "red! important" },
                "& .MuiTableSortLabel-icon": {
                  color: "white",
                },
                "&.Mui-active .MuiTableSortLabel-icon": {
                  opacity: 1,
                  color: "red",
                },
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell
          sx={{
            color: "white",
            "&:hover": { color: "red! important" },
            "&:focus": { color: "red! important" },
          }}
        >
          Activate/Deactivate
        </TableCell>
        <TableCell
          sx={{
            color: "white",
            "&:hover": { color: "red! important" },
            "&:focus": { color: "red! important" },
          }}
        >
          Add Permission
        </TableCell>
        <TableCell
          sx={{
            color: "white",
            "&:hover": { color: "red! important" },
            "&:focus": { color: "red! important" },
          }}
        >
          Action
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
}

export default function UserTable(props: {
  rows: UserResponse[];
  refetch: () => void;
  count: number;
  pages: number;
}) {
  const { rows, refetch } = props;
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof UserResponse>("id");
  const [selected, setSelected] = React.useState<readonly string[]>([]);

  const activate = useActivateUserIdMutation();
  const deactivate = useDeactivateUserIdMutation();
  const purgePermisson = useUserRemovePermission();
  const purgeGroup = useUserRemoveFromGroup();
  const location = useLocation();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof UserResponse
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <CustomPagination
          advanced={false}
          count={props.count}
          pages={props.pages}
          position="start"
        />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy as any}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.sort(getComparator(order, orderBy)).slice() */}
              {stableSort(rows as any, getComparator(order, orderBy)).map(
                (row: any, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        // padding="none"
                        align="left"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      {/* <TableCell>{row.user_type}</TableCell> */}
                      <TableCell>{row.organisation}</TableCell>
                      <TableCell>
                        {row.groups.map((g: any) => (
                          <Button
                            size="small"
                            variant="outlined"
                            sx={{ m: "2px" }}
                          >
                            {g.name}{" "}
                            <HighlightOffIcon
                              onClick={(e) => {
                                purgeGroup.mutate(
                                  { userid: row.id, group: g.name },
                                  {
                                    onSuccess: (data) => {
                                      refetch();
                                    },
                                  }
                                );
                              }}
                            />
                          </Button>
                        ))}
                      </TableCell>
                      {/* <TableCell>
                        {row.permissions.map((p: any) => (
                          <Button
                            size="small"
                            variant="outlined"
                            sx={{ m: "2px" }}
                          >
                            {p.name}{" "}
                            <HighlightOffIcon
                              onClick={(e) => {
                                purgePermisson.mutate(
                                  {
                                    userid: row.id,
                                    permissions: [p.id],
                                  },
                                  {
                                    onSuccess: (data) => {
                                      refetch();
                                    },
                                  }
                                );
                              }}
                            />
                          </Button>
                        ))}
                      </TableCell> */}
                      <TableCell>
                        <Checkbox
                          checked={row.is_active}
                          onChange={(e, value) => {
                            // console.log(value);
                            if (value) {
                              return activate.mutate(
                                { id: row.id },
                                {
                                  onSuccess: (data) => {
                                    refetch();
                                  },
                                }
                              );
                            }

                            return deactivate.mutate(
                              { id: row.id },
                              {
                                onSuccess: (data) => {
                                  refetch();
                                },
                              }
                            );
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </TableCell>
                      <TableCell>
                        {/* <Button
                          variant="outlined"
                          size="small"
                          component={Link}
                          to={`/users/add-permissions/${row.id}`}
                          state={{ backgroundLocation: location }}
                        >
                          Add Permission
                        </Button> */}
                        <Button
                          variant="outlined"
                          size="small"
                          component={Link}
                          to={`/users/add-user-to-group/${row.id}`}
                          state={{ backgroundLocation: location }}
                        >
                          Add To Group
                        </Button>
                      </TableCell>
                      <TableCell>
                        <UsersActionMenu id={row.id} name={row.name} />
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomPagination
          advanced={true}
          count={props.count}
          pages={props.pages}
          position="end"
        />
      </Paper>
    </Box>
  );
}
