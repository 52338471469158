import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Autocomplete,
} from "@mui/material";
import React from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import MilestonesTable from "../../components/tables/milestones/milestones";
import { Colors } from "../../config/theme/theme";
import { IsGizGuard } from "../../lib/context/guards/isGizGuard";
import { StatusContainer } from "../../lib/ui/forms/common/status-container";
import { useMilestoneQuery } from "../../lib/xhr/milestones/milestone";
import {
  PartnerResponse,
  usePartnerQuery,
} from "../../lib/xhr/partners/partners";

export const MilestonesPage = () => {
  const location = useLocation();
  const block = React.useRef(false);
  const [params, setParams] = useSearchParams();
  const orgs = usePartnerQuery();

  const { data, status } = useMilestoneQuery({
    page: params.get("page") ?? 1,
    page_size: params.get("page_size") ?? 20,
    name: (params.get("name") as string) ?? "",
    partner: params.get("partner") as string,
  });

  const value = orgs.data?.results?.filter(
    (o) => o.code === params.get("partner")!
  ) as any;

  const p = {
    name: params.get("name") ?? "",
    partner: params.get("partner") ?? "",
    page: params.get("page") ?? "",
    page_size: params.get("page_size") ?? "",
  };

  React.useEffect(() => {
    const initializePage = () => {
      setParams({
        name: params.get("name") ?? "",
        page: params.get("page") ?? "1",
        partner: params.get("partner") ?? "",
        page_size: params.get("page_size") ?? "20",
      });
    };

    if (block.current === false) {
      block.current = true;
      initializePage();
      // console.log("loaded");
    }
  }, []);

  return (
    <Box sx={{ p: "2rem" }}>
      <Box sx={{ background: "white", borderRadius: ".5rem" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                display: "flex",
                gap: "5rem",
                px: "2rem",
                alignItems: "center",
              }}
            >
              <Typography
                component={"div"}
                sx={{
                  fontSize: "1.18rem",
                  fontWeight: 700,
                  color: Colors.primary.darkest,
                }}
              >
                All Milestones
              </Typography>
              <IsGizGuard>
                <StatusContainer status={orgs.status}>
                  <Autocomplete
                    options={(orgs.data?.results as PartnerResponse[]) ?? []}
                    getOptionLabel={(o) => o?.code ?? ""}
                    value={value ? value.code : []}
                    onChange={(
                      event: any,
                      newValue: PartnerResponse | null
                    ) => {
                      setParams({ ...p, partner: newValue?.code ?? "" });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Partner"
                        variant="outlined"
                      />
                    )}
                    sx={{ minWidth: "18rem" }}
                  />
                </StatusContainer>
              </IsGizGuard>
              <TextField
                placeholder="Search with name"
                onChange={(e) => setParams({ ...p, name: e.target.value })}
                value={params.get("name")}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <IsGizGuard>
              <Box
                sx={{
                  display: "flex",
                  gap: "5rem",
                  px: "2rem",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  component={Link}
                  to="/milestones-add"
                  state={{ backgroundLocation: location }}
                >
                  ADD
                </Button>
              </Box>
            </IsGizGuard>
          </Grid>
        </Grid>
      </Box>
      <StatusContainer status={status}>
        <MilestonesTable
          rows={data?.results ?? []}
          count={data?.count}
          pages={data?.pages as number}
        />
      </StatusContainer>
    </Box>
  );
};
