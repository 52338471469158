import { Button, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useForm } from "react-hook-form";
import { CustomModal } from "../../components/modals/custom-modal";
import { CustomAutoComplete } from "../../lib/ui/forms/autoComplete";
import { TextField } from "../../lib/ui/forms/text-field";
import {
  useGroupAddMutation,
  useGroupQuery,
  usePermissionQuery,
} from "../../lib/xhr/roles/roles";
import { string, z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";

const schema = z.object({
  name: string().min(3),
  permissions: z.array(z.object({ label: z.string(), value: z.number() })),
});

type TSchema = z.infer<typeof schema>;

export const AddRoles = () => {
  const group = useGroupAddMutation();
  const permissions = usePermissionQuery();
  const { refetch } = useGroupQuery();
  const navigate = useNavigate();

  const form = useForm<TSchema>({
    resolver: zodResolver(schema),
  });

  const submit = (data: TSchema) => {
    // console.log(data);
    const permissionIds = data.permissions.map((p) => p.value);
    group.mutate(
      { ...data, permissions: permissionIds },
      {
        onSuccess: (data) => {
          // console.log(data);
          //TODO reformat response
          refetch();
          navigate(-1);
        },
      }
    );
  };

  // console.log(form.formState.errors);

  return (
    <CustomModal>
      <Stack direction="row" justifyContent="center">
        <Typography variant="h4">Add Role</Typography>
      </Stack>
      <Stack spacing={2} component="form" onSubmit={form.handleSubmit(submit)}>
        <TextField
          label="Name"
          labelProps={{ variant: "h6" }}
          name="name"
          control={form.control}
          errors={form.formState.errors}
          // defaultValue={kpi?.name}
        />
        <CustomAutoComplete
          name="permissions"
          options={
            permissions.data?.results?.map((permissions) => ({
              label: permissions.name,
              value: permissions.id,
            })) ?? []
          }
          labelProps={{ variant: "h6" }}
          label={"Permissions"}
          control={form.control}
          multiple={true}
          errors={form.formState.errors}
        />
        <Button variant="contained" type="submit">
          Submit
        </Button>
      </Stack>
    </CustomModal>
  );
};
