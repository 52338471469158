import { useMutation, useQuery } from "@tanstack/react-query"
import { client, ResultObject } from "../common/common"
import { handleResFormat } from "../common/common.util"


export interface Response {
	id: number,//1,
	email: string,//"admin@admin.com",
	name: string,//"admin",
	user_type: string,//"1",
	organisation: string,//"GIZ",
	permission_count: number,//68,
	groups: string[
	],
	is_admin: boolean,//false,
	is_active: boolean,//true,
	permissions: string[]
}

export type UserResponse = Response;


export interface Request {
	old_password: string
	new_password: string
	new_password2: string
}

export const useProfileQuery = () =>
	useQuery<ResultObject<Response>>({
		queryFn: () =>
			client.get('/api/profile/')
				.then(handleResFormat)
				.catch(err => err)
	})

export const useChangePasswordMutation = () =>
	useMutation<ResultObject<string>, string, Request>({
		mutationFn: (body) =>
			client.post('/api/user/changepassword/', body)
				.then(handleResFormat)
				.catch(err => err)
	})