import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Colors } from "../../config/theme/theme";
import EnhancedTable from "../../components/tables/summary/summary-table";
import { useSummaryQuery } from "../../lib/xhr/summary/summary";
import { StatusContainer } from "../../lib/ui/forms/common/status-container";
import _ from "lodash";
import { Toolbar } from "@mui/material";
import { useSearchParams } from "react-router-dom";

export default function SummaryPage() {
  const [params, setParams] = useSearchParams();
  const block = React.useRef(false);

  const summary: any = useSummaryQuery({
    page: params.get("page") ?? 1,
    page_size: params.get("page_size") ?? 10,
  });

  const p = {
    page: params.get("page") ?? "",
    page_size: params.get("page_size") ?? "",
  };

  React.useEffect(() => {
    const initializePage = () => {
      setParams({
        page: params.get("page") ?? "1",
        page_size: params.get("page_size") ?? "10",
      });
    };

    if (block.current === false) {
      block.current = true;
      initializePage();
      console.log("loaded");
    }
  }, []);

  return (
    <Box sx={{ p: "2rem" }}>
      <Box sx={{ background: "white", borderRadius: ".5rem" }}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <Box
              sx={{
                display: "flex",
                gap: "5rem",
                px: "2rem",
                alignItems: "center",
              }}
            >
              <Typography
                component={"div"}
                sx={{
                  fontSize: "1.18rem",
                  fontWeight: 700,
                  color: Colors.primary.darkest,
                }}
              >
                Summary
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
        <Toolbar />
      </Box>
      <StatusContainer status={summary.status}>
        <EnhancedTable
          rows={summary.data?.results}
          count={summary.data?.count}
          pages={summary.data?.pages as number}
        />
      </StatusContainer>
    </Box>
  );
}

// function determineType<Type>(
//   value: Type | Type[],
//   row?: Type,
//   isNested?: boolean
// ): any {
//   // console.log(value);
//   if (_.isArray(value)) {
//     // console.log("is Array", value);
//     const arrayRes = _.map(value, (item, index: number) => {
//       // console.log(value[index]);
//       // setNestedColumns([
//       //   {
//       //     row: row as number,
//       //     ...value[index],
//       //   },
//       //   ...nestedColumns,
//       // ]);
//       // console.log(item);

//       return determineType(item, row, true);
//     });

//     console.log(arrayRes);
//     return arrayRes;
//   }

//   if (_.isString(value)) {
//     // console.log("is string", value);
//     // if (!isNested) {
//     return value;
//     // }
//   }

//   if (_.isObject(value)) {
//     // console.log("is object", value);
//     return _.map(value, (i) => determineType(i, row as any, false));
//   }
// }

// const rows = (data: any) => {
//   // const rows = _.map(data, (summary) => {
//   return _.pickBy(data.at(0), (item) =>
//     determineType(item, data.at(0).code, false)
//   );
//   // });

//   // console.log(nestedColumns);
// };
