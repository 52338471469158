import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { SERVER_URL } from "../../../config/environments/env.config";
import { serialize } from 'object-to-formdata';
import { WebLocalAuthStorage } from "../../storage/auth-storage";
import { useNavigate } from "react-router-dom";


export interface BaseResult {
	code: number;
	status: string;
	count?: number;
	error?: string
}

export interface ResultArray<T> extends BaseResult {
	results: T[];
}
export interface ResultPaginatedArray<T> extends BaseResult {
	count: number;
	results: T[] & ErrorResponse;
	pages: number
	next: number
}

export interface ResultObject<T> extends BaseResult {
	results: T & ErrorResponse;
}

interface ErrorResponse {
	messages?: any
}

export const client = axios.create({ baseURL: SERVER_URL });

const authStorage = new WebLocalAuthStorage("auth");

// console.log(token)

client.interceptors.request.use(
	(config: AxiosRequestConfig) => {
		const { token, refresh } = authStorage.get()
		if (token) {
			(config.headers as any)['Authorization'] = `Bearer ${token}`;
		};

		return config;
	},
	(error) => {
		console.error('> ', error);
		return Promise.reject(error);
	}
);


// client.interceptors.response.use(function (response) {
// 	// Any status code that lie within the range of 2xx cause this function to trigger
// 	// Do something with response data

// 	return response;
// }, function (error) {
// 	// Any status codes that falls outside the range of 2xx cause this function to trigger
// 	// Do something with response error
// 	console.error(' > ', error);

// 	if (error.response.status === 401) {
// 		window.location = '/auth/login' as any

// 		// client.post(`${SERVER_URL}/api/token/refresh/`, { refresh })
// 		// 	.then(res => console.log(res))
// 		// 	.catch(err => console.log(err))

// 	}
// 	// send refresh token
// 	// if success
// 	// set access token and refresh token and redirect to dashboard
// 	return Promise.reject(error);
// });


export function jsonToFormData(data: any) {
	return serialize(data);
}
