import { Typography, Stack, MenuItem, Select } from "@mui/material";
import { useForm } from "react-hook-form";
import { StatusContainer } from "../../lib/ui/forms/common/status-container";
import {
  customPhoneValidation,
  FileField,
  NumField,
  PhoneNumberInput,
  TextField,
} from "../../lib/ui/forms/text-field";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { CustomModal } from "../../components/modals/custom-modal";
import {
  ParamResponse,
  useMilestoneParamsQueryById,
  useMileStoneReportsQueryById,
  useReportMilestoneMutation,
} from "../../lib/xhr/milestones/milestone";
import { CLoadingButton } from "../../components/buttons/button";
import { CustomDatePicker } from "../../lib/ui/forms/date-picker";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import React from "react";
import { useQueryClient } from "@tanstack/react-query";

export const ReportMilestonePage: React.FC<any> = () => {
  const { id } = useParams();
  const query = useMilestoneParamsQueryById(id as string);
  const milestoneReportQuery = useMileStoneReportsQueryById(id as string);
  const mut = useReportMilestoneMutation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const schema = (params: ParamResponse[] | undefined | []) => {
    const s: any = {};
    params?.map((p) => {
      if (p.label_desc.toLocaleLowerCase().match(/phone/)) {
        s[p.label_name] = z
          .string()
          .refine(customPhoneValidation, "Invalid phone number");
        return p;
      }

      if (p.label_type === "text") {
        s[p.label_name] = z.string().min(1, "required");
        return p;
      }
      if (p.label_type === "date") {
        s[p.label_name] = z.date();
        return p;
      }
      if (p.label_type === "number") {
        s[p.label_name] = z.number({ invalid_type_error: "required" });
        return p;
      }
      if (p.label_type === "file") {
        s[p.label_name] = z
          .any()
          .refine((files) => files?.length === 1, "File is required.");
        return p;
      }

      if (p.label_type === "select") {
        s[p.label_name] = z
          .string({ invalid_type_error: "required" })
          .min(1, "required");
      }
    });

    return z
      .object({ ...s })
      .superRefine(({ report_period_from, report_period_to }, ctx) => {
        const start = new Date(report_period_from);
        const end = new Date(report_period_to);

        if (start.getTime() > end.getTime()) {
          // console.log("it is");
          ctx.addIssue({
            code: "custom",
            message: "Report period from  must be less than report period to",
            path: ["report_period_to"],
          });
        }
      });
  };

  const res: any = query.data?.results;
  const errors = res?.messages;

  const form = useForm({
    mode: "onChange",
    resolver: zodResolver(schema(errors ? [] : query.data?.results)),
  });

  // console.log("errors", form.formState.errors);

  const handleFile = (params: ParamResponse[]) => {
    // each milestone will  byt default have one file field
    const field = params.filter((p) => p.label_type === "file");
    return field.at(0)?.label_name;
  };

  const handleRequestFormat = (data: any, file_name?: string) => {
    let payload: {
      label: string;
      value: any;
      value_file: any;
    }[] = [];

    Object.entries(data).map(async (o: [string, any]) => {
      if (file_name && o.at(0) === file_name) {
        // console.log(file_name);
        payload = [
          ...payload,
          {
            label: o.at(0),
            value: "",
            value_file: o.at(1)[0],
            //  await blobToString(o.at(1)[0]),
          },
        ];
        return null;
      }
      payload = [
        ...payload,
        {
          label: o.at(0),
          value: o.at(1),
          value_file: "",
        },
      ];
      return null;
    });

    // console.log(payload);
    return payload;
  };

  function objectToFormData(obj: any) {
    const formData = new FormData();
    formData.append("milestone", obj.milestone);

    for (let i = 0; i < obj.records.length; i++) {
      const record = obj.records[i];

      formData.append(`records[${i}][label]`, record.label);
      record.value && formData.append(`records[${i}][value]`, record.value);

      if (record.value_file instanceof File) {
        formData.append(`records[${i}][value_file]`, record.value_file);
      }
    }

    return formData;
  }

  const submit: any = async (data: any) => {
    const file_name = handleFile(query.data?.results as ParamResponse[]);
    const payload = handleRequestFormat(data, file_name as string);
    // console.log(data);
    const formData = objectToFormData({
      milestone: id,
      records: payload,
    });
    mut.mutate(formData, {
      onSuccess: async (data) => {
        // console.log(data.results);
        enqueueSnackbar("report added successfully", { variant: "success" });
        queryClient.invalidateQueries({
          queryKey: ["get milestone report"],
        });

        await milestoneReportQuery.refetch();

        navigate(`/milestones-reports/${id}?search_term=&page=1&page_size=20`);
      },
    });
  };
  return (
    <CustomModal>
      <Stack spacing={3} component="form" onSubmit={form.handleSubmit(submit)}>
        <Stack direction="row" justifyContent="center">
          <Typography variant="h5"> Report Milestone</Typography>
        </Stack>
        <StatusContainer status={query.status}>
          <RenderForm
            fields={query.data?.results as ParamResponse[]}
            form={form}
            isLoading={mut.isLoading}
          />
        </StatusContainer>
      </Stack>
    </CustomModal>
  );
};

const RenderForm = ({
  fields,
  form,
  isLoading,
}: {
  fields: ParamResponse[] | (any & { messages: string });
  form: any;
  isLoading: boolean;
}) => {
  // console.log(form.formState.errors);
  const [gender_disaggregated, producer_type] = form.watch([
    "gender_disaggregated",
    "producer_type",
  ]) as any;

  // console.log("producer type", producer_type);

  React.useEffect(() => {
    if (producer_type && producer_type === "group") {
      form.setValue("gender_disaggregated", "n/a");
    }

    if (producer_type && producer_type === "individual") {
      form.setValue("gender_disaggregated", undefined);
    }
  }, [producer_type]);

  if (fields?.messages) {
    return (
      <Stack>
        <Typography>There are no form params added for this report</Typography>
      </Stack>
    );
  }
  return (
    <Stack spacing={2}>
      {fields?.map((v: ParamResponse) => {
        if (v.label_desc.toLowerCase().match(/phone/)) {
          return (
            <PhoneNumberInput
              name={v.label_name}
              control={form.control}
              countries={["KE"]}
              defaultCountry={"KE"}
              errors={form.formState.errors}
              label={v.label_desc}
            />
          );
        }
        if (v.label_type === "text") {
          return (
            <TextField
              name={v.label_name}
              label={v.label_desc}
              errors={form.formState.errors}
              control={form.control}
            />
          );
        }

        if (v.label_type === "number") {
          return (
            <NumField
              name={v.label_name}
              label={v.label_desc}
              errors={form.formState.errors}
              control={form.control}
              type="number"
            />
          );
        }

        if (v.label_type === "date") {
          return (
            <CustomDatePicker
              name={v.label_name}
              label={v.label_desc}
              control={form.control}
              errors={form.formState.errors}
              labelProps={{ variant: "h6" }}
            />
          );
        }

        if (v.label_type === "file") {
          return (
            <Stack spacing={1}>
              <FileField
                labelProps={{ variant: "h6", fontSize: "14px" }}
                errors={form.formState.errors}
                type="file"
                label={v.label_desc}
                {...form.register(v.label_name, { required: true })}
              />
            </Stack>
          );
        }

        if (v.label_type === "select") {
          if (v.label_name === "producer_type") {
            return (
              <Stack>
                <Typography>Producer type</Typography>
                <Select
                  value={producer_type ?? ""}
                  onChange={(e) =>
                    form.setValue("producer_type", e.target.value)
                  }
                  name="producer_type"
                >
                  <MenuItem value="individual">Individual enterprise</MenuItem>
                  <MenuItem value="group">Group</MenuItem>
                </Select>
                <Typography sx={{ color: "red", fontSize: ".8rem", ml: 2 }}>
                  {form.formState?.errors["gender_disaggregated"]?.message}
                </Typography>
              </Stack>
            );
          }

          if (
            v.label_name === "gender_disaggregated" &&
            producer_type === "individual"
          ) {
            return (
              <Stack>
                <Typography>{v.label_desc}</Typography>
                <Select
                  name="gender_disaggregated"
                  onChange={(e) =>
                    form.setValue("gender_disaggregated", e.target.value)
                  }
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                </Select>
                <Typography sx={{ color: "red", fontSize: ".8rem", ml: 2 }}>
                  {form.formState?.errors["gender_disaggregated"]?.message}
                </Typography>
              </Stack>
            );
          }

          return <></>;
        }

        return <></>;
      })}

      <CLoadingButton loading={isLoading} />
    </Stack>
  );
};
