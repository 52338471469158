export const Colors = {
	primary: {
		light: "#DDE2FF",
		main: "#9FA2B4",
		dark: "#363740",
		darkest: "#252733",
		contrastText: "#A4A6B3",

	},
	secondary: {
		light: "#C5C7CD",
		dark: "#4B506D",

	},
	info: {
		extraLight: "#F7F8FC",
		light: "",
		main: "#3751FF",
		dark: "",
		contrastText: ""
	},
	success: {
		// light: "#11D600",
		light: '#118407',
		main: "#29CC97",
		dark: "",
		contrasttext: ""
	},
	warning: {
		light: "",
		main: "#FEC400",
		dark: "",
		contrastText: ""
	},
	danger: {
		light: "",
		main: "#FA3535",
		dark: "",
		contrastText: ""
	}
}

